import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { Icon } from '../../foundation/Icon/Icon.js';
import { extendComponent } from '../../util/extend-component.js';
import { Button } from './Button.js';
import { IconBadge } from './IconBadge.js';

const IconButton = extendComponent(Button, {
  square: "true",
  children: ({ children, icon, badge, badgeProps }) => /* @__PURE__ */ jsxs(Fragment, { children: [
    children ?? /* @__PURE__ */ jsx(Icon, { icon }),
    badge && /* @__PURE__ */ jsx(IconBadge, { ...badgeProps, children: badge })
  ] })
});

export { IconButton };
