import * as React from "react";
import { forwardRef } from "react";
const SvgAutoLabelSettings = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m11.461 5.258 1.6 5.4 4.4 1.6-1.125.41 2.844.568.137-.059c.808-.346.808-1.492 0-1.838L14.46 9.258l-2.05-6.154c-.305-.912-1.594-.912-1.898 0L8.46 9.258l-4.855 2.08c-.808.347-.808 1.493 0 1.84l4.855 2.08 2.052 6.154c.304.912 1.593.912 1.897 0l.182-.545-.736-2.942-.395 1.333-1.6-5.4-4.4-1.6 4.4-1.6 1.6-5.4Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18.66 15.235a3.35 3.35 0 0 0-.337-.167l-.17-.753A.41.41 0 0 0 17.75 14h-1.5a.41.41 0 0 0-.403.315l-.17.753a3.336 3.336 0 0 0-.632.354l-.758-.234a.418.418 0 0 0-.482.181l-.75 1.262a.392.392 0 0 0 .08.495l.587.52a3.1 3.1 0 0 0 0 .709l-.587.519a.392.392 0 0 0-.08.495l.75 1.262a.418.418 0 0 0 .482.18l.758-.233a3.299 3.299 0 0 0 .632.354l.17.754a.41.41 0 0 0 .403.314h1.5a.41.41 0 0 0 .403-.314l.17-.754a3.316 3.316 0 0 0 .632-.354l.758.234a.418.418 0 0 0 .482-.181l.75-1.262a.392.392 0 0 0-.08-.495l-.587-.52a3.062 3.062 0 0 0 0-.709l.587-.519a.392.392 0 0 0 .08-.495l-.75-1.261a.418.418 0 0 0-.483-.182l-.757.234a3.299 3.299 0 0 0-.295-.187ZM17 19.6c.91 0 1.649-.716 1.649-1.6 0-.884-.738-1.6-1.649-1.6-.91 0-1.649.716-1.649 1.6 0 .884.738 1.6 1.649 1.6Z"
    }
  )
);
const ForwardRef = forwardRef(SvgAutoLabelSettings);
export default ForwardRef;
