import * as React from "react";
import { forwardRef } from "react";
const SvgMinusCircleFilled = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M1.333 8.003A6.67 6.67 0 0 1 8 1.336a6.669 6.669 0 0 1 6.666 6.667A6.67 6.67 0 0 1 8 14.669a6.67 6.67 0 0 1-6.667-6.666Zm3.333.666h6.667V7.336H4.666v1.333Z",
      fill: "#B3B3B3"
    }
  )
);
const ForwardRef = forwardRef(SvgMinusCircleFilled);
export default ForwardRef;
