import * as React from "react";
import { forwardRef } from "react";
const SvgAngle = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6 4H4v16h16v-2h-5.183A11.003 11.003 0 0 0 6 9.183V4Zm0 7.225V18h6.775A8.997 8.997 0 0 0 6 11.225Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgAngle);
export default ForwardRef;
