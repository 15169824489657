import * as React from "react";
import { forwardRef } from "react";
const SvgPauseCircleOutline = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12Zm9 4H9V8h2v8Zm1 4c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm3-4h-2V8h2v8Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPauseCircleOutline);
export default ForwardRef;
