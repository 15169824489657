import * as React from "react";
import { forwardRef } from "react";
const SvgLog = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M13.333 2a.667.667 0 0 0-.666-.667H3.333A.667.667 0 0 0 2.667 2v12c0 .368.298.667.666.667h3.32a5.686 5.686 0 0 1-.988-1.334H4V2.667h8v2.49c.47.114.917.286 1.333.508V2Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M10.667 8.333A.667.667 0 0 0 10 9v1.867a.663.663 0 0 0 .195.438l.943.943a.667.667 0 0 0 .943-.943l-.748-.748V9a.667.667 0 0 0-.666-.667Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.333 10.667a4.667 4.667 0 1 1-9.333 0 4.667 4.667 0 0 1 9.333 0Zm-1.333 0a3.333 3.333 0 1 1-6.667 0 3.333 3.333 0 0 1 6.667 0Z",
      fill: "#fff"
    }
  )
);
const ForwardRef = forwardRef(SvgLog);
export default ForwardRef;
