import * as React from "react";
import { forwardRef } from "react";
const SvgPinOutline = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m3.486 4.61 2.269 2.773a.25.25 0 0 1 .05.21l-.075.353a2.401 2.401 0 0 0-.046.318c-.01.137-.008.27.005.397.005.04.054.056.083.027l2.916-2.916c.03-.03.013-.078-.027-.083a2.162 2.162 0 0 0-.397-.005 2.401 2.401 0 0 0-.318.046l-.352.076a.25.25 0 0 1-.21-.051L4.61 3.485 3.486 4.61Zm2.772-2.418a.625.625 0 0 0-.884-.884L1.308 5.374a.625.625 0 0 0 .884.884l.742-.743 1.819 2.222c-.032.15-.054.3-.066.45-.052.68.11 1.323.458 1.84.154.229.476.227.67.032L7.585 8.29l2.121 2.121a.5.5 0 0 0 .707-.707L8.264 7.61l-.68.68 2.475-2.474c.195-.195.197-.517-.032-.67-.517-.348-1.16-.511-1.84-.459-.15.012-.3.034-.45.066L5.515 2.934l.743-.742Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPinOutline);
export default ForwardRef;
