import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonRedraw = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    fill: "currentColor",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM4 7a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM2.599 8.576c.078-.37.14-.722.191-1.04l1.028.122c-.052.329-.117.692-.198 1.077l-1.021-.16Zm-.55 2.04c.114-.338.215-.677.305-1.012l1.01.201c-.093.35-.199.706-.32 1.061l-.996-.25Zm-.601 1.463c.077-.156.151-.316.222-.478l.972.313c-.076.175-.156.347-.24.517a3.362 3.362 0 0 0-.174.415l-.995-.251c.059-.174.13-.346.215-.516Zm-.407 2.648a5.41 5.41 0 0 1-.026-1.064l1.036.06c-.024.299-.015.604.022.914l-1.032.09Zm.517 2.02c-.12-.324-.228-.655-.316-.99l1.011-.199c.081.306.181.613.294.918l-.989.271Zm.814 1.888c-.14-.3-.285-.611-.425-.931l.97-.315c.137.31.278.614.417.912l-.962.334ZM2.992 20a16.08 16.08 0 0 0-.196-.451l.297-.1 2.018-8.94c.065-.286.248-.53.504-.673l7.078-3.932-.207-.208c-.201-.2-.201-.526 0-.727l1.818-1.818c.2-.201.526-.201.727 0l5.818 5.818c.201.2.201.526 0 .727l-1.818 1.818c-.2.201-.526.201-.727 0l-.208-.207-3.932 7.078c-.143.256-.387.44-.673.504l-9.235 2.085A1.03 1.03 0 0 1 3 19.998L2.993 20ZM16.584 9.794l-3.77 6.787c-.143.256-.387.44-.673.504l-5.621 1.27 3.585-3.586a1.543 1.543 0 1 0-.873-.873l-3.585 3.585 1.27-5.621c.064-.286.247-.53.503-.673l6.787-3.77 2.377 2.377Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonRedraw);
export default ForwardRef;
