/**
 * Show or hide intercom live chat button
 */
declare global {
  interface Window {
    Intercom: any;
    hsConversationsOnReady: any;
    hsConversationsSettings: any;
    HubSpotConversations: any;
  }
}

export const CHAT_CONTAINER_ID = 'hubspot-messages-iframe-container';
export const CHAT_IFRAME_ID = 'hubspot-messages-custom-iframe';

export const showChatWidget = (): void => {
  const container = document.getElementById(CHAT_CONTAINER_ID);
  if (!container) return;
  container.style.visibility = 'visible';
};

export const hideChatWidget = (): void => {
  const container = document.getElementById(CHAT_CONTAINER_ID);
  if (!container) return;
  container.style.visibility = 'hidden';
};

export function openChatWidget(): void {
  if (!window.HubSpotConversations) return;
  window.HubSpotConversations.widget.open();
}

export function closeChatWidget(): void {
  if (!window.HubSpotConversations) return;
  window.HubSpotConversations.widget.close();
}
