import AuthService from './AuthService';
import { ApiCall } from './types';

const checkAccountName: ApiCall<{ accountName: string }, any> = async ({ accountName }) => {
  const res = await AuthService.apiCallBeforeLogin({
    method: 'get',
    url: `/auth/reg?tenant_id=${accountName}`,
    data: { tenantId: accountName },
    accountName,
    errorHandling: err => {
      if (err.response?.status === 412) {
        throw new Error('Team Name already exists');
      }
    },
  });
  return res;
};

const getUserInfo: ApiCall<{ userEmail: string }, any> = async ({
  userEmail,
  isGuest,
  urlInfo,
}) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/auth/users/profile?user_name=${userEmail}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const inviteToAccount: ApiCall<
  { inviteList: { email: string; role: 'Admin' | 'Collaborator' }[] },
  any
> = async ({ inviteList, isGuest, urlInfo }) => {
  // const inviteList = emails.map(item => ({
  //   email: item.email,
  //   role: 'Admin',
  // }));
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/users/invite',
    data: { inviteList },
    hasPublicApi: false,
    isGuest,
    urlInfo,
    errorHandling: err => {
      if (err.response?.status === 403) {
        throw new Error('Exceeded limits');
      }
    },
  });
  return res;
};

const getAllowSystemAdmin: ApiCall<unknown, any> = async ({ isGuest, urlInfo }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: '/auth/tenants/allow_system',
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const putAllowSystemAdmin: ApiCall<unknown, any> = async ({ isGuest, urlInfo }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'put',
    url: '/auth/tenants/allow_system',
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const resendInvitation: ApiCall<{ email: string }, any> = async ({ email, isGuest, urlInfo }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/users/resend_invite',
    data: { email },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const resendProjectInvitation: ApiCall<{ projectId: string; email: string }, any> = async ({
  projectId,
  email,
  isGuest,
  urlInfo,
}) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/users/${encodeURIComponent(email)}/resend-invite/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

export default {
  checkAccountName,
  resendInvitation,
  resendProjectInvitation,
  getUserInfo,
  inviteToAccount,
  getAllowSystemAdmin,
  putAllowSystemAdmin,
};
