import * as React from "react";
import { forwardRef } from "react";
const SvgLeftToCopy = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 4h12v12H8v-4h5v1.784c0 .192.227.288.36.152l3.486-3.556a.546.546 0 0 0 0-.76L13.36 6.063c-.133-.135-.36-.04-.36.153V8H8V4ZM6 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-2v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V4Zm0 4H4v12h12v-2H8a2 2 0 0 1-2-2V8Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgLeftToCopy);
export default ForwardRef;
