import * as React from "react";
import { forwardRef } from "react";
const SvgMenuBook = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M4.5 7a.836.836 0 0 0-.833.833v10.834c0 .916.75 1.666 1.666 1.666h10.834A.836.836 0 0 0 17 19.5a.836.836 0 0 0-.833-.833h-10a.836.836 0 0 1-.834-.834v-10A.836.836 0 0 0 4.5 7Zm14.167-3.333h-10C7.75 3.667 7 4.417 7 5.333v10C7 16.25 7.75 17 8.667 17h10c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666ZM17 11.167h-6.667a.836.836 0 0 1-.833-.834c0-.458.375-.833.833-.833H17c.458 0 .833.375.833.833a.836.836 0 0 1-.833.834ZM13.667 14.5h-3.334a.836.836 0 0 1-.833-.833c0-.459.375-.834.833-.834h3.334c.458 0 .833.375.833.834a.836.836 0 0 1-.833.833ZM17 7.833h-6.667A.836.836 0 0 1 9.5 7c0-.458.375-.833.833-.833H17c.458 0 .833.375.833.833a.836.836 0 0 1-.833.833Z" })
);
const ForwardRef = forwardRef(SvgMenuBook);
export default ForwardRef;
