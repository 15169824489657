import * as React from "react";
import { forwardRef } from "react";
const SvgArrowLongDown = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M5.5 1v8.085l-1.295-1.29L3.5 8.5 6 11l2.5-2.5-.705-.705L6.5 9.085V1h-1Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgArrowLongDown);
export default ForwardRef;
