import { jsxs, jsx } from 'react/jsx-runtime';
import { createContext, useContext, useState } from 'react';
import { AlertDialog } from './AlertDialog.js';
import './Dialog.js';
import { useDialogState } from 'reakit/Dialog';

const Context = createContext(null);
const useAlertDialog = () => {
  const ctx = useContext(Context);
  if (!ctx)
    throw new Error("useAlertDialog must be used inside AlertDialogProvider");
  return ctx;
};
const useAlertDialogState = () => {
  const [data, setData] = useState(null);
  const state = useDialogState();
  function showAlert(data2) {
    setData(data2);
    setTimeout(() => {
      state.show();
    }, 1);
  }
  function hideAlert() {
    setData(null);
    state.hide();
  }
  return {
    data,
    setData,
    state,
    showAlert,
    hideAlert
  };
};
const AlertDialogProvider = ({ children }) => {
  const context = useAlertDialogState();
  return /* @__PURE__ */ jsxs(Context.Provider, { value: context, children: [
    children,
    context.data && /* @__PURE__ */ jsx(AlertDialog, { state: context.state, ...context.data })
  ] });
};

export { AlertDialogProvider, useAlertDialog };
