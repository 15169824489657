import * as React from "react";
import { forwardRef } from "react";
const SvgChevronDuoRight = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m18.425 12-6.01 6.01L11 16.596 15.596 12 11 7.404l1.414-1.414 6.01 6.01Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "m13 12-6.01 6.01-1.415-1.414L10.172 12 5.575 7.404 6.99 5.99 13 12Z" })
);
const ForwardRef = forwardRef(SvgChevronDuoRight);
export default ForwardRef;
