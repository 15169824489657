import * as React from "react";
import { forwardRef } from "react";
const SvgAdvancedAi = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M5.5 1a4.001 4.001 0 0 1 3.967 3.482l1.125 1.77c.074.117.059.29-.113.364l-.979.419V8.5a1 1 0 0 1-1 1h-1V11H3V9.153c0-.59-.218-1.149-.622-1.653A4 4 0 0 1 5.5 1Zm0 1a3 3 0 0 0-2.342 4.875C3.705 7.557 4 8.334 4 9.153V10h2.5l.001-1.5H8.5V6.376l.775-.332-.771-1.212-.029-.221A3 3 0 0 0 5.5 2Zm0 1.5A.5.5 0 0 1 6 4v.5h.5a.5.5 0 0 1 0 1H6V6a.5.5 0 0 1-1 0v-.5h-.5a.5.5 0 0 1 0-1H5V4a.5.5 0 0 1 .5-.5Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgAdvancedAi);
export default ForwardRef;
