import * as React from "react";
import { forwardRef } from "react";
const SvgAutoCurate = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13 1h-2v3h2V1Zm3.238.926 1.732 1-1.5 2.598-1.732-1 1.5-2.598ZM7.762 1.91l-1.732 1 1.5 2.598 1.732-1-1.5-2.598ZM5 6a1 1 0 0 0-1 1v2.647a1 1 0 0 0 .29.704l4.96 5.002V22a1 1 0 0 0 1.45.893l3.5-1.765a1 1 0 0 0 .55-.893v-4.882l4.96-5.002a1 1 0 0 0 .29-.704V7a1 1 0 0 0-1-1H5Zm1 3.235V8h12v1.235l-4.96 5.002a1 1 0 0 0-.29.704v4.679l-1.5.756V14.94a1 1 0 0 0-.29-.704L6 9.235Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgAutoCurate);
export default ForwardRef;
