import * as React from "react";
import { forwardRef } from "react";
const SvgUndo = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M8.333 5.333c-1.766 0-3.366.66-4.6 1.733l-2.4-2.4v6h6L4.92 8.253A5.302 5.302 0 0 1 8.333 7c2.36 0 4.367 1.54 5.067 3.666l1.58-.52c-.927-2.793-3.547-4.813-6.647-4.813Z" })
);
const ForwardRef = forwardRef(SvgUndo);
export default ForwardRef;
