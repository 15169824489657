import * as React from "react";
import { forwardRef } from "react";
const SvgCloudOutline = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m6.296 9.898-.948.155A4.002 4.002 0 0 0 6 18h13a3 3 0 0 0 .46-5.965l-1.316-.202-.322-1.291A6.003 6.003 0 0 0 6.768 9.06l-.472.837Zm13.467.16a8.003 8.003 0 0 0-14.738-1.98A6.002 6.002 0 0 0 6 20h13a5 5 0 0 0 .763-9.942Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCloudOutline);
export default ForwardRef;
