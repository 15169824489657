import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useRef, useState, useMemo, useCallback, Fragment as Fragment$1 } from 'react';
import { FolderPlus } from '@superb-ai/icons';
import { Chip } from '../../contrib/Chip/Chip.js';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import { Box } from '../../foundation/Box/Box.js';
import { Icon } from '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { Typography } from '../../foundation/Typography/Typography.js';
import { Dropdown } from '../Dropdown/Dropdown.js';
import { ComboboxInput } from '../Input/Input.js';
import { SelectList } from '../Select/SelectList.js';

function Combobox(props) {
  const {
    value,
    formatValue,
    multiple,
    data,
    placeholder = "Please select or select",
    searchFunction,
    creatable = true
  } = props;
  const { fixed, variant, color, size, ...listProps } = props;
  const inputRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [localOptions, setLocalOptions] = useState([]);
  const [visible, setVisible] = useState(false);
  const options = [...localOptions, ...data ?? []];
  const selectedData = useMemo(
    () => options?.find((option) => value === option.value),
    [options, value]
  );
  const computedSearchFunction = useCallback(
    ({ label }) => searchFunction?.(label) ?? label.toLowerCase().includes(searchInput.toLowerCase()),
    [searchFunction, searchInput]
  );
  const searchedData = useMemo(
    () => (!searchInput ? options : options?.filter(computedSearchFunction)) ?? [],
    [options, computedSearchFunction]
  );
  const matchedData = useMemo(
    () => searchedData.some(({ label }) => label.toLowerCase() === searchInput.toLowerCase()),
    [searchedData, searchInput]
  );
  function createOption() {
    if (searchInput && !matchedData && creatable) {
      const newValue = searchInput;
      setLocalOptions([...localOptions, { label: newValue, value: newValue }]);
      if (multiple) {
        const nextValue = value.includes(newValue) ? value.filter((v) => v !== newValue) : [...value, newValue];
        props.onChangeValue?.(nextValue);
      } else {
        props.onChangeValue?.(newValue);
      }
    }
  }
  function onKeyDown(e) {
    e.stopPropagation();
    if (e.code === "Enter") {
      createOption();
    }
  }
  const inputProps = {
    color,
    size,
    ref: inputRef,
    placeholder,
    value: visible ? searchInput : multiple ? "" : value !== null ? `${formatValue?.(value) ?? selectedData?.label ?? ""}` : "",
    onKeyDown,
    onChange: (e) => setSearchInput(e.target.value)
  };
  function formatSelected(value2) {
    if (Array.isArray(value2)) {
      return value2.map((v) => /* @__PURE__ */ jsxs(Fragment$1, { children: [
        formatValue?.(v) ?? /* @__PURE__ */ jsx(Chip, { color: "gray", fill: "strong", children: data?.find((option) => v === option.value)?.label ?? v }),
        /* @__PURE__ */ jsx(ComboboxInput, { ...inputProps })
      ] }, `${v}`));
    }
    return /* @__PURE__ */ jsx(ComboboxInput, { ...inputProps });
  }
  const disclosure = /* @__PURE__ */ jsx(Fragment, { children: value === null || multiple && value.length === 0 ? /* @__PURE__ */ jsx(ComboboxInput, { ...inputProps }) : formatSelected(value) });
  return /* @__PURE__ */ jsx(
    Dropdown,
    {
      initialVisible: false,
      disclosure,
      hideOnClick: !multiple,
      initialFocusRef: inputRef,
      onChangeVisible: setVisible,
      onBlur: () => {
        setVisible(false);
      },
      ...{ fixed, variant, color, size, disabled: props?.disabled },
      children: /* @__PURE__ */ jsx(SelectList, { ...listProps, data: searchedData, py: 0.5, maxHeight: props.maxHeight ?? 300, children: !matchedData && searchInput && creatable && /* @__PURE__ */ jsx(Box, { px: 1.5, style: { paddingTop: "6px", paddingBottom: "6px" }, children: /* @__PURE__ */ jsxs(Chip, { fill: "strong", children: [
        /* @__PURE__ */ jsx(Icon, { icon: FolderPlus, size: "12" }),
        " Create",
        " ",
        /* @__PURE__ */ jsx(Typography, { variant: "s-strong", children: searchInput })
      ] }) }) })
    }
  );
}

export { Combobox };
