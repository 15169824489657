import * as React from "react";
import { forwardRef } from "react";
const SvgTextAlignLeft = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M21 7V5H3v2h18ZM15 9v2H3V9h12ZM21 15v-2H3v2h18ZM15 19v-2H3v2h12Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgTextAlignLeft);
export default ForwardRef;
