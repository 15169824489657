import * as React from "react";
import { forwardRef } from "react";
const SvgCompare = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2.737 5.544a.702.702 0 1 0 1.403 0 .702.702 0 0 0-1.403 0Zm.702-2.105a2.105 2.105 0 1 0 0 4.21 2.105 2.105 0 0 0 0-4.21ZM2.737 12.561a.702.702 0 1 0 1.403 0 .702.702 0 0 0-1.403 0Zm.702-2.105a2.105 2.105 0 1 0 0 4.21 2.105 2.105 0 0 0 0-4.21ZM9.754 5.544a.702.702 0 1 0 1.404 0 .702.702 0 0 0-1.404 0Zm.702-2.105a2.105 2.105 0 1 0 0 4.21 2.105 2.105 0 0 0 0-4.21ZM9.754 12.561a.702.702 0 1 0 1.404 0 .702.702 0 0 0-1.404 0Zm.702-2.105a2.105 2.105 0 1 0 0 4.21 2.105 2.105 0 0 0 0-4.21Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9.754 5.544a.702.702 0 1 0 .706-.702h.698v8.421H2.737v-.7a.702.702 0 1 0 0-.004V5.545a.702.702 0 1 0 .702-.702h7.013a.702.702 0 0 0-.698.702ZM3.44 4.842h-.702v.702c0-.388.314-.702.702-.702Zm.701 1.404v5.614h5.614V6.246H4.14Zm6.316 7.017a.702.702 0 1 1 0-1.403.702.702 0 0 1 0 1.403Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.842 2.035c0-.387.314-.702.702-.702h8.42c.388 0 .703.315.703.702v8.421a.702.702 0 0 1-.702.702H12.56V9.754h.702V2.737H6.246v.702H4.842V2.035Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCompare);
export default ForwardRef;
