import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import { extendComponent } from '../../util/extend-component.js';
import { skeletonStyle } from './style.css.vanilla.js';

const BoxSkeleton = extendComponent(Box, {
  backgroundColor: "gray-100",
  className: skeletonStyle
});

export { BoxSkeleton };
