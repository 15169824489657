import { useState, useCallback } from 'react';

function useOnScreen({ root = null, rootMargin = "0px", threshold = 0 }) {
  const [observer, setObserver] = useState(null);
  const [isIntersecting, setIntersecting] = useState(false);
  const measureRef = useCallback(
    (node) => {
      if (!node)
        return;
      const _root = root ?? node.parentNode;
      const observer2 = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },
        { root: _root, rootMargin, threshold }
      );
      observer2.observe(node);
      setObserver(observer2);
    },
    [root, rootMargin, threshold]
  );
  return { measureRef, isIntersecting, observer };
}

export { useOnScreen };
