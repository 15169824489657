import * as React from "react";
import { forwardRef } from "react";
const SvgCaretUp = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m17 14.5-5-5-5 5h10Z" })
);
const ForwardRef = forwardRef(SvgCaretUp);
export default ForwardRef;
