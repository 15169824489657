import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonAutoEditBox = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 2H2v2h2V2ZM2 16v-2h2v2H2ZM16 2v2h-2V2h2ZM5.267 4V2h2.8v2h-2.8Zm4.666 0V2h2.8v2h-2.8ZM14 5.267h2v2.028h-2V5.267ZM7.314 14v2H5.267v-2h2.047ZM4 12.733H2v-2.8h2v2.8Zm0-4.666H2v-2.8h2v2.8ZM14 8.5h2V14h5.5v2H16v5.5h-2V16H8.5v-2H14V8.5Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonAutoEditBox);
export default ForwardRef;
