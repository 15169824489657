import * as React from "react";
import { forwardRef } from "react";
const SvgSendToBack = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 17 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M4.5 5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V5Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M2.5 2.667c0-.369.298-.667.667-.667h4c.368 0 .666.298.666.667v4a.667.667 0 0 1-.666.666h-4a.667.667 0 0 1-.667-.666v-4Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.5 3v3.333h3.333V3H3.5Zm-.333-1a.667.667 0 0 0-.667.667v4c0 .368.298.666.667.666h4a.667.667 0 0 0 .666-.666v-4A.667.667 0 0 0 7.167 2h-4Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M9.167 9.333c0-.368.298-.666.666-.666h4c.369 0 .667.298.667.666v4a.667.667 0 0 1-.667.667h-4a.667.667 0 0 1-.666-.667v-4Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.167 9.667V13H13.5V9.667h-3.333Zm-.334-1a.667.667 0 0 0-.666.666v4c0 .369.298.667.666.667h4a.667.667 0 0 0 .667-.667v-4a.667.667 0 0 0-.667-.666h-4Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSendToBack);
export default ForwardRef;
