import * as React from "react";
import { forwardRef } from "react";
const SvgUserUnassign = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m17.999 13.413-2.293 2.293-1.414-1.414 2.293-2.293-2.292-2.292 1.414-1.414L18 10.586l2.293-2.293 1.414 1.414L19.414 12l2.293 2.293-1.414 1.414-2.294-2.294ZM12 19h2a6 6 0 0 0-12 0h2a4 4 0 0 1 8 0Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgUserUnassign);
export default ForwardRef;
