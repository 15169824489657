import * as React from "react";
import { forwardRef } from "react";
const SvgSelectMultiple = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m13 13.56 5.707-5.706-1.414-1.414L13 10.732 10.707 8.44 9.293 9.854 13 13.56Z",
      fill: "inherit"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2ZM8 16V4h12l.002 12H8Z",
      fill: "inherit"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8Z", fill: "inherit" })
);
const ForwardRef = forwardRef(SvgSelectMultiple);
export default ForwardRef;
