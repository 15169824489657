import { jsx } from 'react/jsx-runtime';
import { forwardRef, useState } from 'react';
import { Visible, VisibleOff } from '@superb-ai/icons';
import '../Chip/Chip.js';
import '../Collapse/Collapse.js';
import '../ExternalLink/ExternalLink.js';
import '../LoadingIndicator/LoadingIndicator.js';
import '../ProgressBar/ProgressBar.js';
import '../Skeleton/BoxSkeleton.js';
import '../Skeleton/TypographySkeleton.js';
import '../SuiteLogo/SuiteLogo.js';
import '../Table/Table.js';
import '../Tabs/Tabs.js';
import '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import '@react-aria/utils';
import '../../modal/Dialog/Dialog.js';
import '../../modal/Dialog/contexts.js';
import 'reakit/Popover';
import 'react-popper';
import '../../modal/Tooltip/styles.css.vanilla.js';
import '../../inputs/Button/Button.js';
import { IconButton } from '../../inputs/Button/IconButton.js';
import '../../inputs/Button/IconButtonWithTriangle.js';
import '../../inputs/Button/styles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './../../inputs/Dropdown/styles.css.ts.vanilla.css';
import { Input } from '../../inputs/Input/Input.js';
import '../../inputs/Select/styles.css.vanilla.js';
import '../../inputs/Checkbox/Checkbox.js';
import '../../inputs/Label/Label.js';
import '../../inputs/Radio/Radio.js';
import 'reakit/Radio';
import '@vanilla-extract/dynamic';
import 'classnames';
import '../../theme/color-util.js';
import '../../theme/sprinkles.css.vanilla.js';
import '../../inputs/Range/Range.js';
import '../../inputs/Range/styles.css.vanilla.js';
import 'reakit/Composite';
import '../../inputs/SegmentedControl/styles.css.vanilla.js';
import 'rc-overflow';
import '../../inputs/ToggleButton/ToggleButton.js';

const PasswordInput = forwardRef(function PasswordInput2(inputProps, ref) {
  const [isVisible, setIsVisible] = useState(false);
  const buttonSize = inputProps.size === "l" ? "m" : "s";
  const button = /* @__PURE__ */ jsx(
    IconButton,
    {
      icon: isVisible ? Visible : VisibleOff,
      onClick: (e) => {
        e.preventDefault();
        void setIsVisible((v) => !v);
      },
      size: buttonSize,
      variant: "text"
    }
  );
  return /* @__PURE__ */ jsx(Input, { ref, ...inputProps, type: isVisible ? "text" : "password", suffix: button });
});

export { PasswordInput };
