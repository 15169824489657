import { assignInlineVars } from '@vanilla-extract/dynamic';
import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import { getColor } from '../../theme/color.js';
import '../../theme/sprinkles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { extendComponent } from '../../util/extend-component.js';
import { progressBarRecipe, progressColor, progressValue } from './styles.css.vanilla.js';

const ProgressBar = extendComponent(Box, {
  className: ({ animated = false, indeterminate = false, showExceeding = false, size }) => progressBarRecipe({ animated, indeterminate, size, showExceeding }),
  style: ({ color = "yellow", value }) => assignInlineVars({
    [progressColor]: getColor(color),
    [progressValue]: `${value}`
  }),
  role: "progressbar",
  "aria-valuemin": 0,
  "aria-valuemax": 1,
  "aria-valuenow": (props) => !props.indeterminate ? props.value : void 0,
  children: null
});

export { ProgressBar };
