function evaluate(valueOrFn) {
  return (...args) => {
    if (typeof valueOrFn === "function") {
      return valueOrFn(...args);
    }
    return valueOrFn;
  };
}
const objectEntries = Object.entries;
const objectFromEntries = Object.fromEntries;

export { evaluate, objectEntries, objectFromEntries };
