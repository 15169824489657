import { camelCase, isEmpty, without } from 'lodash';
import pLimit from 'p-limit';

const padZero = (str: string, len = 2): string => {
  const nextLen = len || 2;
  const zeros = new Array(nextLen).join('0');
  return (zeros + str).slice(-nextLen);
};

export const RegexUtils = {
  isEmail: (email: string): boolean => {
    const emailRule =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return !!emailRule.test(email);
  },
  hasSpecialLetter: (letter: string) => {
    const specialLetters = /[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/g;
    return letter.match(specialLetters) || [];
  },
  isLowercaseLetter: (letter: string): boolean => {
    const lowercaseLetter = /^[a-z]/;
    return lowercaseLetter.test(letter);
  },
  hasLowercaseLetter: (letter: string): boolean => {
    const lowercaseLetter = /[a-z]/g;
    return !!letter.match(lowercaseLetter);
  },
  hasUppercaseLetter: (letter: string): boolean => {
    const uppercaseLetter = /[A-Z]/g;
    return !!letter.match(uppercaseLetter);
  },
  hasNumericDigit: (letter: string): boolean => {
    const numericDigit = /[0-9]/g;
    return !!letter.match(numericDigit);
  },
  isInAscii: (letter: string): boolean => {
    const ascii = /^[\x20-\x7F]+$/;
    return !!letter.match(ascii);
  },
  isUuid: (string: string): boolean => {
    const uuidRegex = /^[0-9a-fA-F]{8}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{12}$/i;
    return !!string.match(uuidRegex);
  },
  isNotValidPassword: (password: string): boolean => {
    const hasUppercase = RegexUtils.hasUppercaseLetter(password);
    const hasLowercase = RegexUtils.hasLowercaseLetter(password);
    const hasNumber = RegexUtils.hasNumericDigit(password);
    return password.length < 8 || !hasUppercase || !hasLowercase || !hasNumber;
  },
  // TODO (tsnoh): need fix
  hasAlphaNumericAllowSpace: (letter: string): boolean => {
    const alphaNumericAllowSpace = /[a-zA-Z0-9]/;
    return !!letter.match(alphaNumericAllowSpace);
  },
  hasAlphaNumericAndDash: (letter: string): boolean => {
    const alphaNumericAndDash = /[a-z\d\x45]/g;
    const match = letter.match(alphaNumericAndDash);
    if (!match) return false;
    if (match[0] === '-') return false;
    if (match[match.length - 1] === '-') return false;
    if (match.length < letter.length) return false;

    return true;
  },
  addComma: (number: string): string => {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return number.toString().replace(regexp, ',');
  },
  isGeneralNaming: (value: string): boolean => {
    const isFirstLetterLowercase = RegexUtils.isLowercaseLetter(value.charAt(0));
    const hasUppercaseLetter = RegexUtils.hasUppercaseLetter(value);
    const hasOtherSpecialLetters = !isEmpty(without(RegexUtils.hasSpecialLetter(value), '-'));
    const isAllInAsciiRange = RegexUtils.isInAscii(value);

    return (
      isAllInAsciiRange && isFirstLetterLowercase && !hasUppercaseLetter && !hasOtherSpecialLetters
    );
  },
  isOnlyNumericDigit: (value: string): boolean => {
    const numericDigit = /^[0-9]*$/g;
    return !!value.match(numericDigit);
  },
};

export const ColorUtils = {
  invertColor: (hex: string, bw: boolean): string => {
    let nextHex = hex;
    if (nextHex.indexOf('#') === 0) {
      nextHex = nextHex.slice(1);
    }
    // convert 3-digit nextHex to 6-digits.
    if (nextHex.length === 3) {
      nextHex = nextHex[0] + nextHex[0] + nextHex[1] + nextHex[1] + nextHex[2] + nextHex[2];
    }
    if (nextHex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r = parseInt(nextHex.slice(0, 2), 16);
    const g = parseInt(nextHex.slice(2, 4), 16);
    const b = parseInt(nextHex.slice(4, 6), 16);
    if (bw) {
      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    const r2 = (255 - r).toString(16);
    const g2 = (255 - g).toString(16);
    const b2 = (255 - b).toString(16);
    // pad each with zeros and return
    return `#${padZero(r2)}${padZero(g2)}${padZero(b2)}`;
  },
};

export const sleep = async (time = 1000): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, time));

export const convertHashToObject = (hash: string): Record<string, string> => {
  let str = hash;
  if (str[0] === '#') str = str.slice(1, hash.length);
  const strParts = str.split('&');

  const object: Record<string, string> = {};
  for (let i = 0; i < strParts.length; i++) {
    const [key, value] = strParts[i].split('=');
    object[camelCase(key)] = value;
  }

  return object;
};

export const concurrent = async <T, RT>(
  xs: T[],
  f: (value: T) => Promise<RT>,
  n = Infinity,
): Promise<RT[]> => {
  const limit = pLimit(n);
  return Promise.all(xs.map(item => limit(() => f(item))));
};
