import * as React from "react";
import { forwardRef } from "react";
const SvgGenAi = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { clipPath: "url(#gen-ai_svg__a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M5.75.75A.25.25 0 0 0 5.5 1v1h-1V1a.25.25 0 0 0-.25-.25h-.5A.25.25 0 0 0 3.5 1v1H3c-.551 0-1 .449-1 1v.5H1a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1v1H1a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1v1H1a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1V9c0 .552.449 1 1 1h.5v1c0 .138.112.25.25.25h.5A.25.25 0 0 0 4.5 11v-1h1v1c0 .138.112.25.25.25h.5A.25.25 0 0 0 6.5 11v-1h1v1c0 .138.112.25.25.25h.5A.25.25 0 0 0 8.5 11v-1H9c.552 0 1-.448 1-1v-.5h1a.25.25 0 0 0 .25-.25v-.5A.25.25 0 0 0 11 7.5h-1v-1h1a.25.25 0 0 0 .25-.25v-.5A.25.25 0 0 0 11 5.5h-1v-1h1a.25.25 0 0 0 .25-.25v-.5A.25.25 0 0 0 11 3.5h-1V3c0-.551-.448-1-1-1h-.5V1a.25.25 0 0 0-.25-.25h-.5A.25.25 0 0 0 7.5 1v1h-1V1a.25.25 0 0 0-.25-.25h-.5ZM3 9V3h6l.001 6H3Zm2.028-2.083-.445-.445L3.25 8.25h5.333L6.361 5.139 5.028 6.917Zm0-2a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Z"
    }
  )),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "gen-ai_svg__a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 0h12v12H0z" })))
);
const ForwardRef = forwardRef(SvgGenAi);
export default ForwardRef;
