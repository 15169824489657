import * as React from "react";
import { forwardRef } from "react";
const SvgVideoStream = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M16 6.462a.947.947 0 0 0-.051-.315.61.61 0 0 0-.069-.14L15.875 6H4.126A.81.81 0 0 0 4 6.462v11.076c0 .23.07.386.125.462h11.75l.005-.007a.612.612 0 0 0 .069-.14.947.947 0 0 0 .051-.315V6.462Zm2 8.11 2 .888V8.54l-2 .887v5.146Zm0-7.333 3.24-1.436c.333-.25.76.044.76.525v11.344c0 .48-.427.776-.76.525L18 16.761v.777c0 .35-.06.682-.166.983a2.706 2.706 0 0 1-.126.298C17.358 19.527 16.723 20 16 20H4c-1.105 0-2-1.102-2-2.462V6.462C2 5.102 2.895 4 4 4h12c.723 0 1.357.473 1.708 1.18A2.947 2.947 0 0 1 18 6.46v.78Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M8.5 9v6l5-3-5-3Z" })
);
const ForwardRef = forwardRef(SvgVideoStream);
export default ForwardRef;
