import * as React from "react";
import { forwardRef } from "react";
const SvgKeyboard = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M2.667 3.333a1.333 1.333 0 0 0-1.334 1.334v6.666a1.333 1.333 0 0 0 1.334 1.334h10.666a1.333 1.333 0 0 0 1.334-1.334V4.667a1.333 1.333 0 0 0-1.334-1.334H2.667Zm0 1.334h10.666v6.666H2.667V4.667Zm.666.666v1.334h1.334V5.333H3.333Zm2 0v1.334h1.334V5.333H5.333Zm2 0v1.334h1.334V5.333H7.333Zm2 0v1.334h1.334V5.333H9.333Zm2 0v1.334h1.334V5.333h-1.334Zm-8 2v1.334h1.334V7.333H3.333Zm2 0v1.334h1.334V7.333H5.333Zm2 0v1.334h1.334V7.333H7.333Zm2 0v1.334h1.334V7.333H9.333Zm2 0v1.334h1.334V7.333h-1.334Zm-6 2v1.334h5.334V9.333H5.333Z" })
);
const ForwardRef = forwardRef(SvgKeyboard);
export default ForwardRef;
