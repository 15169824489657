import * as React from "react";
import { forwardRef } from "react";
const SvgWindowCheck = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2ZM4 19V7h16l.001 12H4Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M16.707 10.414 11 16.121l-3.707-3.707L8.707 11 11 13.293 15.293 9l1.414 1.414Z" })
);
const ForwardRef = forwardRef(SvgWindowCheck);
export default ForwardRef;
