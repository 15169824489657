import * as React from "react";
import { forwardRef } from "react";
const SvgWarningFilled = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2.734 21.002a1 1 0 0 1-.865-1.501l9.265-16.004a1 1 0 0 1 1.731 0L22.131 19.5a1 1 0 0 1-.866 1.501H2.735ZM13 18h-.935a.992.992 0 0 1-.13 0H11v-2h2v2Zm0-3.998h-2v-5h2v5Z"
    }
  )
);
const ForwardRef = forwardRef(SvgWarningFilled);
export default ForwardRef;
