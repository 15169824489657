import * as React from "react";
import { forwardRef } from "react";
const SvgGrid3X3 = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M4 8h4V4H4v4ZM10 20h4v-4h-4v4ZM8 20H4v-4h4v4ZM4 14h4v-4H4v4ZM14 14h-4v-4h4v4ZM16 4v4h4V4h-4ZM14 8h-4V4h4v4ZM16 14h4v-4h-4v4ZM20 20h-4v-4h4v4Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgGrid3X3);
export default ForwardRef;
