import * as React from "react";
import { forwardRef } from "react";
const SvgSnapRelease = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M9 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM19 7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 20.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM7 22a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM17 10.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm0 1.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M4.55 3H3.446a.2.2 0 0 0-.15.333l15.407 17.331a1 1 0 0 0 .747.336h1.106a.2.2 0 0 0 .15-.333L5.297 3.336A1 1 0 0 0 4.551 3Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSnapRelease);
export default ForwardRef;
