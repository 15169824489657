import * as React from "react";
import { forwardRef } from "react";
const SvgPaperPlaneTilt = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M19.44 2.401a1.75 1.75 0 0 1 2.159 2.16l-4.927 17.468a1.75 1.75 0 0 1-3.266.274l-3.763-7.946-7.946-3.763a1.75 1.75 0 0 1 .274-3.266l.272.962-.272-.962L19.44 2.401Zm.113 2.046L3.156 9.072l7.423 3.516-.428.904-.048.101m.048-.101.428-.904c.366.173.66.467.833.833l-.904.428-.101.048m9.146-9.45-4.625 16.397-3.516-7.423-.904.428"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M15.343 8.657a1 1 0 0 1 0 1.414l-4.242 4.243a1 1 0 0 1-1.415-1.415l4.243-4.242a1 1 0 0 1 1.414 0Z"
    }
  )
);
const ForwardRef = forwardRef(SvgPaperPlaneTilt);
export default ForwardRef;
