import * as React from "react";
import { forwardRef } from "react";
const SvgKeyPointInvisibleButLabeled = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 17 16",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m4.956 3.501 8.485 8.484h.001l-.707.708-1.678-1.679c-.629.293-1.39.486-2.307.486-3.796 0-4.937-3.308-4.948-3.342L3.75 8l.053-.158c.006-.021.464-1.35 1.757-2.325l-1.31-1.31.706-.706Zm5.337 6.75-.898-.9a1.477 1.477 0 0 1-.645.15A1.51 1.51 0 0 1 7.25 8c0-.23.053-.45.148-.646l-1.13-1.13A4.27 4.27 0 0 0 4.808 8c.25.577 1.28 2.5 3.942 2.5a4.63 4.63 0 0 0 1.543-.25Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M7.5 4.63a5.83 5.83 0 0 1 1.25-.13c3.796 0 4.937 3.309 4.948 3.342L13.75 8l-.053.158c-.005.017-.285.829-1.021 1.648l-.696-.696c.393-.445.613-.882.712-1.11-.25-.577-1.28-2.5-3.942-2.5-.126 0-.249.004-.368.013L7.5 4.63Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2.75 16a1.994 1.994 0 0 1-1.848-1.234A1.994 1.994 0 0 1 .75 14v-1H2v1c0 .414.336.75.75.75h1V16h-1Zm11 0v-1.25h1a.75.75 0 0 0 .75-.75v-1h1.25v1a1.994 1.994 0 0 1-1.234 1.848 1.994 1.994 0 0 1-.766.152h-1Zm3-13H15.5V2a.75.75 0 0 0-.75-.75h-1V0h1a1.994 1.994 0 0 1 1.848 1.234c.098.236.152.495.152.766v1Zm-13-3h-1A1.994 1.994 0 0 0 .902 1.234 1.994 1.994 0 0 0 .75 2v1H2V2a.75.75 0 0 1 .75-.75h1V0Zm-3 11H2V9H.75v2Zm0-4H2V5H.75v2Zm5-7v1.25h2V0h-2Zm4 0v1.25h2V0h-2Zm7 5H15.5v2h1.25V5Zm0 4H15.5v2h1.25V9Zm-5 7v-1.25h-2V16h2Zm-4 0v-1.25h-2V16h2Z"
    }
  )
);
const ForwardRef = forwardRef(SvgKeyPointInvisibleButLabeled);
export default ForwardRef;
