import * as React from "react";
import { forwardRef } from "react";
const SvgConsensus = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 18v2h2v-2H4Zm-1-2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H3ZM11 18v2h2v-2h-2Zm-1-2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-4ZM18 18v2h2v-2h-2Zm-1-2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-4ZM13 13v3h-2v-3H7a1 1 0 0 0-1 1v2H4v-2a3 3 0 0 1 3-3h4V8h2v3h4a3 3 0 0 1 3 3v2h-2v-2a1 1 0 0 0-1-1h-4ZM13 6V4h-2v2h2Zm1 2a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4Z"
    }
  )
);
const ForwardRef = forwardRef(SvgConsensus);
export default ForwardRef;
