import * as React from "react";
import { forwardRef } from "react";
const SvgPlace = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M10 10c-.917 0-1.667-.75-1.667-1.666 0-.917.75-1.667 1.667-1.667s1.667.75 1.667 1.667C11.667 9.25 10.917 10 10 10Zm0-8.333c-3.5 0-6.667 2.683-6.667 6.833 0 2.767 2.225 6.042 6.667 9.834 4.442-3.792 6.667-7.067 6.667-9.834 0-4.15-3.167-6.833-6.667-6.833Z" })
);
const ForwardRef = forwardRef(SvgPlace);
export default ForwardRef;
