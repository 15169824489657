import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { Typography } from '../../foundation/Typography/Typography.js';
import '../../theme/color-util.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { atoms } from '../../theme/sprinkles.css.vanilla.js';
import { extendComponent } from '../../util/extend-component.js';
import { tableRecipe, captionRecipe } from './styles.css.vanilla.js';

const stickyTop = ({ sticky }) => (
  // @ts-ignore suppress TS2345
  sticky ? atoms({ position: "sticky", top: "0", backgroundColor: "white" }) : ""
);
const stickyBottom = ({ sticky }) => (
  // @ts-ignore suppress TS2345
  sticky ? atoms({ position: "sticky", bottom: "0", backgroundColor: "white" }) : ""
);
const Table = extendComponent(Box, {
  as: "table",
  className: ({ variant }) => tableRecipe({ variant })
});
const Head = extendComponent(Box, {
  as: "thead",
  className: stickyTop
});
const Footer = extendComponent(Box, {
  as: "tfoot",
  className: stickyBottom
});
const Body = extendComponent(Box, {
  as: "tbody"
});
const Row = extendComponent(Box, {
  as: "tr",
  className: stickyTop
});
const Cell = extendComponent(Typography, {
  variant: "m-regular",
  as: "td",
  textAlign: "left",
  p: 1
});
const HeadCell = extendComponent(Typography, {
  variant: "m-regular",
  as: "th",
  textAlign: "left",
  p: 1
});
const Caption = extendComponent(Typography, {
  as: "caption",
  variant: "m-strong",
  textAlign: "left",
  p: 1,
  className: ({ side }) => captionRecipe({ side })
});

export { Body, Caption, Cell, Footer, Head, HeadCell, Row, Table };
