import * as React from "react";
import { forwardRef } from "react";
const SvgKeypoints = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18 10a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM4 10a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM11 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM11 13a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM16 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 10.17V7.83a2.995 2.995 0 0 0 2 0v2.34a2.997 2.997 0 0 0-2 0Zm-1.206.797-1.8-.772a2.989 2.989 0 0 1-.788 1.838l1.8.772a2.988 2.988 0 0 1 .788-1.838Zm-1.751 5.22a3.01 3.01 0 0 1 1.536 1.28l1.378-1.653a3.01 3.01 0 0 1-1.536-1.28l-1.378 1.652Zm5-.373 1.378 1.653a3.01 3.01 0 0 1 1.536-1.28l-1.378-1.654a3.009 3.009 0 0 1-1.536 1.28Zm1.95-3.009 1.801-.772a2.989 2.989 0 0 1-.788-1.838l-1.8.772c.452.49.742 1.13.788 1.838Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    }
  )
);
const ForwardRef = forwardRef(SvgKeypoints);
export default ForwardRef;
