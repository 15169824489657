import * as React from "react";
import { forwardRef } from "react";
const SvgUpload = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M13 18.5V6l3.59 3.58L18 8.17l-6-6-6 6 1.41 1.41L11 6v12.5h2ZM19 22H5v-2h14v2Z" })
);
const ForwardRef = forwardRef(SvgUpload);
export default ForwardRef;
