import * as React from "react";
import { forwardRef } from "react";
const SvgBrushMode = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M2.919 18.623c2.438 2.147 5.834 1.882 7.8.874a3.385 3.385 0 0 0 .847-5.624 3.364 3.364 0 0 0-4.756.308 3.365 3.365 0 0 0-.802 1.733c-.393 1.892-1.362 2.18-2.448 2.505-.21.063-.425.127-.641.204ZM20.714 6.186l-6.508 8.778c-.19-.926-.67-1.8-1.433-2.473a4.452 4.452 0 0 0-2.627-1.102l7.841-7.604a1.816 1.816 0 0 1 2.472-.065c.715.63.826 1.704.255 2.466Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgBrushMode);
export default ForwardRef;
