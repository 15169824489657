import * as React from "react";
import { forwardRef } from "react";
const SvgLoadingDots = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("circle", { cx: 6, cy: 12, r: 0 }, /* @__PURE__ */ React.createElement("animate", { attributeName: "r", values: "0;2;0", dur: "1.5s", repeatCount: "indefinite" })),
  /* @__PURE__ */ React.createElement("circle", { cx: 12, cy: 12, r: 0 }, /* @__PURE__ */ React.createElement("animate", { attributeName: "r", values: "0;2;0", dur: "1.5s", begin: ".5s", repeatCount: "indefinite" })),
  /* @__PURE__ */ React.createElement("circle", { cx: 18, cy: 12, r: 0 }, /* @__PURE__ */ React.createElement("animate", { attributeName: "r", values: "0;2;0", dur: "1.5s", begin: "1s", repeatCount: "indefinite" }))
);
const ForwardRef = forwardRef(SvgLoadingDots);
export default ForwardRef;
