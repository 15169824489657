import * as React from "react";
import { forwardRef } from "react";
const SvgSecurityCheck = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m12 1 8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A5.998 5.998 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976L12 1Zm0 2.049L5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A3.998 3.998 0 0 0 19 13.79V4.604L12 3.05v-.001Zm4.452 5.173 1.415 1.414L11.503 16 7.26 11.757l1.414-1.414 2.828 2.828 4.95-4.95v.001Z" })
);
const ForwardRef = forwardRef(SvgSecurityCheck);
export default ForwardRef;
