import * as React from "react";
import { forwardRef } from "react";
const SvgAddToQueue = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M13 6h2v3h3v2h-3v3h-2v-3h-3V9h3V6Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 2h12c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2H8c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2Zm0 2v12h12.002L20 4H8Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M2 8h2v12h12v2H4c-1.103 0-2-.897-2-2V8Z", fill: "currentColor" })
);
const ForwardRef = forwardRef(SvgAddToQueue);
export default ForwardRef;
