import Axios from 'axios';

export type LegacyUrl = string;
export type MRAPUrl = {
  url: string; // 1차로 접근 시도
  replacement?: string | 'NO_REPLACEMENT'; // 1차 실패 시 2차로 접근 시도
  additionalHeaders?: AdditionalHeaders; // 있을 경우 get 할 때 hedaers에 넣어줘야 함
};

function createUrlKey(url: LegacyUrl | MRAPUrl) {
  if (typeof url === 'string') {
    return url;
  }

  return url.url;
}

const blobMap = new Map();
const defaultFetchUrlAsBlobOnMRAPOption: FetchUrlAsBlobOnMRAPOption = {
  useCache: false,
  noDiskCache: false,
};
export type FetchUrlAsBlobOnMRAPOption = {
  useCache?: boolean;
  noDiskCache?: boolean;
};

// get 에만 사용 가능
export async function fetchUrlAsBlobOnMRAP(
  url: LegacyUrl | MRAPUrl,
  option?: FetchUrlAsBlobOnMRAPOption,
) {
  const _option = { ...defaultFetchUrlAsBlobOnMRAPOption, ...option };
  const urlKey = createUrlKey(url);

  if (_option.useCache && blobMap.get(urlKey)) {
    return blobMap.get(urlKey);
  }

  let result = '';
  let isUrlExpired = false;
  let useReplacement = false;
  const axios = Axios.create({});

  // legacy
  if (typeof url === 'string') {
    try {
      result = await axios
        .get<Blob>(url, {
          responseType: 'blob',
          headers: {
            ...(_option.noDiskCache ? getNoDiskCacheHeaders() : {}),
          },
        })
        .then(res => URL.createObjectURL(res.data));
    } catch (error) {
      throw error;
    }
  } else {
    // MRAP
    try {
      result = await axios
        .get<Blob>(url.url, {
          responseType: 'blob',
          headers: {
            ...(_option.noDiskCache ? getNoDiskCacheHeaders() : {}),
            ...getAdditionalHeaders(url.additionalHeaders || []),
          },
        })
        .then(res => {
          return URL.createObjectURL(res.data);
        });
    } catch (error) {
      if (getIsUrlExpired(error)) {
        isUrlExpired = true;
      }

      if (canUseReplacement(url)) {
        useReplacement = true;
      }
    }

    if (useReplacement) {
      try {
        result = await axios
          .get<Blob>(url.replacement!, {
            responseType: 'blob',
            ...(_option.noDiskCache ? getNoDiskCacheHeaders() : {}),
            headers: getAdditionalHeaders(url.additionalHeaders || []),
          })
          .then(res => URL.createObjectURL(res.data));
      } catch (error) {
        if (getIsUrlExpired(error)) {
          isUrlExpired = true;
        } else {
          throw error;
        }
      }
    }

    if (isUrlExpired) {
      throw 'EXPIRED';
    }
  }

  if (_option.useCache) {
    blobMap.set(urlKey, result);
  }

  return result;
}

export type AdditionalHeaders = Record<string, string>[];
export function getAdditionalHeaders(additionalHeaders: AdditionalHeaders) {
  const result: Record<string, string> = {};

  for (let i = 0; i < additionalHeaders.length; i++) {
    const currentHeader = additionalHeaders[i];
    const [key, value] = Object.entries(currentHeader)[0];
    const dashedKey = httpHeaderKeysCamelToDashMap[key];

    if (typeof dashedKey !== 'string') {
      throw new Error(`header map does not have key: ${key}.`);
    }
    result[dashedKey] = value;
  }
  return result;
}
const httpHeaderKeysCamelToDashMap: Record<string, string> = {
  xAmzTagging: 'x-amz-tagging',
};

function getNoDiskCacheHeaders() {
  return { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' };
}

export function getIsUrlExpired(error: unknown) {
  return (
    Axios.isAxiosError(error) && (error.response?.status === 400 || error.response?.status === 403)
  );
}
export function canUseReplacement(url: MRAPUrl) {
  return url.replacement && url.replacement !== 'NO_REPLACEMENT';
}
