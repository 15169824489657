import StringUtils from './StringUtils';

// Todo(@Yein): match into firstname or givenname
interface BasicUserProps {
  email: string;
  firstName: string;
  lastName: string;
}

const OWNER = 'Owner';
const ADMIN = 'Admin';
const COLLABORATOR = 'Collaborator';

type UtmParamsType = { utmParams: Record<'utm_campaign' | 'utm_source' | 'utm_medium', string> };

function formatPlan(plan: string): string {
  return plan.substring(0, 6).toUpperCase();
}

export const identify = ({ email }: { email: string }): undefined => {
  if (!window.analytics) return;
  window.analytics.identify(
    email,
    {},
    {
      integrations: {
        HubSpot: false,
        Intercom: false,
        Hotjar: false,
      },
    },
  );
};

interface SignUpProps extends Omit<BasicUserProps, 'firstName' | 'lastName'> {
  accountName: string;
  plan: string;
  companyName: string;
  country: string;
  givenName: string;
  familyName: string;
  krMarketingConsentAgreed?: boolean;
  krNewsletterConsentAgreed?: boolean;
}

export const identifyAndGroupOnSignUp = ({
  email,
  givenName,
  familyName,
  accountName,
  companyName,
  country,
  plan,
  utmParams,
  krMarketingConsentAgreed,
  krNewsletterConsentAgreed,
}: SignUpProps & UtmParamsType): undefined => {
  if (!window.analytics) return;

  window.analytics.identify(
    email,
    {
      email,
      firstName: StringUtils.capitalize(givenName),
      lastName: StringUtils.capitalize(familyName),
      company: companyName,
      country,
      suite_user_role: OWNER,
      suite_team_name__owner_: accountName,
      suite_team_plan: formatPlan(plan),
      ...(krMarketingConsentAgreed !== undefined && {
        kr_marketing_platform_agreement: krMarketingConsentAgreed,
      }),
      ...(krNewsletterConsentAgreed !== undefined && {
        kr_newsletter_agreement: krNewsletterConsentAgreed,
      }),
    },
    {
      context: {
        campaign: {
          name: utmParams?.utm_campaign,
          source: utmParams?.utm_source,
          medium: utmParams?.utm_medium,
        },
      },
    },
  );

  window.analytics.group(
    accountName,
    {
      plan: formatPlan(plan),
      name: companyName,
      userId: email,
    },
    {
      integrations: {
        HubSpot: false,
      },
    },
  );
};

interface AdminSignUpTypes extends BasicUserProps {
  accountId: string;
}

export const identifyAdminOnForceChange = ({
  email,
  firstName,
  lastName,
  accountId,
}: AdminSignUpTypes): undefined => {
  if (!window.analytics) return;

  window.analytics.identify(email, {
    email,
    firstName: StringUtils.capitalize(firstName),
    lastName: StringUtils.capitalize(lastName),
    suite_user_role: ADMIN,
    suite_team_name__admin_: accountId,
  });

  window.analytics.group(
    accountId,
    {
      userId: email,
    },
    {
      integrations: {
        HubSpot: false,
      },
    },
  );
};

export const identifyCollaboratorOnForceChange = ({
  email,
  firstName,
  lastName,
}: BasicUserProps): undefined => {
  if (!window.analytics) return;

  window.analytics.identify(
    email,
    {
      email,
      firstName: StringUtils.capitalize(firstName),
      lastName: StringUtils.capitalize(lastName),
      suite_user_role: COLLABORATOR,
    },
    {
      integrations: {
        Intercom: { hideDefaultLauncher: true },
        HubSpot: false,
        Hotjar: false,
      },
    },
  );
};

interface LoginTypes extends BasicUserProps {
  accountId: string;
  plan: string;
}

export const identifyOwnerOnLogin = ({
  email,
  accountId,
  plan,
  firstName,
  lastName,
}: LoginTypes): undefined => {
  if (!window.analytics) return;

  window.analytics.identify(
    email,
    {
      email,
      firstName: StringUtils.capitalize(firstName),
      lastName: StringUtils.capitalize(lastName),
      suite_user_role: OWNER,
      suite_team_name__owner_: accountId,
      suite_team_plan: formatPlan(plan),
    },
    {
      integrations: {
        HubSpot: false,
      },
    },
  );

  window.analytics.group(
    accountId,
    {
      plan: formatPlan(plan),
    },
    {
      integrations: {
        HubSpot: false,
      },
    },
  );
};

export const identifyAdminOnLogin = ({
  email,
  accountId,
  plan,
  firstName,
  lastName,
}: LoginTypes): undefined => {
  if (!window.analytics) return;

  window.analytics.identify(
    email,
    {
      email,
      firstName: StringUtils.capitalize(firstName),
      lastName: StringUtils.capitalize(lastName),
      suite_user_role: ADMIN,
      suite_team_name__admin_: accountId,
      suite_team_plan: formatPlan(plan),
    },
    {
      integrations: {
        HubSpot: false,
      },
    },
  );

  window.analytics.group(
    accountId,
    {
      plan: formatPlan(plan),
    },
    {
      integrations: {
        HubSpot: false,
      },
    },
  );
};

export const identifyCollaboratorOnLogin = ({
  email,
  accountId,
  plan,
}: {
  email: string;
  accountId: string;
  plan: string;
}): undefined => {
  if (!window.analytics) return;

  window.analytics.identify(
    email,
    {
      email,
      suite_user_role: COLLABORATOR,
      suite_team_plan: formatPlan(plan),
    },
    {
      integrations: { Intercom: { hideDefaultLauncher: true }, HubSpot: false, Hotjar: false },
    },
  );
  window.analytics.group(
    accountId,
    {},
    {
      integrations: {
        HubSpot: false,
        Intercom: false,
        Hotjar: false,
      },
    },
  );
};

export const identifyOwnerNameChange = ({
  email,
  firstName,
  lastName,
}: BasicUserProps): undefined => {
  if (!window.analytics) return;

  window.analytics.identify(email, {
    email,
    firstName: StringUtils.capitalize(firstName),
    lastName: StringUtils.capitalize(lastName),
  });
};

export const identifyAdminNameChange = ({
  email,
  firstName,
  lastName,
}: BasicUserProps): undefined => {
  if (!window.analytics) return;

  window.analytics.identify(email, {
    email,
    firstName: StringUtils.capitalize(firstName),
    lastName: StringUtils.capitalize(lastName),
  });
};

export const identifyCollaboratorNameChange = ({
  email,
  firstName,
  lastName,
}: BasicUserProps): undefined => {
  if (!window.analytics) return;
  window.analytics.identify(
    email,
    {
      email,
      firstName: StringUtils.capitalize(firstName),
      lastName: StringUtils.capitalize(lastName),
    },
    {
      integrations: { Intercom: { hideDefaultLauncher: true }, HubSpot: false, Hotjar: false },
    },
  );
};

export const resetSegmentOnLogout = (): undefined => {
  if (!window.analytics) return;

  window.analytics.reset();
};
