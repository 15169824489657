import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { RouteComponentProps } from 'react-router';
import * as RouterDOM from 'react-router-dom';

import { fromPairs } from 'lodash';

import { analytics } from '../analyticsTracker/segmentUtils';
import { StateGetterSetter } from './types';

type RouteInnerProps = StateGetterSetter<['urlMatchInfo', 'setUrlMatchInfo'], Record<string, any>> &
  Pick<RouteComponentProps, 'history' | 'location'>;

// prettier-ignore
export type RouteContextProps = RouteInnerProps & {
  hash: string;
  params: Record<string, any>;
};

export const RouteContext = React.createContext({} as RouteContextProps);

const RouteInnerProvider: React.FC<RouteInnerProps> = ({
  location,
  history,
  urlMatchInfo,
  setUrlMatchInfo,
  children,
}) => {
  const { accountName } = urlMatchInfo;
  const params = fromPairs(Array.from(new URLSearchParams(location.search)));
  const [, setCookie] = useCookies();

  useEffect(() => {
    const utmParams = (() => {
      return Object.entries(params).reduce((acc: Record<string, any>, entry: string[]) => {
        const [key, value] = entry;
        if (key.includes('utm')) {
          return { ...acc, ...{ [key]: value } };
        }
        return acc;
      }, {});
    })();

    if (Object.keys(utmParams).length === 0) return;
    setCookie('spb_lead', { parameters: utmParams }, { domain: '.superb-ai.com' });
    // eslint-disable-next-line
  }, []);

  const trackPageView = useCallback(() => {
    const pageId = document.documentElement.getAttribute('data-page-id');

    if (pageId)
      analytics.page({
        // Event name resolves to "Viewed Page" in Segment and Mixpanel
        name: 'Page',
        accountId: accountName,
        pageId: pageId,
        title: document.title,
        url: window.location.href,
        path: window.location.pathname,
        referrer: document.referrer,
        search: window.location.search,
        // Include any additional data here
      });
  }, [accountName]);

  useEffect(() => {
    // Catch the first change to document.title which often doesn't come with a history change
    const titleEl = document.querySelector('title');
    let observer: MutationObserver;
    if (titleEl) {
      observer = new MutationObserver(() => {
        if (document.title === 'Superb Platform') return;
        trackPageView();
        if (observer) observer.disconnect();
      });
      observer.observe(titleEl, { subtree: true, characterData: true, childList: true });
    }

    // Use timeout to debounce history changes
    let timeout: NodeJS.Timeout | null = null;
    return history.listen(() => {
      if (observer) observer.disconnect();
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(trackPageView, 250);
    });
    // eslint-disable-next-line
  }, [history, trackPageView]);

  return (
    <RouteContext.Provider
      value={{
        history,
        location,
        hash: location.hash,
        params,
        urlMatchInfo,
        setUrlMatchInfo,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

export const RouteProvider: React.FC = ({ children }) => {
  const [urlMatchInfo, setUrlMatchInfo] = useState({});
  return (
    <RouterDOM.BrowserRouter>
      <RouterDOM.Route
        render={({ location, history }) => {
          return (
            <RouteInnerProvider
              location={location}
              history={history}
              urlMatchInfo={urlMatchInfo}
              setUrlMatchInfo={setUrlMatchInfo}
            >
              {children}
            </RouteInnerProvider>
          );
        }}
      />
    </RouterDOM.BrowserRouter>
  );
};

export const useRouteInfo = (): RouteContextProps => {
  return React.useContext(RouteContext);
};
