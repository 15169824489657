import * as React from "react";
import { forwardRef } from "react";
const SvgNote = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M9.5 1.5h-7c-.552 0-1 .448-1 1v7c0 .552.448 1 1 1h4a.498.498 0 0 0 .354-.146l3.5-3.5a.498.498 0 0 0 .098-.147c.007-.015.01-.03.016-.046a.496.496 0 0 0 .025-.13c.002-.01.007-.02.007-.031v-4c0-.552-.448-1-1-1Zm-7 1h7V6h-3a.5.5 0 0 0-.5.5v3H2.5v-7ZM7 8.793V7h1.793L7 8.793Z" })
);
const ForwardRef = forwardRef(SvgNote);
export default ForwardRef;
