import * as React from "react";
import { forwardRef } from "react";
const SvgCheckFilled = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12Zm8 2.17 6.59-6.59L18 9l-8 8-4-4 1.41-1.41L10 14.17Z"
    }
  )
);
const ForwardRef = forwardRef(SvgCheckFilled);
export default ForwardRef;
