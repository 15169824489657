import * as React from "react";
import { forwardRef } from "react";
const SvgUserSetting = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM19.493 8.235a3.184 3.184 0 0 0-.328-.167l-.164-.753A.4.4 0 0 0 18.61 7h-1.456a.4.4 0 0 0-.391.315l-.165.753a3.19 3.19 0 0 0-.614.354l-.735-.234a.4.4 0 0 0-.467.181l-.728 1.262a.4.4 0 0 0 .077.495l.57.52a3.193 3.193 0 0 0 0 .708l-.57.52a.4.4 0 0 0-.077.495l.728 1.261a.4.4 0 0 0 .467.182l.735-.234a3.189 3.189 0 0 0 .614.354l.165.753a.4.4 0 0 0 .39.315h1.457a.4.4 0 0 0 .39-.315l.165-.753a3.21 3.21 0 0 0 .614-.354l.735.234a.4.4 0 0 0 .468-.181l.728-1.261a.4.4 0 0 0-.077-.496l-.57-.52a3.2 3.2 0 0 0 0-.708l.57-.52a.4.4 0 0 0 .077-.495l-.728-1.261a.4.4 0 0 0-.468-.182l-.735.234a3.21 3.21 0 0 0-.286-.187ZM17.882 12.6a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M12 19h2a6 6 0 0 0-12 0h2a4 4 0 0 1 8 0Z", fill: "currentColor" })
);
const ForwardRef = forwardRef(SvgUserSetting);
export default ForwardRef;
