import jwtDecode from 'jwt-decode';

const REGION_TO_ENDPOINT_MAP = {
  default: process.env.NEXT_PUBLIC_API_HOST,
  'us-east-1': process.env.NEXT_PUBLIC_API_HOST_US_EAST_1,
  'ap-northeast-2': process.env.NEXT_PUBLIC_API_HOST_AP_NORTHEAST_2,
} as const;
type Region = keyof typeof REGION_TO_ENDPOINT_MAP;

const defaultEndpoint = REGION_TO_ENDPOINT_MAP['default'];

type CustomTenantRole = 'Owner' | 'Admin' | 'Collaborator' | 'SystemAdmin';

export function getEndpoint(idToken?: string | null) {
  if (!idToken) return defaultEndpoint;
  const idTokenPayload = jwtDecode<{ iss: Region; 'custom:tenant_role': CustomTenantRole }>(
    idToken,
  );

  const role = idTokenPayload['custom:tenant_role'];
  if (role === 'SystemAdmin') {
    return defaultEndpoint;
  }

  const { iss } = idTokenPayload;
  const region = Object.keys(REGION_TO_ENDPOINT_MAP).find(key => iss.match(key)) as Region;
  return REGION_TO_ENDPOINT_MAP[region] || defaultEndpoint;
}

const REGION_TO_CURATE_ENDPOINT_MAP = {
  default: process.env.NEXT_PUBLIC_CURATE_GLOBAL_API_HOST_AP_NORTHEAST_2,
  'us-east-1': process.env.NEXT_PUBLIC_CURATE_GLOBAL_API_HOST_US_EAST_1,
  'ap-northeast-2': process.env.NEXT_PUBLIC_CURATE_GLOBAL_API_HOST_AP_NORTHEAST_2,
} as const;

export function getCurateEndpoint(idToken?: string | null) {
  if (!idToken) return defaultEndpoint;
  const idTokenPayload = jwtDecode<{ iss: Region }>(idToken);

  const { iss } = idTokenPayload;
  const region = Object.keys(REGION_TO_ENDPOINT_MAP).find(key => iss.match(key)) as Region;
  return REGION_TO_CURATE_ENDPOINT_MAP[region] || defaultEndpoint;
}

const REGION_TO_MODEL_ENDPOINT_MAP = {
  default: process.env.NEXT_PUBLIC_MODEL_API_HOST_AP_NORTHEAST_2,
  'us-east-1': process.env.NEXT_PUBLIC_MODEL_API_HOST_US_EAST_1,
  'ap-northeast-2': process.env.NEXT_PUBLIC_MODEL_API_HOST_AP_NORTHEAST_2,
} as const;

export function getModelEndpoint(idToken?: string | null) {
  if (!idToken) return defaultEndpoint;
  const idTokenPayload = jwtDecode<{ iss: Region }>(idToken);

  const { iss } = idTokenPayload;
  const region = Object.keys(REGION_TO_ENDPOINT_MAP).find(key => iss.match(key)) as Region;
  return REGION_TO_MODEL_ENDPOINT_MAP[region] || defaultEndpoint;
}
