import * as React from "react";
import { forwardRef } from "react";
const SvgChevronDuoUp = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m12 5.575 6.01 6.01L16.596 13 12 8.404 7.404 13l-1.415-1.414L12 5.576Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "m12 11 6.01 6.01-1.414 1.415L12 13.828l-4.596 4.597-1.415-1.415L12 11Z" })
);
const ForwardRef = forwardRef(SvgChevronDuoUp);
export default ForwardRef;
