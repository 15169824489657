import * as React from "react";
import { forwardRef } from "react";
const SvgSortAlphabeticalDesc = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5.32 10.546V9.56l3.094-4.13H5.333V4h5.343v.984l-3.093 4.13h3.08v1.431H5.32Zm1.22 9.45H4.624l2.16-6.546h2.43l2.16 6.546H9.455l-.378-1.253H6.92l-.378 1.253Zm1.484-4.743.652 2.16H7.32l.652-2.16h.052ZM17 20l-3-3.478h2.25V4h1.5v12.522H20L17 20Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSortAlphabeticalDesc);
export default ForwardRef;
