import * as React from "react";
import { forwardRef } from "react";
const SvgInfoChat = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 20 18",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 1.5A1.5 1.5 0 0 1 1.5 0h17A1.5 1.5 0 0 1 20 1.5v12a1.5 1.5 0 0 1-1.5 1.5H4.286L0 18V1.5Zm9 3v-2h2v2H9Zm0 8v-4H8v-2h3v4h1v2H9Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgInfoChat);
export default ForwardRef;
