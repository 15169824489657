import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import '../../theme/color-util.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { atoms } from '../../theme/sprinkles.css.vanilla.js';

const Icon = forwardRef(function Icon2({ icon: I, color, size, ...props }, ref) {
  if (!I) {
    throw new Error(`Icon element is invalid. Expected a class/function but got: ${I}.`);
  }
  return (
    //@ts-ignore I is a component, not a string
    /* @__PURE__ */ jsx(
      I,
      {
        ...mergeProps(props, {
          ref,
          // @ts-ignore surpress TS2345
          className: atoms({ color, display: "inline-flex" }),
          style: { fontSize: size, ...props.style }
        })
      }
    )
  );
});

export { Icon };
