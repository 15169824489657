import * as React from "react";
import { forwardRef } from "react";
const SvgTrash = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M15 2H9c-1.103 0-2 .897-2 2v1H3v2h2v13c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V7h2V5h-4V4c0-1.103-.897-2-2-2ZM9 4h6v1H9V4Zm8 16H7V7h10v13Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M9 9h2v9H9V9ZM13 9h2v9h-2V9Z" })
);
const ForwardRef = forwardRef(SvgTrash);
export default ForwardRef;
