import * as React from "react";
import { forwardRef } from "react";
const SvgLoadingSpinnerAltStatic = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 64 64",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { strokeWidth: 5.2, stroke: "currentColor" }, /* @__PURE__ */ React.createElement("path", { d: "m35.625 13.35 1.527-7.854M42.487 16.156l4.415-6.67M47.752 21.375l6.632-4.473M50.619 28.212l7.839-1.595M50.65 35.625l7.854 1.527M47.844 42.487l6.67 4.415M42.625 47.752l4.473 6.632M35.788 50.619l1.595 7.839M28.375 50.65l-1.527 7.854M21.513 47.844l-4.415 6.67M16.248 42.625l-6.632 4.473M13.381 35.788l-7.839 1.595M13.35 28.375l-7.854-1.527M16.156 21.513l-6.67-4.415M21.375 16.248l-4.473-6.632M28.212 13.381l-1.595-7.839" }))
);
const ForwardRef = forwardRef(SvgLoadingSpinnerAltStatic);
export default ForwardRef;
