import * as React from "react";
import { forwardRef } from "react";
const SvgWorkflow = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM17 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM19.354 6.707l-2.122-2.121-2.121 2.121 2.121 2.121 2.122-2.12Zm-2.122-4.95-4.95 4.95 4.95 4.95 4.95-4.95-4.95-4.95ZM9 15H5v4h4v-4Zm-6-2v8h8v-8H3Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M6 9h2v6H6zM9 5.5h6v2H9zM16 9h2v5h-2z" })
);
const ForwardRef = forwardRef(SvgWorkflow);
export default ForwardRef;
