import * as React from "react";
import { forwardRef } from "react";
const SvgMislabelDetection = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "m13.707 15.121 1.414-1.414 6.364 6.364-1.414 1.414z" }),
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      x: 14.796,
      y: 17.624,
      width: 4,
      height: 8.25,
      rx: 1,
      transform: "rotate(-45 14.796 17.624)",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 2.252A8.014 8.014 0 0 0 2.252 8h2.09A6.017 6.017 0 0 1 8 4.341V2.252Zm4 2.09v-2.09A8.014 8.014 0 0 1 17.748 8h-2.09A6.017 6.017 0 0 0 12 4.341ZM15.659 12A6.017 6.017 0 0 1 12 15.659v2.089A8.014 8.014 0 0 0 17.748 12h-2.09ZM8 17.748v-2.09A6.016 6.016 0 0 1 4.341 12H2.252A8.014 8.014 0 0 0 8 17.748Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M9 0h2v5H9zM9 15h2v5H9zM0 9h5v2H0zM15 9h5v2h-5z" }),
  /* @__PURE__ */ React.createElement("circle", { cx: 10, cy: 10, r: 2, fill: "currentColor" })
);
const ForwardRef = forwardRef(SvgMislabelDetection);
export default ForwardRef;
