import * as React from "react";
import { forwardRef } from "react";
const SvgLinkExternal = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m5.824 6.662 3.671-3.67v2.091h1.167V1H6.578v1.167H8.67L5 5.837l.824.825Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M10.08 9.167V6.833H8.913v2.334H2.497V2.75H4.83V1.583H2.497c-.645 0-1.167.523-1.167 1.167v6.417c0 .644.522 1.166 1.167 1.166h6.416c.645 0 1.167-.522 1.167-1.166Z" })
);
const ForwardRef = forwardRef(SvgLinkExternal);
export default ForwardRef;
