import * as React from "react";
import { forwardRef } from "react";
const SvgBringToFront = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 17 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.5 3v3.333h.8v1H3.167a.667.667 0 0 1-.667-.666v-4c0-.369.298-.667.667-.667h4c.368 0 .666.298.666.667V3.8h-1V3H3.5ZM13.5 13V9.667h-.8v-1h1.133c.368 0 .667.298.667.666v4a.667.667 0 0 1-.667.667h-4a.667.667 0 0 1-.666-.667V12.2h1v.8H13.5Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("rect", { x: 5, y: 4.5, width: 7, height: 7, rx: 0.667, fill: "currentColor" })
);
const ForwardRef = forwardRef(SvgBringToFront);
export default ForwardRef;
