import * as React from "react";
import { forwardRef } from "react";
const SvgSortAlphabeticalAsc = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6.918 10.546H5L7.16 4h2.43l2.16 6.546H9.832l-.378-1.253H7.296l-.378 1.253ZM8.4 5.803l.652 2.16H7.697l.652-2.16h.052ZM5.697 19.995v-.984l3.093-4.13H5.71V13.45h5.343v.984l-3.094 4.13h3.082v1.431H5.696ZM17 20l-3-3.478h2.25V4h1.5v12.522H20L17 20Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSortAlphabeticalAsc);
export default ForwardRef;
