const IS_ONLY_NUMERIC = (str: string): boolean => {
  const regex = /^[0-9]+$/;
  return regex.test(str);
};

const IS_INTEGER = (str: string): boolean => {
  const regex = /^-?\d*\.{0,1}\d+$/;
  return regex.test(str);
};

const IS_EMAIL = (str: string): boolean => {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  return regex.test(str);
};

const IS_ONLY_LOWER_UPPER = (str: string): boolean => {
  const regex = /^[a-zA-Z]+$/;
  return regex.test(str);
};

const IS_ONLY_LOWER_UPPER_NUMERIC_HYPHEN = (str: string): boolean => {
  const regex = /^[a-zA-Z0-9-]+$/;
  return regex.test(str);
};

const IS_LOWER = (str: string): boolean => {
  const regex = /[a-z]/g;
  return regex.test(str);
};

const IS_UPPER = (str: string): boolean => {
  const regex = /[A-Z]/g;
  return regex.test(str);
};

const IS_NUMERIC = (str: string): boolean => {
  const regex = /[0-9]/g;
  return regex.test(str);
};

const IS_SPECIAL = (str: string): boolean => {
  // - 는 마지막에 넣어야한다.
  const regex = /[₩`~!@#$%^&*()_=+\[{\]}\\\|;:'",<.>\/?-]/;

  return regex.test(str);
};

const IS_ASCII = (str: string): boolean => {
  const regex = /^[\x20-\x7F]+$/;
  return regex.test(str);
};

// check symbols \/:;*?"<>|
const HAS_SPECIAL_SYMBOLS = (str: string): boolean => {
  const regex = /[<>:;"/\\|?*\u0000-\u001F]/g;
  return regex.test(str);
};

const IS_URL = (str: string): boolean => {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return regex.test(str);
};

const IS_NUMBER_TYPE = (str: string): boolean => {
  const regex = /^([\+\-]?)((\d+)|(\d+)\.(\d+))$/;
  return regex.test(str);
};

const IS_DATE_TYPE = (str: string): boolean => {
  const regex =
    /^((\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})|(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z)$/;
  return regex.test(str);
};

export default {
  IS_ONLY_NUMERIC,
  IS_INTEGER,
  IS_EMAIL,
  IS_ONLY_LOWER_UPPER,
  IS_ONLY_LOWER_UPPER_NUMERIC_HYPHEN,
  IS_LOWER,
  IS_UPPER,
  IS_NUMERIC,
  IS_SPECIAL,
  IS_ASCII,
  HAS_SPECIAL_SYMBOLS,
  IS_URL,
  IS_NUMBER_TYPE,
  IS_DATE_TYPE,
};
