import * as React from "react";
import { forwardRef } from "react";
const SvgFileArchive = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m9.964 4.326.005.014a.495.495 0 0 1 .024.129l.004.014c.001.006.003.011.003.017V10c0 .552-.448 1-1 1H3c-.551 0-1-.448-1-1V2c0-.552.449-1 1-1h.5v1h1v1h-1v1h1v1h-1v3.5h2V6h-1V5h1V4h-1V3h1V2h-1V1h2c.006 0 .011.002.017.003l.014.004a.496.496 0 0 1 .176.042.498.498 0 0 1 .147.098l3 3a.498.498 0 0 1 .098.146c.005.01.008.022.012.033ZM6.5 4.5V2L9 4.5H6.5Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M5 8V7H4v1h1Z", fill: "currentColor" })
);
const ForwardRef = forwardRef(SvgFileArchive);
export default ForwardRef;
