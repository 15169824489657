import * as React from "react";
import { forwardRef } from "react";
const SvgReset = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M12 4.01a7.965 7.965 0 0 0-5.66 2.337 7.992 7.992 0 0 0 .003 11.31 7.992 7.992 0 0 0 11.31.003c2.64-2.639 3.037-6.642 1.227-9.712L17.41 9.42a5.994 5.994 0 0 1-1.17 6.826 6.007 6.007 0 0 1-8.488.003 6.007 6.007 0 0 1 .003-8.488C8.93 6.586 10.464 6.027 12 6.034L11.996 10l4.953-4.364L12 1v3.01Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgReset);
export default ForwardRef;
