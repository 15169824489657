import * as React from "react";
import { forwardRef } from "react";
const SvgSortNumericDesc = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m17 18.5-3-3.478h2.25V4.978h1.5v10.044H20L17 18.5ZM12 8V6H3.023v2H12Zm-3 5H3v-2h6v2Zm-3 5H3v-2h3v2Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSortNumericDesc);
export default ForwardRef;
