import * as React from "react";
import { forwardRef } from "react";
const SvgLogOut = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M8 10.667 11.333 8 8 5.333v2H2v1.334h6v2Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M12.667 2h-6c-.736 0-1.334.598-1.334 1.333V6h1.334V3.333h6v9.334h-6V10H5.333v2.667c0 .735.598 1.333 1.334 1.333h6c.735 0 1.333-.598 1.333-1.333V3.333C14 2.598 13.402 2 12.667 2Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgLogOut);
export default ForwardRef;
