import * as React from "react";
import { forwardRef } from "react";
const SvgUsers = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M14 20h-2c0-.988-.358-1.892-.952-2.59L11 17.375l.006-.014A4 4 0 0 0 4 20H2a6 6 0 0 1 10.198-4.287A6 6 0 0 1 22.396 20h-2a4 4 0 0 0-7.006-2.639c.39.797.61 1.692.61 2.639Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20.397 9a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
    }
  )
);
const ForwardRef = forwardRef(SvgUsers);
export default ForwardRef;
