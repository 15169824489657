import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import { Box } from '../Box/Box.js';
import { typoRecipe } from './styles.css.vanilla.js';

const Typography = forwardRef(function Typography2({ variant, underline, ...props }, ref) {
  return /* @__PURE__ */ jsx(Box, { as: "span", ...mergeProps(props, { ref, className: typoRecipe({ variant, underline }) }) });
});
const Paragraph = forwardRef(function Paragraph2({ variant, underline, ...props }, ref) {
  return /* @__PURE__ */ jsx(Box, { as: "p", ...mergeProps(props, { ref, className: typoRecipe({ variant, underline }) }) });
});
const LinkTypography = forwardRef(function Link({ variant, underline = true, color = "secondary", ...props }, ref) {
  return /* @__PURE__ */ jsx(
    Box,
    {
      as: "span",
      ...mergeProps(
        { color, cursor: "pointer", ...props },
        { ref, className: typoRecipe({ variant, underline }) }
      )
    }
  );
});

export { LinkTypography, Paragraph, Typography };
