import { Checkbox } from 'reakit/Checkbox';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { getColor } from '../../theme/color.js';
import '../../theme/sprinkles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { styled } from '../../util/styled.js';
import { toggleButtonRecipe, buttonColor } from './styles.css.vanilla.js';

function getColorsFromProps({ color }) {
  return {
    [buttonColor]: getColor(color, "400")
  };
}
const ToggleButton = styled(
  Checkbox,
  ({ size, variant }) => toggleButtonRecipe({ size, variant }),
  ({ color = "green" }) => color ? assignInlineVars(getColorsFromProps({ color })) : {}
);
ToggleButton.displayName = "ToggleButton";

export { ToggleButton };
