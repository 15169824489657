import * as React from "react";
import { forwardRef } from "react";
const SvgEdit = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m9.989 3.654-7.543 7.543-.163 1.796a.667.667 0 0 0 .724.724l1.796-.163 7.543-7.543-2.357-2.357ZM12.817 5.54l1.415-1.414a.667.667 0 0 0 0-.943l-1.415-1.414a.667.667 0 0 0-.943 0L10.46 3.183l2.357 2.357Z" })
);
const ForwardRef = forwardRef(SvgEdit);
export default ForwardRef;
