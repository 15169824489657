import * as React from "react";
import { forwardRef } from "react";
const SvgMoveDiagonal = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M.929 8v7.071H8l-2.828-2.828 7.07-7.071L15.073 8V.929H8l2.829 2.828-7.072 7.072L.93 8Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgMoveDiagonal);
export default ForwardRef;
