import * as React from "react";
import { forwardRef } from "react";
const SvgEditFilter = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M20 8V6H4.023v2H20ZM7 11h10v2H7v-2Zm3 5h4v2h-4v-2Z", fill: "currentColor" })
);
const ForwardRef = forwardRef(SvgEditFilter);
export default ForwardRef;
