import * as React from "react";
import { forwardRef } from "react";
const SvgClearFilled = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 12C2 6.47 6.47 2 12 2s10 4.47 10 10-4.47 10-10 10S2 17.53 2 12Zm10-1.41L14.59 8 16 9.41 13.41 12 16 14.59 14.59 16 12 13.41 9.41 16 8 14.59 10.59 12 8 9.41 9.41 8 12 10.59Z"
    }
  )
);
const ForwardRef = forwardRef(SvgClearFilled);
export default ForwardRef;
