import * as React from "react";
import { forwardRef } from "react";
const SvgCommentReject = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.167 2.5h11.666c.92 0 1.667.748 1.667 1.667V12.5c0 .92-.747 1.667-1.667 1.667H7.5c-.36 0-.712.117-1 .333l-4 3V4.167c0-.92.748-1.667 1.667-1.667Zm2.778 10h8.888V4.167H4.167v10l1.778-1.334c.288-.216.64-.333 1-.333Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "m11.509 5.842 1.006 1.006-1.51 1.51 1.51 1.508-1.006 1.006L10 9.363l-1.509 1.509-1.006-1.006 1.51-1.509-1.51-1.509 1.006-1.006L10 7.352l1.509-1.51Z" })
);
const ForwardRef = forwardRef(SvgCommentReject);
export default ForwardRef;
