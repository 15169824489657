import * as React from "react";
import { forwardRef } from "react";
const SvgChevronDuoLeft = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m5.575 12 6.01-6.01L13 7.404 8.404 12 13 16.596l-1.414 1.414L5.576 12Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "m11 12 6.01-6.01 1.415 1.414L13.828 12l4.597 4.596-1.415 1.414L11 12Z" })
);
const ForwardRef = forwardRef(SvgChevronDuoLeft);
export default ForwardRef;
