import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonDrawingBrush = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 14 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M.945 10.415c1.626 1.432 3.89 1.255 5.2.583a2.257 2.257 0 0 0 .565-3.75 2.242 2.242 0 0 0-3.17.206c-.297.338-.474.74-.535 1.155-.262 1.262-.908 1.454-1.632 1.67-.14.042-.284.085-.428.136ZM12.809 2.124 8.47 7.976a2.991 2.991 0 0 0-2.706-2.383L10.99.523A1.21 1.21 0 0 1 12.639.48c.476.42.55 1.136.17 1.644Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonDrawingBrush);
export default ForwardRef;
