import { capitalize } from 'lodash';

import { MemberData } from '../../../../../types/memberTypes';

export const RoleOrder: Record<string, number> = {
  Owner: 1,
  Admin: 2,
  'System admin': 3,
  Manager: 4,
  Reviewer: 5,
  Labeler: 6,
  Worker: 6,
  Collaborator: 7,
  Unknown: 10,
};
type SortFunction = (a: MemberData, b: MemberData) => number;
export const getMembersSortFunction = (sortBy: string, sortDesc: boolean): SortFunction => {
  return (a: MemberData, b: MemberData) => {
    let res = 0;
    if (sortBy === 'tenantRole') {
      res = RoleOrder[a.tenantRole || 'Unknown'] - RoleOrder[b.tenantRole || 'Unknown'];
    } else if (sortBy === 'role') {
      const aRole = capitalize(a.projectRole) || a.tenantRole || 'Unknown';
      const bRole = capitalize(b.projectRole) || b.tenantRole || 'Unknown';
      res = RoleOrder[aRole] - RoleOrder[bRole];
    } else if (typeof a[sortBy] === 'string') {
      res = a[sortBy].localeCompare(b[sortBy]);
    } else {
      res = a[sortBy] - b[sortBy];
    }
    return res * (sortDesc ? -1 : 1);
  };
};

export function getIsOwner(member: MemberData): boolean {
  return capitalize(member.projectRole || member.tenantRole) === 'Owner';
}

export function getIsOwnerOrAdmin(member: MemberData): boolean {
  return RoleOrder[capitalize(member.projectRole || member.tenantRole)] <= 2;
}

export function getIsRoleOrderSameOrGreater(role1: string, role2?: string): boolean {
  if (!role2) return false;
  return RoleOrder[capitalize(role1)] >= RoleOrder[capitalize(role2)];
}

export function getIsRoleOrderSameOrLess(role1: string, role2?: string): boolean {
  if (!role2) return false;
  return RoleOrder[capitalize(role1)] <= RoleOrder[capitalize(role2)];
}

export function getIsSystemAdmin(role: string): boolean {
  return role?.toUpperCase().replace(' ', '_') === 'SYSTEM_ADMIN';
}

export function calculateNewLabelsCount(
  members: MemberData[],
  totalCount: number,
  method: 'equal' | 'proportional',
  type: 'labeler' | 'reviewer',
): void {
  const memberCount = members.length;

  function _equal() {
    const perMemberAdd = Math.floor(totalCount / memberCount);
    const roundingError = totalCount - perMemberAdd * memberCount;
    members.forEach((member, index) => {
      member.newLabelsCount = perMemberAdd + (index < roundingError ? 1 : 0);
    });
  }

  function _proportional() {
    const currentCountKey = type === 'labeler' ? 'inProgressCount' : 'unreviewedCount';
    // Only apply to members who don't already have more than enough assigned
    let appliedMembers = members.filter(m => (m[currentCountKey] ?? 0) < totalCount);
    if (appliedMembers.length === 0) appliedMembers = members;
    const adjustedMemberCount = appliedMembers.length;
    const totalCountBefore = appliedMembers.reduce((a, b) => a + (b[currentCountKey] || 0), 0);
    const totalCountAfter = totalCountBefore + totalCount;
    const perMemberTotal = Math.floor(totalCountAfter / adjustedMemberCount);
    const roundingError = totalCountAfter - perMemberTotal * adjustedMemberCount;
    members.forEach(member => {
      member.newLabelsCount = 0;
    });
    appliedMembers.forEach((member, index) => {
      member.newLabelsCount = Math.max(
        0,
        perMemberTotal + (index < roundingError ? 1 : 0) - (member[currentCountKey] || 0),
      );
    });
  }

  if (method === 'equal') {
    _equal();
  } else if (method === 'proportional') {
    _proportional();
  }
}
