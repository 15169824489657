import * as React from "react";
import { forwardRef } from "react";
const SvgCuboidTranslate = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M12 7.333H8.666V4h2L8 1.333 5.333 4h2v3.333H4v-2L1.333 8 4 10.667v-2h3.333V12h-2L8 14.667 10.666 12h-2V8.667H12v2L14.666 8 12 5.333v2Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCuboidTranslate);
export default ForwardRef;
