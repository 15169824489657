import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { differenceInMinutes } from 'date-fns';
import JwtDecode from 'jwt-decode';
import { NextRouter, useRouter } from 'next/router';

import { Region, useAppContext } from '../contexts/AppContext';
import { useAuthInfo } from '../contexts/AuthContext';
import { useRouteInfo } from '../contexts/RouteContext';
import { getUrl } from '../routes/util';
import { parseEpoch } from '../utils/date';
import { resetLoginTokens } from '../utils/LoginUtils';
import ServiceUtils from '../utils/ServiceUtils';
import { loginRefresh } from './NewAuthService';

export type ApiFetcherDataType = Record<string, any> | undefined;

export type ApiCallOrigin = 'sahara' | 'siesta' | 'curate';

export interface CommonArgs<DataType extends ApiFetcherDataType> {
  method: string;
  url: string;
  data?: DataType;
  errorHandling?: null | ((err: AxiosError) => void);
  version?: number;
  cancelToken?: CancelToken;
  isCurateUrl?: boolean;
  isModelUrl?: boolean;
  transformResponseToCamelCase?: boolean;
  transformRequestToSnakeCase?: boolean;
}

export interface ApiCallBeforeLoginArgs<DataType extends ApiFetcherDataType = Record<string, any>>
  extends CommonArgs<DataType> {
  accountName: string;
  router: NextRouter;
}

export interface ApiCallAfterLoginArgs<DataType extends ApiFetcherDataType = Record<string, any>>
  extends CommonArgs<DataType> {
  hasPublicApi: boolean;
  accountName: string;
  isGuest: boolean;
  region?: Region | '';
  router: NextRouter;
  origin?: ApiCallOrigin;
  config?: Partial<AxiosRequestConfig> | null;
  headers?: Record<string, string>;
}

type CreateDataType<T extends ApiFetcherDataType> = { data: T };

export type AfterLoginCallbackParam = Pick<
  ApiCallAfterLoginArgs,
  'accountName' | 'isGuest' | 'router' | 'origin'
>;
export type AfterLoginCallback<
  ResponseType extends Record<PropertyKey, any>,
  DataType extends ApiFetcherDataType = undefined,
> = (params: CreateAfterLoginParamTypeWithData<CreateDataType<DataType>>) => Promise<ResponseType>;

export type BeforeLoginCallbackParam = Pick<ApiCallBeforeLoginArgs, 'accountName' | 'router'>;
export type BeforeLoginCallback<
  ResponseType extends Record<PropertyKey, any>,
  DataType extends ApiFetcherDataType = undefined,
> = (params: CreateBeforeLoginParamTypeWithData<CreateDataType<DataType>>) => Promise<ResponseType>;

export type CreateAfterLoginParamTypeWithData<
  DataType extends ApiFetcherDataType = Record<string, any>,
> = DataType & AfterLoginCallbackParam;
export type CreateBeforeLoginParamTypeWithData<
  DataType extends ApiFetcherDataType = Record<string, any>,
> = DataType & BeforeLoginCallbackParam;

interface ReplacePageToAuthParams {
  router?: NextRouter;
  origin?: ApiCallOrigin;
}
export const replacePageToAuth = ({ router }: ReplacePageToAuthParams) => {
  const next = router ? router.asPath : window.location.href.replace(window.location.origin, '');
  const saharaLoginPageUrl = getUrl('/auth/login', {}, { next });

  if (router) {
    router.replace(saharaLoginPageUrl);
  } else {
    window.location.replace(saharaLoginPageUrl);
  }
};
export const replacePageToAccessAuthorization = ({ router }: ReplacePageToAuthParams) => {
  // const next = router ? router.asPath : window.location.href.replace(window.location.origin, '');
  const saharaAccessAuthorizationPageUrl = getUrl('/access_authorization', {});

  if (router) {
    router.replace(saharaAccessAuthorizationPageUrl);
  } else {
    window.location.replace(saharaAccessAuthorizationPageUrl);
  }
};

export const handleLoginRefresh = async ({
  accountName,
  router,
}: {
  accountName: string;
  router: NextRouter;
}): Promise<void> => {
  const idToken = localStorage.getItem('spb_user');
  if (!idToken) return;
  const decodedIdToken = JwtDecode<{ exp: number }>(idToken);
  const epoch = decodedIdToken?.exp || 0;
  const expiry = parseEpoch(epoch);
  if (differenceInMinutes(expiry, new Date()) <= 5) {
    await loginRefresh({ accountName, router, data: undefined });
  }
};

export const handleErrorAfterLogin = (
  err: AxiosError<any>,
  router?: NextRouter,
): Error | undefined => {
  if (!err.response) {
    return err;
  }

  const { status, data } = err.response;

  switch (status) {
    case 400:
      if (data?.statusCode === 400 && (data?.type === 'DQL_QUERY' || data?.type === 'QUERY_SYNTAX'))
        return data;

      if (err?.request?.responseURL?.includes('change-password')) {
        return data;
      }

      if (
        err.response.data &&
        (err.response.data.code === '003400' || err.response.data.code === '200400')
      ) {
        resetLoginTokens();
        replacePageToAuth({ router });
        // eslint-disable-next-line consistent-return
        return;
      }
      if (err.response.data.detail) {
        return new Error(`${err.response.data.detail}`);
      }
      return new Error('Bad request');
    case 401:
      resetLoginTokens();
      replacePageToAuth({ router });
      return new Error('Unauthorized');
    case 403:
      if (err.response.data.detail?.code === '403999') {
        replacePageToAccessAuthorization({ router });
        // eslint-disable-next-line consistent-return
        return new Error('Access Authorization Required');
      }
      switch (err.response.data.code) {
        case '403001': // You have reached the limit for number of user seats
        case '403002': // You have reached the limit for data storage
        case '403003': // You have reached the limit for Auto-Label
          return new Error('Exceeded limits');
        default:
          return new Error('Not Authorized');
      }
    case 404:
      return new Error('Not Found');
    case 428:
      return new Error('Precondition Required');
    case 409:
      return new Error('Duplicated');
    case 500:
      return new Error('Server Error');
    default:
      return new Error('Unknown Error');
  }
};

export const apiCallBeforeLogin = async <
  ResponseType extends Record<PropertyKey, any>,
  DataType extends ApiFetcherDataType = Record<string, any>,
>({
  method,
  url,
  data = {} as DataType,
  accountName,
  errorHandling = null,
  version,
}: ApiCallBeforeLoginArgs<DataType>): Promise<AxiosResponse<ResponseType>> => {
  try {
    return await axios<ResponseType>({
      method,
      url,
      data: ServiceUtils.toSnakeCaseKeys(data),
      ...ServiceUtils.getAuthAxiosConfig({ accountName, version }),
      ...ServiceUtils.getAxiosRetry(),
    });
  } catch (err: unknown) {
    if (errorHandling) {
      errorHandling(err as AxiosError);
    }
    throw err;
  }
};

export const apiCallAfterLogin = async <
  ResponseType extends Record<PropertyKey, any> = any,
  DataType extends ApiFetcherDataType = Record<string, any>,
>({
  method,
  url,
  data = {} as Record<PropertyKey, any>,
  hasPublicApi,
  isGuest,
  errorHandling = null,
  config = null,
  cancelToken,
  version,
  router,
  accountName,
  isCurateUrl, // Temp
  isModelUrl, // Temp
  transformResponseToCamelCase,
  transformRequestToSnakeCase = true,
  region,
  headers = {},
}: ApiCallAfterLoginArgs<DataType>): Promise<AxiosResponse<ResponseType>> => {
  if (!isGuest) {
    try {
      await handleLoginRefresh({ accountName, router });

      const axiosConfig =
        config ||
        ServiceUtils.getAxiosConfig({
          accountName,
          version,
          isCurateUrl,
          isModelUrl,
          transformResponseToCamelCase,
          region,
        });

      const additionalConfig: AxiosRequestConfig = {
        ...axiosConfig,
        headers: {
          ...axiosConfig.headers, // Existing headers
          ...headers, // Additional headers
        },
      };

      return await axios<ResponseType>({
        method,
        url,
        data: transformRequestToSnakeCase ? ServiceUtils.toSnakeCaseKeys(data) : data,
        cancelToken,
        ...additionalConfig,
        ...ServiceUtils.getAxiosRetry(),
      });
    } catch (err: any) {
      if (errorHandling) errorHandling(err);
      throw handleErrorAfterLogin(err, router) || err;
    }
  } else if (!hasPublicApi) {
    // TODO: i18n
    throw new Error('No public API.');
  } else {
    try {
      const axiosConfig = config || ServiceUtils.getGuestAxiosConfig({ accountName, version });

      return await axios<ResponseType>({
        method,
        url,
        data: transformRequestToSnakeCase ? ServiceUtils.toSnakeCaseKeys(data) : data,
        ...axiosConfig,
        ...ServiceUtils.getAxiosRetry(),
      });
    } catch (err: any) {
      // TODO: i18n
      if (err.response.status === 404) throw new Error('Page not found.');
      if (errorHandling) errorHandling(err);
      throw err;
    }
  }
};

export const useFetcherDefaultParams = () => {
  const { urlMatchInfo, params } = useRouteInfo();
  const { isGuest } = useAuthInfo();
  const { region } = useAppContext();
  const accountName = urlMatchInfo?.accountName ?? params?.accountName;
  const router = useRouter();

  return {
    afterLoginFetcherDefaultParams: {
      accountName,
      isGuest,
      router,
      region,
    },
    beforeLoginFetcherDefaultParams: {
      accountName,
      router,
    },
    signInPageFetcherDefaultParams: {
      router,
    },
  };
};

export const useFetcher = () => {
  const {
    afterLoginFetcherDefaultParams,
    beforeLoginFetcherDefaultParams,
    signInPageFetcherDefaultParams,
  } = useFetcherDefaultParams();

  const afterLoginFetcher =
    <
      CallbackType extends AfterLoginCallback<ResponseType, DataType>,
      ResponseType extends Record<PropertyKey, any> = Awaited<ReturnType<CallbackType>>,
      DataType extends ApiFetcherDataType = Parameters<CallbackType>[0]['data'],
    >(
      apiCallback: CallbackType,
    ) =>
    (...data: DataType extends undefined ? [undefined?] : [DataType]) =>
      apiCallback({
        ...afterLoginFetcherDefaultParams,
        ...(data && { data: data?.[0] as Record<PropertyKey, any> }),
      });

  const beforeLoginFetcher =
    <
      CallbackType extends BeforeLoginCallback<ResponseType, DataType>,
      ResponseType extends Record<PropertyKey, any> = Awaited<ReturnType<CallbackType>>,
      DataType extends ApiFetcherDataType = Parameters<CallbackType>[0]['data'],
    >(
      apiCallback: CallbackType,
    ) =>
    (...data: DataType extends undefined ? [undefined?] : [DataType]) => {
      return apiCallback({
        ...beforeLoginFetcherDefaultParams,
        ...(data && { data: data?.[0] as Record<PropertyKey, any> }),
      });
    };

  const signInPageFetcher =
    <
      CallbackType extends BeforeLoginCallback<ResponseType, DataType>,
      ResponseType extends Record<PropertyKey, any> = Awaited<ReturnType<CallbackType>>,
      DataType extends ApiFetcherDataType = Parameters<CallbackType>[0]['data'],
    >(
      apiCallback: CallbackType,
    ) =>
    (...data: DataType extends undefined ? [undefined?] : [DataType]) => {
      return apiCallback({
        accountName: data?.[0]?.accountName ?? data?.[0]?.tenantId ?? undefined,
        ...signInPageFetcherDefaultParams,
        ...(data && { data: data?.[0] as Record<PropertyKey, any> }),
      });
    };

  return { afterLoginFetcher, beforeLoginFetcher, signInPageFetcher };
};
