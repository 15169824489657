export const formatCurationType = (inputString: string) => {
  return inputString.toLowerCase().replace(/_/g, '-');
};

type AutoCurateCreateSliceJobResultParams = {
  result_slice_id?: string;
  val_slice_id?: string;
  train_slice_id?: string;
  mislabel_slice_id?: string;
  [key: string]: any; // Allows for additional properties that won't be used
};

export const retrieveSliceIdsFromParams = (
  params: AutoCurateCreateSliceJobResultParams,
): string[] => {
  const { result_slice_id, val_slice_id, train_slice_id, mislabel_slice_id } = params;

  const sliceIds: string[] = [];

  if (result_slice_id) sliceIds.push(result_slice_id);
  if (val_slice_id) sliceIds.push(val_slice_id);
  if (train_slice_id) sliceIds.push(train_slice_id);
  if (mislabel_slice_id) sliceIds.push(mislabel_slice_id);

  return sliceIds;
};
