import * as React from "react";
import { forwardRef } from "react";
const SvgAutoLabel = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.6 10.4 12 5l-1.6 5.4L6 12l4.4 1.6L12 19l1.6-5.4L18 12l-4.4-1.6Zm-.651-7.554c-.304-.912-1.594-.912-1.898 0L9 9l-4.855 2.08c-.808.347-.808 1.493 0 1.84L9 15l2.051 6.154c.304.912 1.594.912 1.898 0L15 15l4.855-2.08c.808-.347.808-1.493 0-1.84L15 9l-2.051-6.154Z"
    }
  )
);
const ForwardRef = forwardRef(SvgAutoLabel);
export default ForwardRef;
