import * as React from "react";
import { forwardRef } from "react";
const SvgVisibleOff = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.447 2.8 13.63 12.981h.002l-.849.849-2.014-2.014c-.754.351-1.668.583-2.768.583-4.555 0-5.924-3.97-5.937-4.01L2 8.199l.063-.19c.008-.025.557-1.619 2.11-2.79L2.6 3.648l.847-.847Zm6.405 8.1L8.774 9.82A1.772 1.772 0 0 1 8 10c-.985 0-1.8-.815-1.8-1.8 0-.276.064-.539.178-.774L5.022 6.07A5.125 5.125 0 0 0 3.27 8.2c.3.692 1.537 3 4.73 3 .71 0 1.324-.114 1.852-.3Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M6.5 4.156c.457-.1.956-.157 1.5-.157 4.555 0 5.924 3.97 5.937 4.01L14 8.2l-.063.19c-.006.02-.342.994-1.226 1.977l-.835-.835A5.159 5.159 0 0 0 12.73 8.2c-.3-.692-1.537-3-4.73-3-.151 0-.299.005-.441.015L6.5 4.156Z" })
);
const ForwardRef = forwardRef(SvgVisibleOff);
export default ForwardRef;
