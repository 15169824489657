import * as React from "react";
import { forwardRef } from "react";
const SvgImageCategory = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M22 6H10V4h12v2ZM22 13H10v-2h12v2ZM22 20H10v-2h12v2Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM4 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM4 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    }
  )
);
const ForwardRef = forwardRef(SvgImageCategory);
export default ForwardRef;
