import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonSubtract = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9 16a1 1 0 0 1-1-1V9.5H3.5v11h11V16H9ZM3 8a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5.5a1 1 0 0 0-1-1H9.5V9a1 1 0 0 0-1-1H3Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 14.077V15a1 1 0 0 0 1 1h.923v-1.5H9.5v-.423H8ZM9.923 2H9a1 1 0 0 0-1 1v.923h1.5V3.5h.423V2ZM8 12.923h1.5v-1.846H8v1.846Zm0-6h1.5V5.077H8v1.846ZM11.077 2v1.5h1.846V2h-1.846Zm3 0v1.5h1.846V2h-1.846Zm3 0v1.5h1.846V2h-1.846Zm3 0v1.5h.423v.423H22V3a1 1 0 0 0-1-1h-.923ZM22 5.077h-1.5v1.846H22V5.077Zm0 3h-1.5v1.846H22V8.077Zm0 3h-1.5v1.846H22v-1.846Zm0 3h-1.5v.423h-.423V16H21a1 1 0 0 0 1-1v-.923ZM18.923 16v-1.5h-1.846V16h1.846Zm-6 0v-1.5h-1.846V16h1.846Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonSubtract);
export default ForwardRef;
