import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import { typoRecipe } from '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import { extendComponent } from '../../util/extend-component.js';

const Label = extendComponent(Box, {
  as: "label",
  display: "flex",
  cursor: "pointer",
  flexDirection: ({ vertical }) => vertical ? "column" : "row",
  alignItems: ({ vertical }) => vertical ? "flex-start" : "center",
  columnGap: 0.5,
  rowGap: 1,
  className: ({ variant = "m-regular", underline = false }) => typoRecipe({ variant, underline })
});

export { Label };
