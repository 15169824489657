import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useState, useRef, useMemo, Children, cloneElement } from 'react';
import { usePopper } from 'react-popper';
import { useId } from '@react-aria/utils';
import '../../foundation/Box/Box.js';
import { Icon } from '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import setRef from '../../util/setRef.js';
import { tooltipRecipe, buttonIconStyle, arrowStyle } from './styles.css.vanilla.js';

const openDelay = 100;
const closeDelay = 100;
function Tooltip(props) {
  const {
    children,
    content,
    defaultOpen = false,
    arrow = true,
    boundary,
    placement = "auto",
    strategy = "absolute",
    offset = 0,
    onClick,
    buttonIcon,
    hideOnEmptyContent
  } = props;
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [open, setOpen] = useState(defaultOpen);
  const closeTimeout = useRef(null);
  const openTimeout = useRef(null);
  const id = useId();
  function handleOpen(open2) {
    setOpen(open2);
  }
  const modifiers = useMemo(() => {
    return [
      ...arrow ? [{ name: "arrow", options: { element: arrowElement, padding: 6 } }] : [],
      { name: "offset", options: { offset: [0, offset + 5] } },
      { name: "flip", options: { boundary: boundary ?? "clippingParents" } }
    ];
  }, [arrow, arrowElement, offset, boundary]);
  const options = useMemo(
    () => ({
      modifiers,
      placement,
      strategy
    }),
    [modifiers, placement, strategy]
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, options);
  function show() {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    if (openTimeout.current) {
      clearTimeout(openTimeout.current);
    }
    openTimeout.current = setTimeout(() => {
      handleOpen(true);
    }, openDelay);
  }
  function hide() {
    closeTimeout.current = setTimeout(() => {
      handleOpen(false);
    }, closeDelay);
  }
  function handleClick(event) {
    onClick?.(event);
  }
  function renderChildren() {
    return Children.map(
      children,
      (child) => cloneElement(child, {
        ...children.props,
        "aria-describedby": id,
        ref: (node) => {
          setReferenceElement(node);
          if (child.ref) {
            setRef(child.ref, node);
          }
        },
        onMouseEnter: (event) => {
          show();
          children.props.onMouseEnter?.(event);
        },
        onMouseLeave: (event) => {
          hide();
          children.props.onMouseLeave?.(event);
        }
      })
    );
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    renderChildren(),
    !(hideOnEmptyContent && !content) && open && /* @__PURE__ */ jsxs(
      "div",
      {
        ref: setPopperElement,
        style: styles.popper,
        ...attributes.popper,
        role: "tooltip",
        id,
        className: tooltipRecipe({ arrow, clickable: !!onClick }),
        onMouseEnter: show,
        onMouseLeave: hide,
        onClick: handleClick,
        children: [
          content,
          buttonIcon && /* @__PURE__ */ jsx("div", { className: buttonIconStyle, children: /* @__PURE__ */ jsx(Icon, { icon: buttonIcon }) }),
          arrow && /* @__PURE__ */ jsx("div", { ref: setArrowElement, style: styles.arrow, className: arrowStyle })
        ]
      }
    )
  ] });
}

export { Tooltip };
