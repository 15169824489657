import * as React from "react";
import { forwardRef } from "react";
const SvgFolderSetting = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M12.414 5H20c1.103 0 2 .897 2 2v2h-1.998L20 7H4v12h3.001L7 21H4c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2h6c.265 0 .52.105.707.293L12.414 5Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18.49 11.852a4.98 4.98 0 0 0-.506-.25l-.255-1.13a.614.614 0 0 0-.604-.472h-2.25a.614.614 0 0 0-.604.472l-.255 1.13a4.968 4.968 0 0 0-.949.531l-1.136-.35a.627.627 0 0 0-.723.271l-1.125 1.892a.587.587 0 0 0 .119.743l.88.78a4.67 4.67 0 0 0 0 1.063l-.88.779a.587.587 0 0 0-.12.743l1.126 1.892a.627.627 0 0 0 .723.271l1.136-.35a4.952 4.952 0 0 0 .949.531l.255 1.13a.615.615 0 0 0 .604.472h2.25c.291 0 .542-.196.604-.472l.255-1.13a4.963 4.963 0 0 0 .949-.531l1.136.35a.627.627 0 0 0 .723-.271l1.125-1.892a.588.588 0 0 0-.119-.743l-.881-.78a4.615 4.615 0 0 0 0-1.063l.881-.779c.215-.19.264-.5.12-.743l-1.126-1.892a.627.627 0 0 0-.723-.271l-1.136.35a4.926 4.926 0 0 0-.442-.281ZM16 18.4c1.366 0 2.473-1.074 2.473-2.4 0-1.325-1.107-2.4-2.473-2.4-1.366 0-2.473 1.075-2.473 2.4 0 1.326 1.107 2.4 2.473 2.4Z",
      fill: "inherit"
    }
  )
);
const ForwardRef = forwardRef(SvgFolderSetting);
export default ForwardRef;
