import * as React from "react";
import { forwardRef } from "react";
const SvgFlip = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m8 1.334-.667.667L14 5.334l.667-.667L8 1.334ZM8 14.667l-.667-.666L14 11.334l.667.667L8 14.667Z",
      fill: "#444"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2.667 4.37v7.387l4 1.143V3.14l-4 1.23Zm-1.334-.985v9.378L8 14.667V1.334L1.333 3.385Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6 8.667V10L3.333 8 6 6v1.333h3.333v1.334H6ZM14.667 4.667l-5.334.667v2h1.334V6l2.666 2-2.666 2V8.667H9.333v2.667l5.334.666V4.667Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgFlip);
export default ForwardRef;
