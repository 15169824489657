import * as React from "react";
import { forwardRef } from "react";
const SvgSliceAdd = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("mask", { id: "slice-add_svg__a", fill: "#fff" }, /* @__PURE__ */ React.createElement("path", { d: "M2 10a8 8 0 0 1 8-8v8H2Z" })),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M2 10a8 8 0 0 1 8-8v8H2Z",
      fill: "currentColor",
      stroke: "currentColor",
      strokeWidth: 4,
      mask: "url(#slice-add_svg__a)"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 3h-1v8H3v1a9 9 0 0 0 10 8.945V18.93A7.002 7.002 0 0 1 5.07 13H13V5.072A7.002 7.002 0 0 1 18.93 13h2.015A9 9 0 0 0 12 3Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18.375 14h-1.75v2.625H14v1.75h2.625V21h1.75v-2.625H21v-1.75h-2.625V14Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSliceAdd);
export default ForwardRef;
