import * as d3Format from 'd3-format';

// Format count with correct unit
export function formatCount(
  value: number,
  unit?: string,
  unitPlural = `${unit}${unit ? (unit[unit.length - 1] === 's' ? 'es' : 's') : ''}`,
): string {
  const formattedCount = Number(value).toLocaleString('en');
  if (unit) {
    return `${formattedCount} ${value === 1 ? unit : unitPlural}`;
  }
  return formattedCount;
}

export const formatNumber = d3Format.format(',');
