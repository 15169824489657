import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useRef, useState, useEffect, Fragment as Fragment$1 } from 'react';
import { Plus } from '@superb-ai/icons';
import { Chip } from '../../contrib/Chip/Chip.js';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import { Box } from '../../foundation/Box/Box.js';
import { Icon } from '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { Typography } from '../../foundation/Typography/Typography.js';
import { Dropdown } from '../Dropdown/Dropdown.js';
import { ComboboxInput } from '../Input/Input.js';
import { SelectList } from '../Select/SelectList.js';

function AsyncCombobox(props) {
  const {
    value,
    formatValue,
    multiple,
    data,
    searchInput,
    onChangeSearchInput,
    onBlurSearchInput,
    onCreate,
    createMessage,
    creatable = true,
    placeholder = "Please select or select"
  } = props;
  const { fixed, variant, color, size, suffix, ...listProps } = props;
  const inputRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const options = data ?? [];
  const matchedData = options.find(({ label }) => label === searchInput);
  const isCreatable = creatable && !listProps?.isLoading && !listProps?.loading && !matchedData;
  function createOption() {
    if (searchInput && isCreatable) {
      const newValue = searchInput;
      onCreate?.(newValue);
      if (multiple) {
        const nextValue = value.includes(newValue) ? value.filter((v) => v !== newValue) : [...value, newValue];
        props.onChangeValue?.(nextValue);
      } else {
        props.onChangeValue?.(newValue);
      }
    }
  }
  useEffect(() => {
    setSelectedOption?.(options.find(({ value: optionValue }) => optionValue === value));
  }, [data, value]);
  function onKeyDown(e) {
    e.stopPropagation();
    if (e.code === "Enter") {
      createOption();
    }
  }
  const inputProps = {
    color,
    size,
    ref: inputRef,
    placeholder,
    suffix,
    value: visible ? searchInput : multiple ? "" : value !== null ? `${formatValue?.(value) ?? selectedOption?.label ?? ""}` : "",
    onKeyDown,
    onChange: (e) => onChangeSearchInput?.(e.target.value),
    onBlur: onBlurSearchInput
  };
  function formatSelected(value2) {
    if (Array.isArray(value2)) {
      return value2.map((v) => /* @__PURE__ */ jsxs(Fragment$1, { children: [
        formatValue?.(v) ?? /* @__PURE__ */ jsx(Chip, { color: "gray", fill: "strong", children: data?.find((option) => v === option.value)?.label ?? v }),
        /* @__PURE__ */ jsx(ComboboxInput, { ...inputProps })
      ] }, `${v}`));
    }
    return /* @__PURE__ */ jsx(ComboboxInput, { ...inputProps });
  }
  const disclosure = /* @__PURE__ */ jsx(Fragment, { children: !value || multiple && value.length === 0 ? /* @__PURE__ */ jsx(ComboboxInput, { ...inputProps }) : formatSelected(value) });
  const onChangeValue = (newValue) => {
    props?.onChangeValue?.(newValue);
  };
  return /* @__PURE__ */ jsx(
    Dropdown,
    {
      initialVisible: false,
      disclosure,
      hideOnClick: !multiple,
      initialFocusRef: inputRef,
      onChangeVisible: setVisible,
      onBlur: () => {
        setVisible(false);
      },
      ...{ fixed, variant, color, size, disabled: props?.disabled },
      children: /* @__PURE__ */ jsx(
        SelectList,
        {
          ...listProps,
          onChangeValue,
          data: options,
          py: 0.5,
          maxHeight: props.maxHeight ?? 300,
          children: searchInput && isCreatable && /* @__PURE__ */ jsx(Box, { px: 1.5, style: { paddingTop: "6px", paddingBottom: "6px" }, children: /* @__PURE__ */ jsxs(Chip, { fill: "strong", children: [
            /* @__PURE__ */ jsx(Icon, { icon: Plus, size: "12" }),
            createMessage || "Create",
            " ",
            /* @__PURE__ */ jsx(Typography, { variant: "s-strong", children: searchInput })
          ] }) })
        }
      )
    }
  );
}

export { AsyncCombobox };
