import * as React from "react";
import { forwardRef } from "react";
const SvgError = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13 14V6h-2v8h2Zm0 4v-2h-2v2h2Z" })
);
const ForwardRef = forwardRef(SvgError);
export default ForwardRef;
