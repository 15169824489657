import * as React from "react";
import { forwardRef } from "react";
const SvgCurveIngest = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.485 11.036 16.9 12.45l-3.535 3.535L11.95 17.4l-1.414-1.415L7 12.45l1.414-1.414 2.536 2.535v-1.096C10.95 6.398 15.498 3 20 3h1v2h-1c-3.499 0-7.05 2.602-7.05 7.475v1.096l2.535-2.535ZM5 16H3v5h18v-5h-2v3H5v-3Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCurveIngest);
export default ForwardRef;
