import * as React from "react";
import { forwardRef } from "react";
const SvgRotate = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { clipRule: "evenodd", d: "M2.667 2.667H4V12h9.333v1.333H2.667V2.667Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M8.517 12A6 6 0 0 0 4 7.483V12h4.517Zm1.36 0c.082.439.123.885.123 1.333H2.667V6a7.333 7.333 0 0 1 7.21 6Z"
    }
  )
);
const ForwardRef = forwardRef(SvgRotate);
export default ForwardRef;
