import * as React from "react";
import { forwardRef } from "react";
const SvgChevronBigRight = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m7.05 19.472 1.414 1.403 8.486-8.417-8.486-8.416L7.05 5.445l7.071 7.013-7.07 7.014Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgChevronBigRight);
export default ForwardRef;
