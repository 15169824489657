import * as React from "react";
import { forwardRef } from "react";
const SvgConsensusSplit = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13 7V5h-2v2h2Zm1 2a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4ZM13 14v3h-2v-3H7a1 1 0 0 0-1 1v2H4v-2a3 3 0 0 1 3-3h4V9h2v3h4a3 3 0 0 1 3 3v2h-2v-2a1 1 0 0 0-1-1h-4ZM2 17l3 3 3-3H2ZM9 17l3 3 3-3H9ZM16 17l3 3 3-3h-6Z"
    }
  )
);
const ForwardRef = forwardRef(SvgConsensusSplit);
export default ForwardRef;
