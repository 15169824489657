import { forwardRef, createElement } from 'react';
import { mergeProps } from '@react-aria/utils';
import { evaluate } from './functional.js';

function styled(base, getClassName, getStyle, getAdditionalProps) {
  const StyledComponent = forwardRef(
    (props, ref) => {
      const { style, ...propsWithoutStyle } = props;
      const mergedProps = mergeProps(
        propsWithoutStyle,
        {
          className: evaluate(getClassName)(props),
          style: { ...style, ...evaluate(getStyle)(props) },
          ref
        },
        getAdditionalProps?.(props)
      );
      return createElement(base, mergedProps);
    }
  );
  const name = base.displayName ?? base.name ?? base;
  StyledComponent.displayName = `styled(${name})`;
  return StyledComponent;
}

export { styled };
