import * as React from "react";
import { forwardRef } from "react";
const SvgPolygon = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM18 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM4 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 16c-.35 0-.687.06-1 .17V7.83a2.995 2.995 0 0 0 2 0v8.34c-.313-.11-.65-.17-1-.17Zm3 3c0-.576-.162-1.114-.443-1.57l8.467-1.815a3.02 3.02 0 0 0 .42 1.955l-8.468 1.815C7.992 19.259 8 19.13 8 19Zm12-5.83v-2.34a2.997 2.997 0 0 1-2 0v2.34a2.994 2.994 0 0 1 2 0Zm-3.556-6.74L7.976 4.614a2.986 2.986 0 0 1-.419 1.956l8.467 1.814a3.02 3.02 0 0 1 .42-1.956Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18 16a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygon);
export default ForwardRef;
