import * as React from "react";
import { forwardRef } from "react";
const SvgUserAssign = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M22 11h-6.5v2H22v-2ZM12 19h2a6 6 0 0 0-12 0h2a4 4 0 0 1 8 0Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M17.01 7.99 13 12l4.01 4.01 1.415-1.414L15.828 12l2.597-2.596L17.01 7.99Z" })
);
const ForwardRef = forwardRef(SvgUserAssign);
export default ForwardRef;
