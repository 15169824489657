import * as React from "react";
import { forwardRef } from "react";
const SvgScatterToolbarSelect = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM5.836 2.134a1 1 0 0 1 1.094.062l14.439 10.666a1 1 0 0 1-.478 1.797l-7.325.863-4.41 5.913a1 1 0 0 1-1.796-.485L5.342 3.111a1 1 0 0 1 .494-.978Zm1.751 3.034 1.476 13.045 3.157-4.232a1 1 0 0 1 .684-.395l5.243-.617-10.56-7.801Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgScatterToolbarSelect);
export default ForwardRef;
