import * as React from "react";
import { forwardRef } from "react";
const SvgFolderOpen = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M2.165 19.551c.186.28.499.449.835.449h15c.4 0 .762-.238.919-.606l3-7A.998.998 0 0 0 21 11h-1V7c0-1.103-.897-2-2-2h-5.1l-2.284-1.787A.997.997 0 0 0 10 3H4c-1.103 0-2 .897-2 2v14h.007a1 1 0 0 0 .158.551ZM17.341 18H4.517l2.143-5h12.824l-2.143 5ZM18 7v4H6c-.4 0-.762.238-.919.606L4 14.129V7h14Z" })
);
const ForwardRef = forwardRef(SvgFolderOpen);
export default ForwardRef;
