import { PointcloudManifest } from '@superb-ai/siesta';
import axios, { AxiosError } from 'axios';
import * as path from 'path-browserify';

import { TargetTypeCivet } from '../components/pages/account/integrations/types';
import { getAdditionalHeaders, LegacyUrl, MRAPUrl } from '../shares/fetchUrlAsBlobOnMRAP';
import { FileWithPath } from '../utils/FileUtils';
import { concurrent } from '../utils/SpbUtils';
import AuthService from './AuthService';
import { ApiCall } from './types';

export type PointcloudSequenceUrl = {
  manifestUrl: string;
  frameUrls: { frameUrl: string; imageUrls: string[] }[];
};

export interface UrlInfo {
  url: string;
  dataKey: string;
  group?: string;
}
interface BaseFileInfo {
  fileName: string;
  fileSize: number;
}
export interface FileInfo extends BaseFileInfo {
  key: string;
  group: string;
}
interface SingleFileInfo extends BaseFileInfo {
  file: FileWithPath;
}
interface PresignedFileInfo {
  uploadUrl: string;
  file: FileWithPath;
}
interface PresignedFileInfoForMRAP {
  uploadUrl: LegacyUrl | MRAPUrl;
  file: FileWithPath;
}
const uploadWithUrl: ApiCall<{ url: UrlInfo }, any> = async args => {
  const { url, isGuest, urlInfo } = args;
  const imageUrlInfo = {
    url: url.url,
    key: url.dataKey,
    group: url.group,
  };

  if (imageUrlInfo.key.length > 255) {
    throw new Error('shorter data key required');
  }

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/assets/typed/img-url/',
    data: imageUrlInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

interface PresignedFileResult {
  error?: any;
}

type BatchFileCallback = (fileInfo: PresignedFileInfo, result: PresignedFileResult) => void;
type BatchFileCallbackForMRAP = (
  fileInfo: PresignedFileInfoForMRAP,
  result: PresignedFileResult,
) => void;

/**
 * This acts as a promise, uploading files concurrently,
 * resolving when all uploads are finished.
 * The point of this class is to be able to both await overall completion
 * as well as completion of individual files using onFileComplete.
 */
class PresignedFileBatch extends Promise<PresignedFileResult[]> {
  fileCallbacks: BatchFileCallback[] = [];
  maxParallelUploads = 10;

  constructor(files: PresignedFileInfo[], additionalHeaders?: Record<string, string>) {
    super((resolve, reject) => {
      setTimeout(() => {
        // nextTick because `this` isn't available yet
        const config = {
          headers: {
            'Content-Type': 'application/octet-stream',
            ...additionalHeaders,
          },
          transformRequest: [
            (data: any, headers: any) => {
              // eslint-disable-next-line no-param-reassign
              delete headers.Authorization;
              return data;
            },
          ],
        };
        concurrent(
          files,
          async fileInfo => {
            try {
              await axios.put(fileInfo.uploadUrl, fileInfo.file, config);
              this.fileCallbacks.forEach(callback => callback(fileInfo, {}));
              return {};
            } catch (error: any) {
              this.fileCallbacks.forEach(callback => callback(fileInfo, { error }));
              return { error };
            }
          },
          this.maxParallelUploads,
        )
          .then(resolve)
          .catch(reject);
      });
    });
  }
  static get [Symbol.species](): PromiseConstructor {
    return Promise;
  }
  // eslint-disable-next-line class-methods-use-this
  get [Symbol.toStringTag](): string {
    return 'PresignedFileBatch';
  }
  /**
   * Callback for every single file
   * @param callback
   */
  onFileComplete(callback: BatchFileCallback): this {
    this.fileCallbacks.push(callback);
    return this;
  }
}

class PresignedFileBatchForMRAP extends Promise<PresignedFileResult[]> {
  fileCallbacks: BatchFileCallbackForMRAP[] = [];
  maxParallelUploads = 10;

  constructor(files: PresignedFileInfoForMRAP[]) {
    super((resolve, reject) => {
      setTimeout(() => {
        // nextTick because `this` isn't available yet

        concurrent(
          files,
          async fileInfo => {
            const url = fileInfo.uploadUrl;

            if (typeof url === 'string') {
              const config = {
                headers: {
                  'Content-Type': 'application/octet-stream',
                },
                transformRequest: [
                  (data: any, headers: any) => {
                    // eslint-disable-next-line no-param-reassign
                    delete headers.Authorization;
                    return data;
                  },
                ],
              };
              try {
                await axios.put(url, fileInfo.file, config);
                this.fileCallbacks.forEach(callback => callback(fileInfo, {}));
                return {};
              } catch (error: any) {
                this.fileCallbacks.forEach(callback => callback(fileInfo, { error }));
                return { error };
              }
            } else {
              const config = {
                headers: {
                  'Content-Type': 'application/octet-stream',
                  ...getAdditionalHeaders(url.additionalHeaders || []),
                },
                transformRequest: [
                  (data: any, headers: any) => {
                    // eslint-disable-next-line no-param-reassign
                    delete headers.Authorization;
                    return data;
                  },
                ],
              };

              let useReplacement = false;
              try {
                await axios.put(url.url, fileInfo.file, config);
                this.fileCallbacks.forEach(callback => callback(fileInfo, {}));
                return {};
              } catch (error: any) {
                useReplacement = true;
                // if (axios.isAxiosError(error)) {
                //   if (error.response?.status === 404) {
                //     is404 = true;
                //   } else {
                //     this.fileCallbacks.forEach(callback => callback(fileInfo, { error }));
                //     return { error };
                //   }
                // }
                if (!url?.replacement || url?.replacement === 'NO_REPLACEMENT') {
                  return { error };
                }
              }

              if (useReplacement) {
                try {
                  await axios.put(url.replacement, fileInfo.file, config);
                  this.fileCallbacks.forEach(callback => callback(fileInfo, {}));
                  return {};
                } catch (error: any) {
                  this.fileCallbacks.forEach(callback => callback(fileInfo, { error }));
                  return { error };
                }
              }

              return {};
            }
          },
          this.maxParallelUploads,
        )
          .then(resolve)
          .catch(reject);
      });
    });
  }
  static get [Symbol.species](): PromiseConstructor {
    return Promise;
  }
  // eslint-disable-next-line class-methods-use-this
  get [Symbol.toStringTag](): string {
    return 'PresignedFileBatch';
  }
  /**
   * Callback for every single file
   * @param callback
   */
  onFileComplete(callback: BatchFileCallbackForMRAP): this {
    this.fileCallbacks.push(callback);
    return this;
  }
}

const uploadPresignedFiles = (files: PresignedFileInfo[]): PresignedFileBatch => {
  return new PresignedFileBatch(files);
};

const uploadPresignedJSON = async ({
  uploadUrl,
  jsonFile,
}: {
  uploadUrl: string;
  jsonFile: string;
}) => {
  const res = await fetch(
    new Request(uploadUrl, {
      method: 'PUT',
      body: jsonFile,
    }),
  );
  return res;
};

const uploadPresignedFilesForMRAP = (files: PresignedFileInfoForMRAP[]) => {
  return new PresignedFileBatchForMRAP(files);
};

const validateFileUpload = (
  file: File,
  fileInfo: BaseFileInfo & { key?: string },
  isFree: boolean,
): void => {
  if (fileInfo.key && fileInfo.key.length > 255) {
    throw new Error('Shorter data key required');
  }
  const fileName = fileInfo.key ? 'File' : `File ${file.name}`;
  if (isFree && file.size > 1048576) {
    throw new Error(`${fileName} exceeds the size limit of 1 MB`);
  }
  if (file.size > 20971520) {
    throw new Error(`${fileName} exceeds the size limit of 20 MB`);
  }
};

const validateAndBuildFrameInfosOfPointcloudSequence = async (fileInfos: SingleFileInfo[]) => {
  const manifestFileInfo = fileInfos.find(fileinfo => fileinfo.file.type === 'application/json');
  if (!manifestFileInfo || !manifestFileInfo.file.path) throw new Error('no manifest file');
  // TODO: validate manifest. if we throw an Error,
  // then it would be catched by uploadAndTrackPointcloudSequence, explaining what kind of error happens.
  const root = path.dirname(manifestFileInfo.file.path);
  const manifest = JSON.parse(await manifestFileInfo.file.text()) as PointcloudManifest;
  const key = manifest.key;
  const prefix = manifest.manifest.prefix;
  const frameInfos = [];
  const frameFileInfos = [];
  let totalCount = 0;
  let frameCount = 0;
  let imageCount = 0;
  for (const frameInfo of manifest.manifest.frames) {
    const framePath = frameInfo['frame_path'];
    const targetFramePath = path.join(root, prefix, framePath);
    const frameFileInfo = fileInfos.find(fileInfo => targetFramePath === fileInfo.file.path);
    if (!frameFileInfo) throw new Error('no pointcloud file');
    const frameFileSize = frameFileInfo.fileSize;
    const frameNumber = frameInfo['frame_number'];
    const frameFileInfoItem = {
      frameFileInfo,
      imageFileInfos: [] as SingleFileInfo[],
    };

    const imageInfos = [];
    for (const imageInfo of frameInfo['images']) {
      const imagePath = imageInfo['image_path'];
      const targetImagePath = path.join(root, prefix, imagePath);
      const imageFileInfo = fileInfos.find(fileInfo => targetImagePath === fileInfo.file.path);
      if (!imageFileInfo) throw new Error('no image file');
      const imageFileSize = imageFileInfo.fileSize;
      frameFileInfoItem.imageFileInfos.push(imageFileInfo);
      imageInfos.push({
        imageFileName: targetImagePath,
        imageFileSize: imageFileSize,
      });
      imageCount += 1;
      totalCount += 1;
    }

    frameInfos.push({
      frame_number: frameNumber,
      frame_file_name: framePath,
      frame_file_size: frameFileSize,
      image_count: imageCount,
      image_infos: imageInfos,
    });
    frameFileInfos.push(frameFileInfoItem);
    frameCount += 1;
    totalCount += 1;
  }

  return { key, manifestFileInfo, frameFileInfos, frameInfos, frameCount, totalCount };
};

/**
 * Uploads a single-file asset.
 * 1. Create asset 2. Get URL 3. Upload to URL
 * @param args
 */
const uploadFile: ApiCall<
  { fileInfo: FileInfo; file: File; isFree: boolean },
  any
> = async args => {
  const { fileInfo, file, isGuest, isFree, urlInfo } = args;
  validateFileUpload(file, fileInfo, isFree);
  const uploadErrorHandling = (err: AxiosError<any>) => {
    if (err.response?.status === 400 && err.response?.data.detail === 'File size limit exceeded') {
      if (isFree && file.size > 1048576) {
        throw new Error('File exceeds the size limit of 1 MB');
      }
      if (file.size > 20971520) {
        throw new Error('File exceeds the size limit of 20 MB');
      }
      throw new Error('File exceeds the size limit');
    }
  };
  const assetRes = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/assets/typed/img-presigned-url/',
    data: fileInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
    errorHandling: uploadErrorHandling,
  });

  const uploadInfoRes = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/assets/${assetRes.data.id}/upload-url/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
    // TODO: REMOVE BEFORE DEPLOY
    // MRAP: LOCAL_TEST local test를 위해 필요
    // baseUrl: 'http://gpu1.superb-infra.com:8000/',
  });

  const presignedUrlRes = (
    await uploadPresignedFilesForMRAP([
      {
        uploadUrl: uploadInfoRes.data.url,
        file,
      },
    ])
  )[0];
  return { id: assetRes.data.id, ...presignedUrlRes };
};

interface UploadFilesArgs {
  group: string;
  key: string;
  dataType: string;
  fileInfos: SingleFileInfo[];
  isFree: boolean;
}

/**
 * Uploads a multi-file asset.
 * 1. Create asset 2. Get URLs 3. Upload to URLs
 * @param args
 */
const uploadImageSequenceFiles: ApiCall<
  UploadFilesArgs,
  { id: string; batch: PresignedFileBatchForMRAP }
> = async args => {
  const { group, key, dataType, fileInfos, isFree, isGuest, urlInfo } = args;
  if (key.length > 255) {
    throw new Error('Shorter data key required');
  }
  fileInfos.forEach(fileInfo => {
    validateFileUpload(fileInfo.file, fileInfo, isFree);
  });
  const uploadErrorHandling = (err: AxiosError<any>) => {
    if (err.response?.status === 400 && err.response?.data.detail === 'File size limit exceeded') {
      throw new Error('One of the files exceeds the size limit');
    }
  };
  const data = {
    group,
    key,
    fileInfos: fileInfos.map(fileInfo => ({
      fileName: fileInfo.fileName,
      fileSize: fileInfo.fileSize,
    })),
  };
  const assetRes = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/assets/typed/${dataType}-presigned-url/`,
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
    errorHandling: uploadErrorHandling,
  });

  const uploadInfoRes = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/assets/${assetRes.data.id}/upload-url/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  const urls = uploadInfoRes.data.url as (LegacyUrl | MRAPUrl)[];
  const files = fileInfos.map((fileInfo, index) => ({
    uploadUrl: urls[index],
    file: fileInfo.file,
  }));
  return { id: assetRes.data.id, batch: uploadPresignedFilesForMRAP(files) };
};

const uploadPointcloudSequenceFiles: ApiCall<
  UploadFilesArgs,
  { id: string; batch: PresignedFileBatch }
> = async args => {
  const { group, dataType, fileInfos, isGuest, urlInfo } = args;
  // TODO: Validate fileInfo to meet basic condition
  const { key, manifestFileInfo, frameFileInfos, frameInfos, frameCount, totalCount } =
    await validateAndBuildFrameInfosOfPointcloudSequence(fileInfos);
  // use key from manifest, not folder
  if (key.length > 255) {
    throw new Error('Shorter data key required');
  }
  const uploadErrorHandling = (err: AxiosError<any>) => {
    if (err.response?.status === 400 && err.response?.data.detail === 'File size limit exceeded') {
      throw new Error('One of the files exceeds the size limit');
    }
  };
  const data = {
    group,
    key,
    manifestFileName: manifestFileInfo.file.name,
    manifestFileSize: manifestFileInfo.fileSize,
    sequenceNumber: 1,
    frameCount: frameCount,
    totalFileCount: totalCount,
    frameInfos: frameInfos,
  };
  const assetRes = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/assets/typed/${dataType}-presigned-url/`,
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
    errorHandling: uploadErrorHandling,
  });
  const assetId = assetRes.data.id;
  const uploadInfoRes = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/assets/${assetId}/upload-url/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  const url = uploadInfoRes.data.url as PointcloudSequenceUrl;
  const manifestUploadInfo = { uploadUrl: url.manifestUrl, file: manifestFileInfo.file };
  const frameUploadInfos = [] as { uploadUrl: string; file: FileWithPath }[];
  const imageUploadInfos = [] as { uploadUrl: string; file: FileWithPath }[];

  for (let i = 0; i < frameFileInfos.length; i++) {
    frameUploadInfos.push({
      uploadUrl: url.frameUrls[i].frameUrl,
      file: frameFileInfos[i].frameFileInfo.file,
    });
    for (let j = 0; j < frameFileInfos[i].imageFileInfos.length; j++) {
      imageUploadInfos.push({
        uploadUrl: url.frameUrls[i].imageUrls[j],
        file: frameFileInfos[i].imageFileInfos[j].file,
      });
    }
  }
  return {
    id: assetRes.data.id,
    batch: uploadPresignedFiles([manifestUploadInfo, ...frameUploadInfos, ...imageUploadInfos]),
  };
};

const registerUploadedAssetToLabel: ApiCall<
  { projectId: string; assetId: string },
  any
> = async args => {
  const { projectId, assetId, isGuest, urlInfo } = args;
  return AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/assets/${assetId}/labels/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
};

interface IntegrationArgs {
  integrationId: string;
  target: TargetTypeCivet;
}

type BucketArgs = IntegrationArgs & { prefix: string };
const getBucketObjects: ApiCall<BucketArgs, { folderName: string }[]> = async args => {
  const { integrationId, prefix, isGuest, urlInfo, target } = args;
  const data = {
    prefix,
  };

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/assets/integrations/${integrationId}/${target}/objects/`,
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

type BucketVerificationArgs = IntegrationArgs & { key: string };
const verifyKeyInBucket: ApiCall<BucketVerificationArgs, { key: string }[]> = async args => {
  const { integrationId, key, isGuest, urlInfo, target } = args;
  const data = {
    key,
  };
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/assets/integrations/${integrationId}/${target}/objects/`,
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

export default {
  uploadWithUrl,
  uploadFile,
  uploadPointcloudSequenceFiles,
  uploadImageSequenceFiles,
  registerUploadedAssetToLabel,
  uploadPresignedFiles,
  uploadPresignedJSON,
  getBucketObjects,
  verifyKeyInBucket,
};
