import * as React from "react";
import { forwardRef } from "react";
const SvgSlice = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("mask", { id: "slice_svg__a", fill: "#fff" }, /* @__PURE__ */ React.createElement("path", { d: "M2 10a8 8 0 0 1 8-8v8H2Z" })),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M2 10a8 8 0 0 1 8-8v8H2Z",
      fill: "currentColor",
      stroke: "currentColor",
      strokeWidth: 4,
      mask: "url(#slice_svg__a)"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 3h1a9 9 0 1 1-9 9v-1h8V3Zm2 2.07V13H5.07A7.002 7.002 0 0 0 19 12a7.002 7.002 0 0 0-6-6.93Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSlice);
export default ForwardRef;
