import * as React from "react";
import { forwardRef } from "react";
const SvgBrush = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M5.833 11.666a2.497 2.497 0 0 0-2.5 2.5c0 1.092-.966 1.667-1.666 1.667C2.433 16.85 3.742 17.5 5 17.5a3.332 3.332 0 0 0 3.333-3.334c0-1.383-1.116-2.5-2.5-2.5ZM17.842 4.45 15.55 2.158l-8.05 8.05L9.792 12.5l8.05-8.05Z" })
);
const ForwardRef = forwardRef(SvgBrush);
export default ForwardRef;
