import * as React from "react";
import { forwardRef } from "react";
const SvgKeyPointFlip = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M7.333 1.333h1.334v1.872H7.333V1.333ZM7.333 4.188h1.334V6.06H7.333V4.188ZM7.333 7.043h1.334v1.872H7.333V7.043ZM7.333 9.97h1.334v1.871H7.333V9.97ZM7.333 12.753h1.334v1.872H7.333v-1.872ZM5.381 3.526c.135-.315.619-.221.619.12v9.375a.317.317 0 0 1-.322.313H1.655a.313.313 0 0 1-.297-.433l4.023-9.375ZM10.6 12.733V5.085l3.337 7.648H10.6Z" })
);
const ForwardRef = forwardRef(SvgKeyPointFlip);
export default ForwardRef;
