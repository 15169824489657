import * as React from "react";
import { forwardRef } from "react";
const SvgChartVisualize = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 2H2v6h2V4h4V2Zm3 5h2v10h-2V7Zm6 3v7h-2v-7h2ZM7 12v5h2v-5H7Zm13 10h-4v-2h4v-4h2v6h-2Zm2-14V2h-6v2h4v4h2ZM2 20v-4h2v4h4v2H2v-2Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgChartVisualize);
export default ForwardRef;
