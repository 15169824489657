import * as React from "react";
import { forwardRef } from "react";
const SvgEraser = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m10.125 3 2.87 2.814c.451.45.451 1.168 0 1.618l-5.328 5.235a2.353 2.353 0 0 1-3.281 0l-2.047-2.011a1.134 1.134 0 0 1 0-1.618L8.484 3a1.176 1.176 0 0 1 1.64 0ZM3.157 9.847l2.052 2.01c.452.45 1.182.45 1.64 0l2.047-2.01-2.87-2.82-2.87 2.82Z" })
);
const ForwardRef = forwardRef(SvgEraser);
export default ForwardRef;
