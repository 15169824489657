import { init } from 'commandbar';

export const useCommandBar = !!process.env.NEXT_PUBLIC_COMMANDBAR_ID;

export function initExternals(): void {
  // CommandBar
  if (useCommandBar && process.env.NEXT_PUBLIC_COMMANDBAR_ID) {
    init(process.env.NEXT_PUBLIC_COMMANDBAR_ID);
  }
}
