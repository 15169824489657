import * as React from "react";
import { forwardRef } from "react";
const SvgCheckCircle = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm-2-5.83 6.59-6.59L18 9l-8 8-4-4 1.41-1.41L10 14.17Z"
    }
  )
);
const ForwardRef = forwardRef(SvgCheckCircle);
export default ForwardRef;
