import * as React from "react";
import { forwardRef } from "react";
const SvgGenerateImage = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.667 2H3c-.735 0-1.333.598-1.333 1.333v9.334C1.667 13.402 2.265 14 3 14h9.334c.735 0 1.333-.598 1.333-1.333V8h-1.333l.001 4.667H3V3.333h9.334-4.667V2ZM5.334 7.333a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 2-.667-.666-2 2.666h8L8.334 6.667l-2 2.666Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m11 2 .716 1.95 1.95.717-1.95.716L11 7.333l-.717-1.95-1.95-.716 1.95-.717L11 2Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 1.5a.5.5 0 0 1 .47.328l.636 1.733 1.733.636a.5.5 0 0 1 0 .939l-1.733.637-.637 1.733a.5.5 0 0 1-.938 0l-.637-1.733-1.733-.637a.5.5 0 0 1 0-.939l1.733-.636.636-1.733A.5.5 0 0 1 11 1.5Zm0 1.95-.248.673a.5.5 0 0 1-.296.296l-.673.248.673.247a.5.5 0 0 1 .296.297l.248.673.247-.673a.5.5 0 0 1 .297-.297l.673-.247-.673-.248a.5.5 0 0 1-.297-.296L11 3.45Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgGenerateImage);
export default ForwardRef;
