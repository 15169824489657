import * as React from "react";
import { forwardRef } from "react";
const SvgMove = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M16 9h-5V4h3l-4-4-4 4h3v5H4V6l-4 4 4 4v-3h5v5H6l4 4 4-4h-3v-5h5v3l4-4-4-4v3Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgMove);
export default ForwardRef;
