import * as React from "react";
import { forwardRef } from "react";
const SvgLogoSlack = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.8 0a2.4 2.4 0 1 0 0 4.799h2.4V2.4A2.402 2.402 0 0 0 8.8 0Zm0 6.4H2.4a2.4 2.4 0 0 0 0 4.8h6.4a2.4 2.4 0 1 0 0-4.8Z",
      fill: "#36C5F0"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M24 8.8a2.4 2.4 0 0 0-4.801 0v2.4h2.4A2.4 2.4 0 0 0 24 8.8Zm-6.4 0V2.4a2.4 2.4 0 0 0-4.8 0v6.4a2.4 2.4 0 1 0 4.8 0Z",
      fill: "#2EB67D"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.2 24a2.4 2.4 0 1 0 0-4.799h-2.401v2.4a2.401 2.401 0 0 0 2.4 2.4Zm0-6.4h6.4a2.4 2.4 0 0 0 0-4.8h-6.4a2.4 2.4 0 0 0 0 4.8Z",
      fill: "#ECB22E"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 15.2a2.4 2.4 0 0 0 4.8 0v-2.4H2.4A2.4 2.4 0 0 0 0 15.2Zm6.4 0v6.4a2.4 2.4 0 0 0 4.8 0v-6.399a2.4 2.4 0 0 0-4.8-.001Z",
      fill: "#E01E5A"
    }
  )
);
const ForwardRef = forwardRef(SvgLogoSlack);
export default ForwardRef;
