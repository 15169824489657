import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonUnion = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9.5 9.5h-6v11h11v-6h6v-11h-11v6ZM8 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-5v5a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5V3Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 8h1.5v1.333h-.167V9.5H8V8Zm0 6.5v.5a1 1 0 0 0 1 1h.5v-1.5H8Zm6.5 1.5H16v-1.5h-1.333v.167H14.5V16ZM16 9.5V9a1 1 0 0 0-1-1h-.5v1.5H16ZM10.667 8v1.5h2.666V8h-2.666ZM16 10.667h-1.5v2.666H16v-2.666ZM13.333 16v-1.5h-2.666V16h2.666ZM8 13.333h1.5v-2.666H8v2.666Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonUnion);
export default ForwardRef;
