import * as React from "react";
import { forwardRef } from "react";
const SvgRefresh = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M17.648 6.35A7.965 7.965 0 0 0 11.995 4 7.992 7.992 0 0 0 4 12c0 4.42 3.572 8 7.995 8 3.732 0 6.844-2.55 7.735-6h-2.082c-.82 2.33-3.041 4-5.653 4a6.007 6.007 0 0 1-6.004-6c0-3.31 2.692-6 6.004-6 1.661 0 3.142.69 4.223 1.78L12.996 11H20V4l-2.352 2.35Z" })
);
const ForwardRef = forwardRef(SvgRefresh);
export default ForwardRef;
