import * as React from "react";
import { forwardRef } from "react";
const SvgSubRight = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M4 5h2v8h10.17l-2.58-2.59L15 9l5 5-5 5-1.41-1.41L16.17 15H4V5Z" })
);
const ForwardRef = forwardRef(SvgSubRight);
export default ForwardRef;
