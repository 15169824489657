import { lowerCase, split, trimStart, upperFirst } from 'lodash';

import RegexUtils from './RegexUtils';

const trimAllOfString = (str: string): string => {
  return str.replace(/\s/g, '');
};

// TODO (tsnoh): 이름 바꾸고 싶다.
const trimBackRemainOneOfString = (str: string): string => {
  let idx = str.length - 1;

  let isFind = false;

  while (idx >= 0) {
    if (str[idx] === ' ') {
      if (!isFind) {
        isFind = true;
      }
      idx--;
    } else {
      break;
    }
  }

  if (isFind) {
    return str.slice(0, idx + 2);
  }
  return str;
};

const parseOnlyOneSpace = (str: string): string => {
  let result = '';

  let isFind = false;
  for (let i = 0; i < str.length; i++) {
    const curChar = str[i];

    if (curChar === ' ') {
      if (!isFind) {
        isFind = true;
      }
    } else if (isFind) isFind = false;
    result += curChar;
  }
  return result;
};

const splitValueWithParentheses = (value: string): { preValue: string; innerValue: string } => {
  const beforeAndAfterOpenParentheses = split(value, ' (');
  const beforeAndAfterCloseParentheses = split(beforeAndAfterOpenParentheses[1], ')');
  return {
    preValue: beforeAndAfterOpenParentheses[0],
    innerValue: beforeAndAfterCloseParentheses[0],
  };
};

const convertFirstLetterToUpperCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.substr(1, str.length);
};

const isWithinRangeLength = (str: string, min: number, max: number): boolean => {
  const { length } = str;
  return min <= length && length <= max;
};

const parseConstToFirstUpper = (str: string): string => {
  return str
    .split('_')
    .map(word => upperFirst(lowerCase(word)))
    .join(' ');
};

const parseNewProjectName = (str: string): string => {
  if (typeof str !== 'string') return '';
  const trimStr = trimStart(str);
  let result = '';
  for (let i = 0; i < trimStr.length; i++) {
    const curChar = trimStr[i];
    if (!RegexUtils.HAS_SPECIAL_SYMBOLS(curChar)) result += curChar;
  }

  return result;
};

const parsePartialMatchName = (
  str: string,
  partialStr: string,
): { value: string; isMatch: boolean }[] => {
  if (partialStr === '') return [{ value: str, isMatch: false }];

  const results = [];
  let remainStr = str;

  let a = 0;
  while (remainStr !== '') {
    const frontIdx = remainStr.indexOf(partialStr);
    const rearIdx = frontIdx + partialStr.length;

    if (frontIdx === -1) {
      results.push({ value: remainStr, isMatch: false });
      break;
    } else if (frontIdx === 0) {
      results.push({ value: remainStr.slice(0, rearIdx), isMatch: true });
      if (rearIdx !== remainStr.length) {
        remainStr = remainStr.slice(rearIdx);
      } else {
        break;
      }
    } else {
      results.push({ value: remainStr.slice(0, frontIdx), isMatch: false });
      results.push({
        value: remainStr.slice(frontIdx, rearIdx),
        isMatch: true,
      });
      if (rearIdx !== remainStr.length) {
        remainStr = remainStr.slice(rearIdx);
      } else {
        break;
      }
    }

    a++;
    if (a > 30) break;
  }

  return results;
};

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e: any) {
    return false;
  }
  return true;
};

const snakeCase = (str: string): string => {
  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
};

function formatList(items: string[]): string {
  if (items.length === 0) return '';
  const last = items.pop();
  if (items.length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return last!;
  }
  return `${items.join(', ')} and ${last}`;
}

export default {
  trimAllOfString,
  trimBackRemainOneOfString,
  parseOnlyOneSpace,
  splitValueWithParentheses,
  convertFirstLetterToUpperCase,
  isWithinRangeLength,
  parseConstToFirstUpper,
  parseNewProjectName,
  parsePartialMatchName,
  capitalize,
  isJson,
  snakeCase,
  formatList,
};
