import * as React from "react";
import { forwardRef } from "react";
const SvgCopyAll = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20 4H8v12h12V4ZM8 2a2 2 0 0 0-2 2v2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8Zm8 16H8a2 2 0 0 1-2-2V8H4v12h12v-2Zm-5.199-6.435L10.321 13H9.196l2.048-5.818h1.302L14.596 13h-1.124l-.483-1.435H10.8Zm1.071-3.19-.787 2.344h1.62l-.787-2.344h-.046Zm3.446-1.193h1.028V13h-1.028V7.182Zm3.114 0h-1.029V13h1.029V7.182Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCopyAll);
export default ForwardRef;
