import * as React from "react";
import { forwardRef } from "react";
const SvgLink = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M10 15v2H7A5 5 0 0 1 7 7h3v2H7a3 3 0 1 0 0 6h3ZM14 9V7h3a5 5 0 0 1 0 10h-3v-2h3a3 3 0 1 0 0-6h-3Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M17 11H7v2h10v-2Z" })
);
const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
