var Primary = {
	"100": {
		value: "#fff8f8",
		type: "color"
	},
	"200": {
		value: "#ffe7e7",
		type: "color"
	},
	"300": {
		value: "#ffd7d5",
		type: "color"
	},
	"400": {
		value: "#ff625a",
		type: "color"
	},
	"500": {
		value: "#ef4d45",
		type: "color"
	}
};
var Secondary = {
	"100": {
		value: "#f8f8ff",
		type: "color"
	},
	"200": {
		value: "#e7e8ff",
		type: "color"
	},
	"300": {
		value: "#d5dbff",
		type: "color"
	},
	"400": {
		value: "#5a7bff",
		type: "color"
	},
	"500": {
		value: "#3a60fa",
		type: "color"
	}
};
var Gray = {
	"100": {
		value: "#f5f5f5",
		type: "color"
	},
	"150": {
		value: "#e8e8e8",
		type: "color"
	},
	"200": {
		value: "#dcdcdc",
		type: "color"
	},
	"250": {
		value: "#d4d4d4",
		type: "color"
	},
	"300": {
		value: "#b3b3b3",
		type: "color"
	},
	"400": {
		value: "#333333",
		type: "color"
	},
	"500": {
		value: "#1f1f1f",
		type: "color"
	}
};
var White = {
	"400": {
		value: "#ffffff",
		type: "color"
	}
};
var Black = {
	"400": {
		value: "#000000",
		type: "color"
	}
};
var Red = {
	"100": {
		value: "#ffd2d2",
		type: "color"
	},
	"400": {
		value: "#ff3a28",
		type: "color"
	},
	"500": {
		value: "#b42a1e",
		type: "color"
	}
};
var Orange = {
	"100": {
		value: "#ffe0c3",
		type: "color"
	},
	"400": {
		value: "#ff792e",
		type: "color"
	},
	"500": {
		value: "#bb5821",
		type: "color"
	}
};
var Yellow = {
	"100": {
		value: "#fff0b1",
		type: "color"
	},
	"400": {
		value: "#FFB600",
		type: "color"
	},
	"500": {
		value: "#9f8526",
		type: "color"
	}
};
var Lime = {
	"100": {
		value: "#faffbe",
		type: "color"
	},
	"400": {
		value: "#d3f354",
		type: "color"
	},
	"500": {
		value: "#88a123",
		type: "color"
	}
};
var Green = {
	"100": {
		value: "#e6ffdd",
		type: "color"
	},
	"400": {
		value: "#82db24",
		type: "color"
	},
	"500": {
		value: "#547d46",
		type: "color"
	}
};
var Mint = {
	"100": {
		value: "#cbfff1",
		type: "color"
	},
	"400": {
		value: "#4ae2b9",
		type: "color"
	},
	"500": {
		value: "#3aa7a7",
		type: "color"
	}
};
var Skyblue = {
	"100": {
		value: "#cff1ff",
		type: "color"
	},
	"400": {
		value: "#4ecaff",
		type: "color"
	},
	"500": {
		value: "#357f9e",
		type: "color"
	}
};
var Blue = {
	"100": {
		value: "#dbe7ff",
		type: "color"
	},
	"400": {
		value: "#3479ff",
		type: "color"
	},
	"500": {
		value: "#385588",
		type: "color"
	}
};
var Violet = {
	"100": {
		value: "#dfd8fb",
		type: "color"
	},
	"400": {
		value: "#702dff",
		type: "color"
	},
	"500": {
		value: "#312094",
		type: "color"
	}
};
var Purple = {
	"100": {
		value: "#eecaff",
		type: "color"
	},
	"400": {
		value: "#bf36ff",
		type: "color"
	},
	"500": {
		value: "#6d438d",
		type: "color"
	}
};
var Pink = {
	"100": {
		value: "#ffd6f1",
		type: "color"
	},
	"400": {
		value: "#ff4881",
		type: "color"
	},
	"500": {
		value: "#9c2c52",
		type: "color"
	}
};
var Cloud = {
	"100": {
		value: "#f4f4f4",
		type: "color"
	},
	"400": {
		value: "#a6a6a6",
		type: "color"
	},
	"500": {
		value: "#4e4e4e",
		type: "color"
	}
};
var Overlays = {
	black: {
		value: "#00000099",
		type: "color"
	},
	white: {
		value: "#ffffff99",
		type: "color"
	}
};
var tokens = {
	Primary: Primary,
	"Primary-opacity": {
	"100": {
		value: "#ff0c0008",
		type: "color"
	},
	"200": {
		value: "#ff0c0017",
		type: "color"
	},
	"300": {
		value: "#ff0c0029",
		type: "color"
	},
	"400": {
		value: "#ff0c00a6",
		type: "color"
	},
	"500": {
		value: "#ff0c00bd",
		type: "color"
	}
},
	Secondary: Secondary,
	"Secondary-opacity": {
	"100": {
		value: "#0033ff08",
		type: "color"
	},
	"200": {
		value: "#0033ff17",
		type: "color"
	},
	"300": {
		value: "#0033ff2b",
		type: "color"
	},
	"400": {
		value: "#0033ffa6",
		type: "color"
	},
	"500": {
		value: "#0033ffc7",
		type: "color"
	}
},
	Gray: Gray,
	"Gray-Opacity": {
	"100": {
		value: "#0000000a",
		type: "color"
	},
	"150": {
		value: "#00000017",
		type: "color"
	},
	"200": {
		value: "#00000024",
		type: "color"
	},
	"250": {
		value: "#0000002b",
		type: "color"
	},
	"300": {
		value: "#0000004d",
		type: "color"
	},
	"400": {
		value: "#000000cc",
		type: "color"
	},
	"500": {
		value: "#000000e0",
		type: "color"
	}
},
	White: White,
	Black: Black,
	Red: Red,
	Orange: Orange,
	Yellow: Yellow,
	Lime: Lime,
	Green: Green,
	Mint: Mint,
	Skyblue: Skyblue,
	Blue: Blue,
	Violet: Violet,
	Purple: Purple,
	Pink: Pink,
	Cloud: Cloud,
	Overlays: Overlays
};

export { Black, Blue, Cloud, Gray, Green, Lime, Mint, Orange, Overlays, Pink, Primary, Purple, Red, Secondary, Skyblue, Violet, White, Yellow, tokens as default };
