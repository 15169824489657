import * as React from "react";
import { forwardRef } from "react";
const SvgCircleUp = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M8.667 7.22v4.113H7.334V7.22L5.606 8.94 4.667 8 8 4.667 11.334 8l-.94.94-1.727-1.72Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 14.667A6.667 6.667 0 1 1 8 1.333a6.667 6.667 0 0 1 0 13.334Zm0-1.334A5.333 5.333 0 1 1 8 2.667a5.333 5.333 0 0 1 0 10.666Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCircleUp);
export default ForwardRef;
