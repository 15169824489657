import * as React from "react";
import { forwardRef } from "react";
const SvgTag = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M10.069 10.069a2 2 0 1 0-2.83-2.829 2 2 0 0 0 2.83 2.829Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.648 13.548a2 2 0 0 1-.58-1.568l.505-6.566a2 2 0 0 1 1.84-1.84l6.567-.506a2 2 0 0 1 1.567.58l7.835 7.835a2 2 0 0 1 0 2.829l-7.07 7.07a2 2 0 0 1-2.83 0l-7.834-7.834Zm8.485-8.486 7.835 7.835-7.07 7.071-7.836-7.835.505-6.565 6.566-.506Z"
    }
  )
);
const ForwardRef = forwardRef(SvgTag);
export default ForwardRef;
