import * as React from "react";
import { forwardRef } from "react";
const SvgFill = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m8.543 5.836-5.657 5.656a2 2 0 0 0 0 2.829l6.364 6.364a2 2 0 0 0 2.828 0l7.071-7.071L7.5 2 6.086 3.414l2.457 2.422ZM9.957 7.25l-5.65 5.65h11.3l-5.65-5.65ZM20 21.333a1.75 1.75 0 0 0 1.737-1.96C21.75 17.833 20 15.5 20 15.5s-1.738 2.333-1.738 3.873A1.75 1.75 0 0 0 20 21.333Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgFill);
export default ForwardRef;
