import * as React from "react";
import { forwardRef } from "react";
const SvgSnapReleaseAll = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M6.838 7.336 6.01 2.85l5.324 3.729-4.497.757Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.35 4a6.63 6.63 0 0 0-5.52 2.942l-.66-1.117c.223-.33-.531-.646-.266-.942a8.65 8.65 0 1 1 12.27 12.162l-1.347-1.478A6.65 6.65 0 0 0 13.35 4Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M11 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9 18.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM9 20a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSnapReleaseAll);
export default ForwardRef;
