import * as React from "react";
import { forwardRef } from "react";
const SvgEmail = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm18 1.869V18H4V7.869l8 5.333 8-5.333ZM19.197 6H4.803L12 10.798 19.197 6Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgEmail);
export default ForwardRef;
