import * as React from "react";
import { forwardRef } from "react";
const SvgShow = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M12 5c-7.592 0-9.874 6.617-9.895 6.684L2 12l.104.316C2.126 12.383 4.408 19 12 19c7.592 0 9.874-6.617 9.895-6.684L22 12l-.104-.316C21.874 11.617 19.592 5 12 5Zm0 12c-5.322 0-7.384-3.846-7.883-5 .5-1.158 2.564-5 7.883-5 5.322 0 7.384 3.846 7.883 5-.5 1.158-2.564 5-7.883 5Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgShow);
export default ForwardRef;
