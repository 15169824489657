import * as React from "react";
import { forwardRef } from "react";
const SvgChevronExpand = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M17 12V7h-5V5h7l-.008 7H17ZM7 12v5h5v2H5l.008-7H7Z" })
);
const ForwardRef = forwardRef(SvgChevronExpand);
export default ForwardRef;
