import * as React from "react";
import { forwardRef } from "react";
const SvgExport = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m8 7 4-5 4 5h-3v9h-2V7H8Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M21 19v-8c0-1.103-.897-2-2-2h-4v2h4v8H5v-8h4V9H5c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2Z" })
);
const ForwardRef = forwardRef(SvgExport);
export default ForwardRef;
