import { jsxs, jsx } from 'react/jsx-runtime';
import { useRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';
import { getColor, getOpacityColor } from '../../theme/color.js';
import '../../theme/sprinkles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { useRange, RangeInput } from './Range.js';
import { selectedColor, selectedOpacityColor, minPercent, maxPercent, wrap, dualWrap, dualInputs, from, to } from './styles.css.vanilla.js';

function DualRange({
  onValueChange,
  onValueInput,
  color = "primary",
  ...props
}) {
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const { values, min = 0, max = 1 } = props;
  const { onChange: onFromChange, onMouseUp: onFromMouseUp } = useRange({
    value: values[0],
    min,
    max: values[1],
    onValueChange: (value) => onValueChange && onValueChange([value, values[1]]),
    onValueInput: (value) => onValueInput && onValueInput([value, values[1]])
  });
  const { onChange: onToChange, onMouseUp: onToMouseUp } = useRange({
    value: values[0],
    min: values[0],
    max,
    onValueChange: (value) => onValueChange && onValueChange([values[0], value]),
    onValueInput: (value) => onValueInput && onValueInput([values[0], value])
  });
  const minPercentValue = (values[0] - min) / (max - min) * 100;
  const maxPercentValue = (values[1] - min) / (max - min) * 100;
  const varStyle = assignInlineVars({
    [selectedColor]: getColor(color),
    [selectedOpacityColor]: getColor(getOpacityColor(color, "primary"), "300"),
    [minPercent]: `${minPercentValue}%`,
    [maxPercent]: `${maxPercentValue}%`
  });
  return /* @__PURE__ */ jsxs("div", { className: cn(wrap, dualWrap), children: [
    /* @__PURE__ */ jsx(
      RangeInput,
      {
        ...mergeProps(props, {
          ref: fromRef,
          className: cn(dualInputs, from),
          onChange: onFromChange,
          onMouseUp: onFromMouseUp,
          value: values[0],
          style: varStyle
        })
      }
    ),
    /* @__PURE__ */ jsx(
      RangeInput,
      {
        ...mergeProps(props, {
          ref: toRef,
          className: cn(dualInputs, to),
          onChange: onToChange,
          onMouseUp: onToMouseUp,
          value: values[1],
          style: varStyle
        })
      }
    )
  ] });
}

export { DualRange };
