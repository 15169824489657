import * as React from "react";
import { forwardRef } from "react";
const SvgUnavailable = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 13.333A5.333 5.333 0 1 0 8 2.666a5.333 5.333 0 0 0 0 10.667Zm0 1.333A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.333Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "m3.286 11.771 8.485-8.485.942.942-8.485 8.486z" })
);
const ForwardRef = forwardRef(SvgUnavailable);
export default ForwardRef;
