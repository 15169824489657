import * as React from "react";
import { forwardRef } from "react";
const SvgCurateLogoInverted = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M20.28 12.727a7.55 7.55 0 1 0-7.551 7.551v-7.55h7.55Z", fill: "#85AFFF" }),
  /* @__PURE__ */ React.createElement("rect", { x: 14, y: 14, width: 7, height: 7, rx: 1, fill: "#fff" }),
  /* @__PURE__ */ React.createElement("circle", { cx: 4, cy: 4, r: 2, fill: "#85AFFF" })
);
const ForwardRef = forwardRef(SvgCurateLogoInverted);
export default ForwardRef;
