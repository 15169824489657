import * as React from "react";
import { forwardRef } from "react";
const SvgKeyFrame = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M2.41 12.992a1.403 1.403 0 0 1 0-1.984l8.598-8.597a1.403 1.403 0 0 1 1.984 0l8.597 8.597a1.403 1.403 0 0 1 0 1.984l-8.597 8.597a1.403 1.403 0 0 1-1.984 0l-8.597-8.597Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m12.992 21.59 8.597-8.598a1.403 1.403 0 0 0 0-1.984l-8.597-8.597A1.399 1.399 0 0 0 12 2v20c.359 0 .718-.137.992-.41Z",
      fill: "#B3B3B3"
    }
  )
);
const ForwardRef = forwardRef(SvgKeyFrame);
export default ForwardRef;
