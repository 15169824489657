import { jsx } from 'react/jsx-runtime';
import { forwardRef, useRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import { useContainerSize } from '../../hooks/container-size.js';
import { baseStyle, animateStyle } from './styles.css.vanilla.js';

const Collapse = forwardRef(function Collapse2({ children, open = false, animate = true, ...props }, ref) {
  const detailRef = useRef(null);
  const { height: detailHeight } = useContainerSize(detailRef);
  return /* @__PURE__ */ jsx(
    Box,
    {
      ...mergeProps(props, {
        ref,
        className: [
          baseStyle,
          ...animate ? [animateStyle] : [],
          ...props?.className ? [props.className] : []
        ].join(" ")
      }),
      style: {
        height: open ? detailHeight !== -1 ? detailHeight ?? void 0 : void 0 : 0,
        ...props?.style
      },
      children: /* @__PURE__ */ jsx(Box, { ref: detailRef, children })
    }
  );
});

export { Collapse };
