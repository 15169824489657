import * as React from "react";
import { forwardRef } from "react";
const SvgAutoEdit = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.525 4.557c.187-.207.466-.33.975-.557-.509-.226-.788-.35-.975-.557-.227-.25-.32-.622-.525-1.443-.205.821-.298 1.193-.525 1.443-.187.207-.466.33-.975.557.509.226.788.35.975.557.227.25.32.622.525 1.443.205-.821.298-1.193.525-1.443ZM4.892 9.205c.312-.35.775-.566 1.608-.955-.833-.389-1.296-.605-1.608-.955-.39-.438-.545-1.087-.892-2.545-.347 1.458-.502 2.107-.892 2.545-.312.35-.775.566-1.608.955.833.389 1.296.605 1.608.955.39.438.545 1.086.892 2.545.347-1.459.502-2.107.892-2.545ZM18.5 18.5c-.579.276-.903.43-1.12.677-.278.313-.386.775-.63 1.823-.244-1.048-.352-1.51-.63-1.823-.217-.247-.541-.401-1.12-.677.579-.276.903-.43 1.12-.677.278-.313.386-.775.63-1.823.244 1.048.352 1.51.63 1.823.217.247.541.401 1.12.677ZM5.282 19.509a1.677 1.677 0 0 0 2.37 0L19.51 7.653a1.677 1.677 0 0 0 0-2.371l-.79-.79a1.677 1.677 0 0 0-2.372 0L4.491 16.346a1.677 1.677 0 0 0 0 2.371l.79.79Zm8.694-10.275a1.673 1.673 0 0 0-.463-.328l3.625-3.624a.559.559 0 0 1 .79 0l.79.79a.559.559 0 0 1 0 .79l-3.624 3.625a1.672 1.672 0 0 0-.328-.463l-.79-.79Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgAutoEdit);
export default ForwardRef;
