import * as React from "react";
import { forwardRef } from "react";
const SvgScatterDots = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm3 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm6-7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM11 7.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm3 9a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm2.5-3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgScatterDots);
export default ForwardRef;
