import * as React from "react";
import { forwardRef } from "react";
const SvgImageObject = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M6.667 9.333 6 8.666l-2 2.667h8L8.667 6.666l-2 2.667ZM5.667 7.333a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2.667 3.333a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0Zm.666-2A2 2 0 1 0 5.22 4h5.56c.202.568.652 1.019 1.22 1.22v5.56c-.568.201-1.019.651-1.22 1.22H5.22A2.006 2.006 0 0 0 4 10.78V5.22a1.997 1.997 0 0 1-1.333 0v5.56a2 2 0 1 0 2.553 2.553h5.56a2 2 0 1 0 2.553-2.553V5.22a2 2 0 1 0-2.553-2.554H5.22a2 2 0 0 0-1.887-1.333Zm-.666 9.447.025-.009a2.088 2.088 0 0 0-.025.009ZM5.22 12l.008.025A2.072 2.072 0 0 0 5.22 12Zm6.805-6.772a2.121 2.121 0 0 1-.024-.008l.024.008Zm-9.358 7.438a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0Zm2.666-9.333ZM12.667 4a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334Zm0 9.333a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgImageObject);
export default ForwardRef;
