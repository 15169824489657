function makePrintIfChanged() {
  let prev = null;
  return function printIfChanged(value, content) {
    if (value !== prev) {
      prev = value;
      return content ?? value;
    }
    return null;
  };
}

export { makePrintIfChanged };
