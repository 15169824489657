import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useEffect, cloneElement } from 'react';
import { usePopoverState, PopoverDisclosure, Popover as Popover$1 } from 'reakit/Popover';
import { useContainerSize } from '../../hooks/container-size.js';
import { scale } from '../../theme/scale.js';

function Popover(props) {
  const {
    children,
    disclosure,
    open = false,
    placement = "bottom-start",
    fixed = false,
    sameWidth = false,
    hideOnClick = false,
    offset = 0,
    onToggle,
    onOpen,
    onClose,
    ...otherProps
  } = props;
  const popover = usePopoverState({
    visible: open,
    placement,
    gutter: scale(offset),
    unstable_fixed: fixed
  });
  const { width } = useContainerSize(popover.unstable_referenceRef);
  useEffect(() => {
    onToggle?.(popover.visible);
    if (popover.visible) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [popover.visible, onOpen, onClose, onToggle]);
  function handleOnClick(event) {
    otherProps.onClick?.(event);
    if (hideOnClick) {
      popover.hide();
    }
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    disclosure && /* @__PURE__ */ jsx(PopoverDisclosure, { ...popover, ...disclosure.props, children: (disclosureProps) => cloneElement(disclosure, disclosureProps) }),
    /* @__PURE__ */ jsx(
      Popover$1,
      {
        ...popover,
        ...otherProps,
        style: { ...otherProps.style, minWidth: sameWidth && width ? width : void 0 },
        onClick: handleOnClick,
        children: popover.visible && children
      }
    )
  ] });
}

export { Popover };
