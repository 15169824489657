import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';

var backgroundStyle = "_1rvc8sn1 _1cuzvx513y _1cuzvx5w _1cuzvx59n";
var closeButtonStyle = "_1rvc8sn6";
var dialogStyle = "_1rvc8sn5 _1cuzvx519 _1cuzvx514u _1cuzvx51 _1cuzvx59 _1cuzvx5ai _1cuzvx567 _1cuzvx5ab _1cuzvx5t _1cuzvx513 _1cuzvx5w _1cuzvx58y _1cuzvx58p";

export { backgroundStyle, closeButtonStyle, dialogStyle };
