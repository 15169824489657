import { useState, useEffect } from 'react';

function useContainerSize(ref) {
  const [size, setSize] = useState({ width: null, height: null });
  useEffect(() => {
    const getDimensions = () => ({
      width: ref.current ? ref.current.offsetWidth : null,
      height: ref.current ? ref.current.offsetHeight : null
    });
    const observer = new ResizeObserver(() => {
      setSize(getDimensions());
    });
    if (ref.current) {
      setSize(getDimensions());
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);
  return size;
}

export { useContainerSize };
