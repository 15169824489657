import * as React from "react";
import { forwardRef } from "react";
const SvgArrangeObject = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.132 5.528 8 2.994 2.87 5.528 8 8.062l5.132-2.534ZM8.713 1.93a1.64 1.64 0 0 0-1.428 0L2.155 4.464c-.957.473-.957 1.656 0 2.128l5.131 2.534a1.64 1.64 0 0 0 1.428 0l5.132-2.534c.956-.472.956-1.655 0-2.128L8.714 1.93Zm-6.61 5.124c-.91.487-.895 1.64.046 2.105l5.131 2.534a1.64 1.64 0 0 0 1.428 0l5.132-2.534c.94-.465.956-1.618.045-2.105l-.045.023-1.388.686.674.332-5.132 2.534-5.131-2.534.673-.332-1.387-.686a1.6 1.6 0 0 1-.045-.023Zm.046 4.917c-.941-.464-.956-1.618-.045-2.104a1.6 1.6 0 0 0 .045.023l1.387.685-.673.333 5.131 2.533 5.132-2.534-.674-.332 1.388-.685.045-.023c.91.486.896 1.64-.045 2.104l-5.132 2.534a1.64 1.64 0 0 1-1.428 0L2.15 11.971Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgArrangeObject);
export default ForwardRef;
