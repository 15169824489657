import * as React from "react";
import { forwardRef } from "react";
const SvgArrowVertical = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M6.5 9.5V3.415l1.795 1.79L9 4.5l-3-3-3 3 .705.705L5.5 3.415V9.5h1Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M5.5 2v6.585l-1.795-1.79L3 7.5l3 3 3-3-.705-.705L6.5 8.585V2h-1Z" })
);
const ForwardRef = forwardRef(SvgArrowVertical);
export default ForwardRef;
