import * as React from "react";
import { forwardRef } from "react";
const SvgFirstPage = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m10.431 12 6.01-6.01 1.415 1.414L13.26 12l4.596 4.596-1.414 1.414-6.01-6.01ZM8.144 6h-2v12h2V6Z" })
);
const ForwardRef = forwardRef(SvgFirstPage);
export default ForwardRef;
