import * as React from "react";
import { forwardRef } from "react";
const SvgCuration = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2.647a1 1 0 0 1-.29.704l-4.96 5.002v4.882a1 1 0 0 1-.55.893l-3.5 1.765A1 1 0 0 1 9.25 22v-6.647L4.29 10.35A1 1 0 0 1 4 9.647V7Zm2 1v1.235l4.96 5.002a1 1 0 0 1 .29.704v5.435l1.5-.756V14.94a1 1 0 0 1 .29-.704L18 9.235V8H6Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M11 1h2v3h-2V1Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M11 1h2v3h-2V1ZM16.238 1.926l1.732 1-1.5 2.598-1.732-1 1.5-2.598ZM6.03 2.909l1.732-1 1.5 2.598-1.732 1-1.5-2.598Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCuration);
export default ForwardRef;
