import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { LabelLogo, LabelLogoInverted, CurateLogo, CurateLogoInverted, ModelLogo, ModelLogoInverted, AppsLogo } from '@superb-ai/icons';
import '../../foundation/Box/Box.js';
import { Icon } from '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import '../../theme/color-util.js';
import { vars } from '../../theme/theme.css.vanilla.js';
import '../../theme/sprinkles.css.vanilla.js';
import { sizes } from '../../theme/size.js';

const logoTypes = {
  label: { normal: LabelLogo, inverted: LabelLogoInverted },
  curate: { normal: CurateLogo, inverted: CurateLogoInverted },
  model: { normal: ModelLogo, inverted: ModelLogoInverted },
  apps: { normal: AppsLogo, inverted: AppsLogo }
  // apps는 external link이기 때문에 inverted icon 불필요
};
const SuiteLogo = forwardRef(function SuiteLogo2({ type, isInverted = false, size = "medium" }, ref) {
  function getSize() {
    const isDefinedSize = (x) => sizes.includes(x);
    if (isDefinedSize(size)) {
      return { size: vars.size[size] };
    } else {
      return { style: { fontSize: size } };
    }
  }
  return /* @__PURE__ */ jsx(Icon, { ref, icon: logoTypes[type][isInverted ? "inverted" : "normal"], ...getSize() });
});

export { SuiteLogo };
