import { AxiosError } from 'axios';
import { assign } from 'lodash';

import { MemberData } from '../types/memberTypes';
import { ProjectData } from '../types/projectTypes';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import FileService from './FileService';
import { ApiCall, Params } from './types';

type ProjectId = { projectId: string };

const createProject: ApiCall<{ newProjectInfo: any; labelInterface: any }, any> = async args => {
  const { newProjectInfo, labelInterface, isGuest, urlInfo } = args;

  const projectInfo = {
    name: newProjectInfo.projectName,
    description: newProjectInfo.description,
    workapp: newProjectInfo.selectedWorkapp,
    labelInterface,
    setting: {},
    isPublic: newProjectInfo.isPublic,
    settings: {
      allowAdvancedQa: newProjectInfo.allowAdvancedQa,
      ...(newProjectInfo.allowSelfAssign
        ? {}
        : {
            num_max_self_assign: 0,
            num_max_self_review_assign: 0,
          }),
    },
    ...(newProjectInfo.curateSyncSettings && {
      curateSyncSettings: newProjectInfo.curateSyncSettings,
    }),
    ...(newProjectInfo.curateDatasetId && { curateDatasetId: newProjectInfo.curateDatasetId }),
  };

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/projects/',
    data: projectInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const createSampleProject: ApiCall<{ params: Params }, any> = async args => {
  const { isGuest, urlInfo, params } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/commands/projects_import/',
    data: { params },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const editProjectWithNewProjectInfo: ApiCall<
  { newProjectInfo: any; labelInterface: any },
  any
> = async args => {
  const { newProjectInfo, labelInterface, isGuest, urlInfo } = args;

  const projectInfo = {
    name: newProjectInfo.projectName,
    description: newProjectInfo.description,
    labelInterface,
    setting: {},
    isPublic: newProjectInfo.isPublic,
    settings: {
      allowAdvancedQa: newProjectInfo.allowAdvancedQa,
      ...(newProjectInfo.allowSelfAssign
        ? {}
        : {
            num_max_self_assign: 0,
            num_max_self_review_assign: 0,
          }),
    },
    ...(newProjectInfo.curateSyncSettings && {
      curateSyncSettings: { is_active: newProjectInfo.curateSyncSettings },
    }),
  };
  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/projects/${newProjectInfo.projectId}/`,
    data: projectInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const editLabelInterface: ApiCall<{ labelInterface: any }, any> = async args => {
  const { labelInterface, isGuest, urlInfo } = args;

  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/projects/${urlInfo.projectId}/`,
    data: { labelInterface },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const updateProject: ApiCall<ProjectId & { newInfo: Partial<ProjectData> }, any> = async args => {
  const { projectId, newInfo, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/projects/${projectId}/`,
    data: newInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const duplicateProject: ApiCall<
  { name: string; description: string; workapp: string; labelInterface: any; isPublic: boolean },
  any
> = async args => {
  const { name, description, workapp, labelInterface, isPublic, isGuest, urlInfo } = args;
  const projectInfo = {
    name,
    description,
    workapp,
    labelInterface,
    setting: {},
    isPublic,
  };

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/projects/',
    data: projectInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getProject: ApiCall<ProjectId, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getProjectByName: ApiCall<{ projectName: string }, any> = async args => {
  const { projectName, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/name/${encodeURIComponent(projectName)}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const deleteProject: ApiCall<ProjectId, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

interface GetUsersArgs {
  asList?: boolean;
  additionalFields?: string[];
  exceptMembersRole?: string[];
  statusIn?: string[];
  roleNotIn?: string[];
}

interface GetUserResponse {
  tenant_role: 'Owner' | 'Admin' | 'Collaborator';
  count: number;
  users: MemberData[];
}

const getUsers: ApiCall<ProjectId & GetUsersArgs, GetUserResponse[]> = async ({
  projectId,
  additionalFields,
  isGuest,
  urlInfo,
  roleNotIn = [],
  statusIn = [],
}) => {
  const params = { additionalFields };
  const statusInFilter = `&${statusIn.map(s => `status__in[]=${s}`).join('&')}`;
  const roleNotInFilter = `&${roleNotIn.map(s => `role__not_in[]=${s}`).join('&')}`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/users/?${ServiceUtils.getParamString(
      params,
    )}${statusInFilter}${roleNotInFilter}`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getUsersAsList: ApiCall<ProjectId & GetUsersArgs, MemberData[]> = async ({
  projectId,
  additionalFields,
  isGuest,
  urlInfo,
  roleNotIn = [],
  statusIn = [],
}) => {
  const params = { additionalFields };
  const statusInFilter = `&${statusIn.map(s => `status__in[]=${s}`).join('&')}`;
  const roleNotInFilter = `&${roleNotIn.map(s => `role__not_in[]=${s}`).join('&')}`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/users/?${ServiceUtils.getParamString(
      params,
    )}&as_list${statusInFilter}${roleNotInFilter}`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getUserRole: ApiCall<ProjectId & { userId: string }, any> = async args => {
  const { projectId, userId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/invites/users/${userId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const updateUserRole: ApiCall<
  ProjectId & { userId: string; nextRole: string },
  any
> = async args => {
  const { projectId, userId, nextRole, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'put',
    url: `/projects/${projectId}/invites/users/${userId}/`,
    data: { role: nextRole.toLowerCase() },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const deleteUserRole: ApiCall<ProjectId & { userId: string }, any> = async args => {
  const { projectId, userId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/invites/users/${userId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getTags: ApiCall<ProjectId, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/tags/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const createTag: ApiCall<ProjectId & { tagInfo: any }, any> = async args => {
  const { projectId, tagInfo, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/tags/`,
    data: tagInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const deleteTag: ApiCall<ProjectId & { tagId: string }, any> = async args => {
  const { projectId, tagId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/tags/${tagId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getGuidelines: ApiCall<ProjectId, { count: number; results: any[] }> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/guidelines/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const uploadGuidelines: ApiCall<ProjectId & { fileInfo: any; file: any }, any> = async args => {
  const { projectId, fileInfo, file, isGuest, urlInfo } = args;
  const presignedUrlRes = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/guidelines/prep/`,
    data: {
      type: 'file',
      size: fileInfo.size,
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  const presignedUrlWithParams = presignedUrlRes.data.url;
  const presignedUrl = `tenant${presignedUrlWithParams.split('?')[0].split('/tenant')[1]}`;

  await FileService.uploadPresignedFiles([
    {
      uploadUrl: presignedUrlWithParams,
      file,
    },
  ]);

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/guidelines/`,
    data: assign(fileInfo, { path: presignedUrl }),
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const downloadGuideline: ApiCall<ProjectId & { guidelineId: string }, any> = async args => {
  const { projectId, guidelineId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/guidelines/${guidelineId}/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getTodoList: ApiCall<ProjectId, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/todolist/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getArchive: ApiCall<ProjectId, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;

  const errorHandling = (err: AxiosError) => {
    if (err.response?.status === 404) {
      throw new Error('Not sync');
    }
  };

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/archive/`,
    data: {},
    hasPublicApi: false,
    errorHandling,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const createArchiveReadUrl: ApiCall<ProjectId, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;

  const errorHandling = (err: AxiosError) => {
    if (err.response?.status === 404) {
      throw new Error('Not Found');
    }
  };

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/archive/read-url/`,
    data: {},
    hasPublicApi: false,
    errorHandling,
    isGuest,
    urlInfo,
  });

  return res.data;
};

export default {
  createProject,
  createSampleProject,
  editProjectWithNewProjectInfo,
  editLabelInterface,
  updateProject,
  duplicateProject,
  getProject,
  getProjectByName,
  deleteProject,
  getUsers,
  getUsersAsList,
  getUserRole,
  updateUserRole,
  deleteUserRole,
  getTags,
  createTag,
  deleteTag,
  getGuidelines,
  uploadGuidelines,
  downloadGuideline,
  getTodoList,
  getArchive,

  createArchiveReadUrl,
};
