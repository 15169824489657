import * as React from "react";
import { forwardRef } from "react";
const SvgRemoveFar = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M12 2.666V1.333h4v1.333z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m7.333 2-.666.667L13.333 6 14 5.333 7.333 2ZM7.333 15.333l-.666-.666L13.333 12l.667.667-6.667 2.666Z",
      fill: "#444"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m9.333 6.087-2.666-.333.666.833 1.334.167.666-.667Zm-.666 5.159.666.666-2.666.334.666-.834 1.334-.166Z",
      fill: "#444"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M8.667 6 14 5.333v7.333L8.667 12V6Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 5.036v7.387l4 1.143v-9.76l-4 1.23ZM.667 4.051v9.378l6.666 1.904V2L.667 4.051Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgRemoveFar);
export default ForwardRef;
