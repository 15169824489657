import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { differenceInMinutes } from 'date-fns';
import jwtDecode from 'jwt-decode';

import { getUrl } from '../routes/util';
import { parseEpoch } from '../utils/date';
import { resetLoginTokens } from '../utils/LoginUtils';
import ServiceUtils from '../utils/ServiceUtils';
import { ApiCall } from './types';

interface CommonArgs {
  method: string;
  url: string;
  data?: Record<string, any>;
  errorHandling?: null | ((err: AxiosError) => void);
  version?: number;
}

interface ApiCallBeforeLoginArgs extends CommonArgs {
  accountName: string;
}

interface ApiCallAfterLoginArgs extends CommonArgs {
  hasPublicApi: boolean;
  config?: Partial<AxiosRequestConfig>;
}

function gotoAuth() {
  const next = window.location.href.replace(window.location.origin, '');
  window.location.replace(getUrl('/auth/login', {}, { next }));
}

function gotoAccessAuthorization() {
  const next = window.location.href.replace(window.location.origin, '');
  window.location.replace(getUrl('/access_authorization', {}, { next }));
}

const apiCallBeforeLogin = async ({
  method,
  url,
  data = {},
  accountName,
  errorHandling = null,
  version,
}: ApiCallBeforeLoginArgs): Promise<any> => {
  try {
    const res = await axios({
      method,
      url,
      data: ServiceUtils.toSnakeCaseKeys(data),
      ...ServiceUtils.getAuthAxiosConfig({ accountName, version }),
      ...ServiceUtils.getAxiosRetry(),
    });
    return res;
  } catch (err: any) {
    ServiceUtils.handleSentryLogging(err, origin);
    if (errorHandling) errorHandling(err);
    throw err;
  }
};

const loginRefresh = async ({ accountName }: { accountName: string }): Promise<any> => {
  const errorHandling = () => {
    resetLoginTokens();
    gotoAuth();
  };

  const res = await apiCallBeforeLogin({
    method: 'post',
    url: '/auth/refresh',
    data: { idToken: localStorage.getItem('spb_user'), refreshToken: localStorage.getItem('spb_') },
    accountName,
    errorHandling,
  });

  const { accessToken, idToken, hmacToken } = res.data.data;

  localStorage.setItem('spb_access', accessToken);
  localStorage.setItem('spb_user', idToken);
  if (hmacToken) {
    localStorage.setItem('hmac_token', hmacToken);
  }

  return res;
};

const handleLoginRefresh = async ({ accountName }: { accountName: string }): Promise<void> => {
  const idToken = localStorage.getItem('spb_user');
  if (!idToken) return;
  const decodedIdToken = jwtDecode<{ exp: number }>(idToken);
  const epoch = decodedIdToken?.exp || 0;
  const expiry = parseEpoch(epoch);
  if (differenceInMinutes(expiry, new Date()) <= 5) {
    await loginRefresh({ accountName });
  }
};

const handleErrorAfterLogin = (err: AxiosError<any>): Error | undefined => {
  if (!err.response) {
    return err;
  }
  const { status } = err.response;
  switch (status) {
    case 400:
      if (
        err.response.data &&
        (err.response.data.code === '003400' || err.response.data.code === '200400')
      ) {
        resetLoginTokens();
        gotoAuth();
        // eslint-disable-next-line consistent-return
        return;
      }
      if (err.response.data.detail) {
        return new Error(`${err.response.data.detail}`);
      }
      return new Error('Bad request');
    case 401:
      resetLoginTokens();
      gotoAuth();
      return new Error('Unauthorized');
    case 403:
      if (err.response.data.detail?.code === '403999') {
        resetLoginTokens();
        gotoAccessAuthorization();
        return new Error('Access Authorization Required');
      }

      switch (err.response.data.code) {
        case '403001': // You have reached the limit for number of user seats
        case '403002': // You have reached the limit for data storage
        case '403003': // You have reached the limit for Auto-Label
          return new Error('Exceeded limits');
        default:
          return new Error('Not Authorized');
      }
    case 404:
      return new Error('Not Found');
    case 412:
      return new Error(
        err.response.data?.message ? `Server Error: ${err.response.data.message}` : 'Server Error',
      );
    case 428:
      return new Error('Precondition Required');
    case 409:
      return new Error('Duplicated');
    case 500:
      return new Error(
        err.response.data?.message ? `Server Error: ${err.response.data.message}` : 'Server Error',
      );
    default:
      return new Error('Unknown Error');
  }
};

const apiCallAfterLogin: ApiCall<ApiCallAfterLoginArgs, any> = async args => {
  const {
    method,
    url,
    data = {},
    hasPublicApi,
    isGuest,
    urlInfo,
    errorHandling = null,
    config = null,
    cancelToken,
    version,
    isCurateUrl,
  } = args;

  if (!isGuest) {
    try {
      await handleLoginRefresh({ accountName: urlInfo.accountName });
      const axiosConfig =
        config ||
        ServiceUtils.getAxiosConfig({ accountName: urlInfo.accountName, version, isCurateUrl });

      const res = await axios({
        method,
        url,
        data: ServiceUtils.toSnakeCaseKeys(data),
        cancelToken,
        ...axiosConfig,
        ...ServiceUtils.getAxiosRetry(),
      });

      return res;
    } catch (err: any) {
      ServiceUtils.handleSentryLogging(err, origin);
      if (errorHandling) errorHandling(err);
      throw handleErrorAfterLogin(err) || err;
    }
  } else if (!hasPublicApi) {
    // 여기는 번역할 필요가 없었음.......
    throw new Error('No public API');
  } else {
    const axiosConfig =
      config || ServiceUtils.getGuestAxiosConfig({ accountName: urlInfo.accountName, version });

    try {
      const response = await axios({
        method,
        url,
        data: ServiceUtils.toSnakeCaseKeys(data),
        ...axiosConfig,
        ...ServiceUtils.getAxiosRetry(),
      });
      return response;
    } catch (err: any) {
      if (err.response.status === 404) {
        // 여기는 번역할 필요가 없었음.......
        throw new Error('Page not found');
      }
      ServiceUtils.handleSentryLogging(err, origin);
      if (errorHandling) errorHandling(err);
      throw err;
    }
  }
};

const deleteApiKey: ApiCall<unknown, any> = async ({ isGuest, urlInfo }) => {
  const res = await apiCallAfterLogin({
    method: 'delete',
    url: '/auth/tenants/apikey',
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res;
};

export default {
  loginRefresh,
  deleteApiKey,
  apiCallAfterLogin,
  apiCallBeforeLogin,
};
