import { jsx } from 'react/jsx-runtime';
import { Fragment } from 'react';
import { useCompositeState, CompositeGroup, CompositeItem } from 'reakit/Composite';
import { mergeProps } from '@react-aria/utils';
import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import '@superb-ai/icons';
import '../../contrib/Chip/Chip.js';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import { Button } from '../Button/Button.js';
import '../Button/IconButton.js';
import '../Button/IconButtonWithTriangle.js';
import '../Button/styles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './../Dropdown/styles.css.ts.vanilla.css';
import '../Input/Input.js';
import '../Select/styles.css.vanilla.js';
import '../Checkbox/Checkbox.js';
import '../Label/Label.js';
import '../Radio/Radio.js';
import 'reakit/Radio';
import '@vanilla-extract/dynamic';
import 'classnames';
import '../../theme/color-util.js';
import '../../theme/sprinkles.css.vanilla.js';
import '../Range/Range.js';
import '../Range/styles.css.vanilla.js';
import { segmentedControlRecipe } from './styles.css.vanilla.js';
import 'rc-overflow';
import '../ToggleButton/ToggleButton.js';
import '../../modal/Dialog/Dialog.js';
import '../../modal/Dialog/contexts.js';
import 'reakit/Popover';
import { Tooltip } from '../../modal/Tooltip/Tooltip.js';

const defaultButtonProps = { variant: "stroke", color: "primary", inactive: true };
const defaultActiveButtonProps = { variant: "stroke", inactive: false };
function SegmentedControl(props) {
  const composite = useCompositeState();
  const {
    options,
    value,
    onChangeValue,
    buttonProps = {},
    activeButtonProps = defaultActiveButtonProps,
    flexWidth = false,
    ...boxProps
  } = props;
  return /* @__PURE__ */ jsx(
    CompositeGroup,
    {
      ...composite,
      as: Box,
      ...mergeProps(boxProps, { className: segmentedControlRecipe({ flexWidth }) }),
      children: options.map((option) => {
        const selected = value === option.value;
        const props2 = {
          ...defaultButtonProps,
          ...buttonProps,
          ...selected ? { ...activeButtonProps } : {},
          segmented: true,
          disabled: option.disabled
        };
        return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(CompositeItem, { ...composite, children: (compositeProps) => /* @__PURE__ */ jsx(
          Tooltip,
          {
            content: option.tooltip,
            strategy: "fixed",
            placement: "bottom",
            hideOnEmptyContent: true,
            children: /* @__PURE__ */ jsx("span", { style: { display: "flex" }, children: /* @__PURE__ */ jsx(
              Button,
              {
                ...compositeProps,
                ...props2,
                "aria-selected": selected,
                onClick: (e) => {
                  props2.onClick?.(e);
                  onChangeValue?.(option.value);
                },
                children: option.label
              }
            ) })
          }
        ) }) }, `${option.value}`);
      })
    }
  );
}

export { SegmentedControl };
