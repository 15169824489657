import * as React from "react";
import { forwardRef } from "react";
const SvgChevronUnfold = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M8.465 15.536 12 19.07l3.536-3.536 1.414 1.415L12 21.9l-4.944-4.956 1.409-1.408ZM15.536 8.464 12 4.93 8.465 8.464 7.05 7.05 12 2.1l4.944 4.956-1.408 1.408Z" })
);
const ForwardRef = forwardRef(SvgChevronUnfold);
export default ForwardRef;
