import * as React from "react";
import { forwardRef } from "react";
const SvgInfoXCircleOutline = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.258 5.672 2.543 3.957l1.414-1.414 17.5 17.5-1.414 1.414-1.715-1.715A9.957 9.957 0 0 1 12 22C6.48 22 2 17.52 2 12c0-2.4.847-4.604 2.258-6.328Zm12.645 12.645A7.96 7.96 0 0 1 12 20c-4.41 0-8-3.59-8-8a7.96 7.96 0 0 1 1.683-4.903L10 11.414V13h1v4h3v-1.586l2.903 2.903ZM13.586 15 13 14.414V15h.586Zm-2.348-8L13 8.762V7h-1.761ZM20 12a7.952 7.952 0 0 1-.636 3.125l1.502 1.503A9.95 9.95 0 0 0 22 12c0-5.52-4.48-10-10-10-1.67 0-3.244.41-4.628 1.134l1.503 1.502A7.953 7.953 0 0 1 12 4c4.41 0 8 3.59 8 8Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgInfoXCircleOutline);
export default ForwardRef;
