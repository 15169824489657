import * as React from "react";
import { forwardRef } from "react";
const SvgChartScatterPlot = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 19V5c0-1.103.897-2 2-2h14c1.103 0 2 .897 2 2v14c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2Zm2 0h14.001L19 5H5v14Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "currentColor",
      d: "M13 8h1v1h-1zM14 9h1v1h-1zM12 9h1v1h-1zM13 9h1v1h-1zM13 10h1v1h-1zM15 11h1v1h-1zM16 10h1v1h-1zM16 7h1v1h-1zM16 12h1v1h-1zM16 13h1v1h-1zM17 11h1v1h-1zM14 12h1v1h-1zM15 13h1v1h-1zM17 13h1v1h-1zM17 15h1v1h-1zM6 15h1v1H6zM7 14h1v1H7zM7 13h1v1H7zM9 15h1v1H9zM7 16h1v1H7zM8 15h1v1H8zM8 14h1v1H8zM10 16h1v1h-1zM15 12h1v1h-1zM16 11h1v1h-1zM16 14h1v1h-1zM13 14h1v1h-1zM10 12h1v1h-1zM11 11h1v1h-1zM10 10h1v1h-1zM9 10h1v1H9zM9 9h1v1H9zM8 9h1v1H8zM6 8h1v1H6zM9 17h1v1H9zM11 17h1v1h-1zM11 16h1v1h-1zM9 11h1v1H9zM15 17h1v1h-1zM16 17h1v1h-1zM7 7h1v1H7zM9 7h1v1H9zM10 6h1v1h-1zM9 14h1v1H9v-1ZM8 13h1v1H8v-1Z"
    }
  )
);
const ForwardRef = forwardRef(SvgChartScatterPlot);
export default ForwardRef;
