import * as React from "react";
import { forwardRef } from "react";
const SvgAlarm = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M12 4c-4.879 0-9 4.121-9 9s4.121 9 9 9 9-4.121 9-9-4.121-9-9-9Zm0 16c-3.794 0-7-3.206-7-7s3.206-7 7-7 7 3.206 7 7-3.206 7-7 7Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M13 12V8h-2v6h6v-2h-4ZM17.284 3.707l1.412-1.416 3.01 3-1.413 1.417-3.01-3.001ZM6.698 3.707l-2.99 2.999L2.29 5.294l2.99-3 1.417 1.413Z" })
);
const ForwardRef = forwardRef(SvgAlarm);
export default ForwardRef;
