import * as React from "react";
import { forwardRef } from "react";
const SvgCommentAssign = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 4h11c1.103 0 2 .897 2 2v4h-2V6H5v9l2.134-1.6a2 2 0 0 1 1.2-.4H11v2H9a2 2 0 0 0-1.2.4L3 19V6c0-1.103.897-2 2-2Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M22 15.01h-6.5v2H22v-2Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M17.01 12 13 16.01l4.01 4.01 1.415-1.413-2.597-2.597 2.597-2.596L17.01 12Z" })
);
const ForwardRef = forwardRef(SvgCommentAssign);
export default ForwardRef;
