import * as React from "react";
import { forwardRef } from "react";
const SvgChevronBigUp = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m19.071 17.368 1.414-1.403L12 7.55l-8.485 8.416 1.414 1.403L12 10.354l7.071 7.014Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgChevronBigUp);
export default ForwardRef;
