import { jsxs, jsx } from 'react/jsx-runtime';
import { useRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { Typography } from '../../foundation/Typography/Typography.js';
import '@superb-ai/icons';
import '../../contrib/Chip/Chip.js';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import { Dialog } from './Dialog.js';
import './contexts.js';
import 'reakit/Popover';
import 'react-popper';
import '../Tooltip/styles.css.vanilla.js';
import { Button } from '../../inputs/Button/Button.js';
import '../../inputs/Button/IconButton.js';
import '../../inputs/Button/IconButtonWithTriangle.js';
import '../../inputs/Button/styles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './../../inputs/Dropdown/styles.css.ts.vanilla.css';
import '../../inputs/Input/Input.js';
import '../../inputs/Select/styles.css.vanilla.js';
import '../../inputs/Checkbox/Checkbox.js';
import '../../inputs/Label/Label.js';
import '../../inputs/Radio/Radio.js';
import 'reakit/Radio';
import '@vanilla-extract/dynamic';
import 'classnames';
import '../../theme/color-util.js';
import '../../theme/sprinkles.css.vanilla.js';
import '../../inputs/Range/Range.js';
import '../../inputs/Range/styles.css.vanilla.js';
import 'reakit/Composite';
import '../../inputs/SegmentedControl/styles.css.vanilla.js';
import 'rc-overflow';
import '../../inputs/ToggleButton/ToggleButton.js';

function renderButton({ label, ...props }, defaultProps) {
  return /* @__PURE__ */ jsx(Button, { ...mergeProps(defaultProps, props), children: label });
}
function AlertDialog({ children, title, content, mainButton, subButton, ...props }) {
  const mainButtonRef = useRef(null);
  return /* @__PURE__ */ jsxs(
    Dialog,
    {
      ...props,
      "aria-label": props["aria-label"] ?? title,
      role: "alertdialog",
      hideOnClickOutside: false,
      unstable_initialFocusRef: mainButtonRef,
      children: [
        /* @__PURE__ */ jsx(Dialog.Header, { children: title }),
        /* @__PURE__ */ jsx(Dialog.Content, { children: /* @__PURE__ */ jsx(Typography, { as: "div", variant: "m-regular", children: children ?? content }) }),
        /* @__PURE__ */ jsxs(Dialog.Actions, { children: [
          subButton && renderButton(subButton, {
            variant: "text",
            onClick() {
              props?.state?.hide();
            }
          }),
          mainButton && renderButton(mainButton, {
            variant: "strong-fill",
            color: "primary",
            ref: mainButtonRef
          })
        ] })
      ]
    }
  );
}

export { AlertDialog };
