import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var buttonColor = "var(--rpy6lw0)";
var toggleButtonRecipe = createRuntimeFn({ defaultClassName: "rpy6lw2 _1cuzvx55", variantClassNames: { size: { xs: "rpy6lw3", s: "rpy6lw4" }, variant: { "strong-fill": "rpy6lw5" } }, defaultVariants: { variant: "strong-fill", size: "s" }, compoundVariants: [] });

export { buttonColor, toggleButtonRecipe };
