import * as React from "react";
import { forwardRef } from "react";
const SvgCalendar = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M7 12h2v2H7v-2ZM7 16h2v2H7v-2ZM13 12h-2v2h2v-2ZM11 16h2v2h-2v-2ZM17 12h-2v2h2v-2ZM15 16h2v2h-2v-2Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 22H5c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2h2V2h2v2h6V2h2v2h2c1.103 0 2 .897 2 2v14c0 1.103-.897 2-2 2Zm0-14V6H5v2h14Zm0 2 .001 10H5V10h14Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCalendar);
export default ForwardRef;
