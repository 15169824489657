import * as React from "react";
import { forwardRef } from "react";
const SvgFileNew = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m13.285 5.768.007.019c.018.056.03.113.033.172 0 .006.003.013.004.019.002.007.005.014.005.022v7.333c0 .736-.598 1.334-1.334 1.334H4a1.335 1.335 0 0 1-1.333-1.334V2.667c0-.736.598-1.334 1.333-1.334h4.667c.008 0 .015.002.022.005l.02.004a.66.66 0 0 1 .192.04l.043.016c.07.033.138.073.195.13l4 4a.666.666 0 0 1 .146.24Zm-3.951-.435h1.724L9.334 3.61v1.724ZM4 2.667v10.666h8.002L12 6.667H8.667A.666.666 0 0 1 8 6V2.667H4Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M8.667 8H7.334v1.333H6v1.334h1.334V12h1.333v-1.333H10V9.333H8.667V8Z", fill: "#fff" })
);
const ForwardRef = forwardRef(SvgFileNew);
export default ForwardRef;
