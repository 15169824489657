import * as React from "react";
import { forwardRef } from "react";
const SvgComment = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M8.5 3.5h-5a.5.5 0 1 0 0 1h5a.5.5 0 1 0 0-1Zm0 2h-5a.5.5 0 1 0 0 1h5a.5.5 0 1 0 0-1Zm1-4.5h-7A1.5 1.5 0 0 0 1 2.5v5A1.5 1.5 0 0 0 2.5 9h5.795l1.85 1.855A.5.5 0 0 0 11 10.5v-8A1.5 1.5 0 0 0 9.5 1Zm.5 8.295-1.145-1.15A.5.5 0 0 0 8.5 8h-6a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v6.795Z" })
);
const ForwardRef = forwardRef(SvgComment);
export default ForwardRef;
