import * as React from "react";
import { forwardRef } from "react";
const SvgZoomOutMap = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m10 2 1.533 1.533-1.926 1.914.946.946 1.914-1.926L14 6V2h-4ZM2 6l1.533-1.533 1.914 1.926.946-.946-1.926-1.914L6 2H2v4Zm4 8-1.533-1.533 1.926-1.914-.946-.946-1.914 1.926L2 10v4h4Zm8-4-1.533 1.533-1.914-1.926-.946.946 1.926 1.914L10 14h4v-4Z" })
);
const ForwardRef = forwardRef(SvgZoomOutMap);
export default ForwardRef;
