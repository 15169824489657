import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonAutoEditPoint = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13 2h-2v4.083A6.005 6.005 0 0 0 6.083 11H2v2h4.083A6.005 6.005 0 0 0 11 17.917V22h2v-4.083A6.005 6.005 0 0 0 17.917 13H22v-2h-4.083A6.005 6.005 0 0 0 13 6.083V2Zm1.5 10a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonAutoEditPoint);
export default ForwardRef;
