import * as React from "react";
import { forwardRef } from "react";
const SvgReviewApprove = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M16.707 7.853 11 13.56 7.293 9.854 8.707 8.44 11 10.732l4.293-4.293 1.414 1.414Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 3h14c1.103 0 2 .897 2 2v10c0 1.103-.897 2-2 2H9a2 2 0 0 0-1.2.4L3 21V5c0-1.103.897-2 2-2Zm3.334 12H19V5H5v12l2.134-1.6a2 2 0 0 1 1.2-.4Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgReviewApprove);
export default ForwardRef;
