import * as React from "react";
import { forwardRef } from "react";
const SvgTextType = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M2.947 8.4H1.794L3.55 3.308h1.387l1.755 5.09H5.54L4.264 4.473h-.04L2.948 8.399Zm-.072-2.002h2.724v.84H2.875v-.84ZM8.056 8.472c-.243 0-.46-.043-.651-.127a1.048 1.048 0 0 1-.452-.38 1.14 1.14 0 0 1-.165-.632c0-.212.04-.39.117-.534a.946.946 0 0 1 .318-.348c.135-.088.287-.154.458-.2.172-.044.353-.075.542-.094.222-.023.4-.044.537-.064a.761.761 0 0 0 .296-.095.208.208 0 0 0 .091-.184V5.8c0-.157-.05-.279-.149-.365-.097-.086-.237-.13-.417-.13-.19 0-.342.043-.455.127a.583.583 0 0 0-.224.314l-.98-.08a1.33 1.33 0 0 1 .294-.601c.146-.171.334-.302.564-.393.232-.093.5-.14.806-.14.212 0 .415.025.609.075.195.05.368.127.52.231a1.124 1.124 0 0 1 .491.987V8.4H9.202V7.87h-.03c-.061.12-.143.225-.246.316a1.14 1.14 0 0 1-.37.211c-.144.05-.31.075-.5.075Zm.303-.731a.89.89 0 0 0 .413-.092.73.73 0 0 0 .281-.254.654.654 0 0 0 .102-.36V6.63a.567.567 0 0 1-.137.06c-.056.016-.12.032-.191.047a9.657 9.657 0 0 1-.408.064 1.31 1.31 0 0 0-.325.087.52.52 0 0 0-.217.162.394.394 0 0 0-.077.248c0 .144.052.255.157.33.106.075.24.113.402.113Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgTextType);
export default ForwardRef;
