import * as React from "react";
import { forwardRef } from "react";
const SvgAppsWorkflows = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M1.333 1.667c0-.184.15-.334.334-.334h2.667c.184 0 .333.15.333.334V2.5h8c.46 0 .833.373.833.833v4.334c0 .46-.373.833-.833.833H12v.667L10.667 8 12 6.833V7.5h.5v-4H4.667v.833c0 .184-.15.334-.333.334H1.667a.333.333 0 0 1-.334-.334V1.667Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M6.333 6.667c0-.184.15-.334.334-.334h2.667c.184 0 .333.15.333.334v2.666c0 .184-.15.334-.333.334H6.667a.333.333 0 0 1-.333-.334V8.5h-3.5v3.333h5.5v-.666l1.333 1.166L8.334 13.5v-.667H2.667A.833.833 0 0 1 1.834 12V8.333c0-.46.373-.833.833-.833h3.667v-.833ZM10.667 11c0-.184.15-.333.333-.333h2.667c.184 0 .333.149.333.333v2.667c0 .184-.15.333-.333.333H11a.333.333 0 0 1-.333-.333V11Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgAppsWorkflows);
export default ForwardRef;
