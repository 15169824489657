import * as React from "react";
import { forwardRef } from "react";
const SvgPolylinePlus = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 3.333a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0Zm.667-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m4.196 10.862 6.666-6.666c.197.412.53.746.942.943l-6.666 6.666a2.008 2.008 0 0 0-.942-.943Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2.667 12.666a.667.667 0 1 0 1.333 0 .667.667 0 0 0-1.333 0Zm.666-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M1.333 2.667h4V4h-4z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M4 1.333v4H2.667v-4z" })
);
const ForwardRef = forwardRef(SvgPolylinePlus);
export default ForwardRef;
