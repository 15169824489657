import * as React from "react";
import { forwardRef } from "react";
const SvgFolderOutline = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M20 5h-7.586l-1.707-1.707A.997.997 0 0 0 10 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2ZM4 19V7h16l.002 12H4Z" })
);
const ForwardRef = forwardRef(SvgFolderOutline);
export default ForwardRef;
