import * as React from "react";
import { forwardRef } from "react";
const SvgKeyPointMinus = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 17 16",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M7.833 6.963a1.939 1.939 0 1 0 0 3.878 1.939 1.939 0 0 0 0-3.878Zm4.335 1.455a4.361 4.361 0 0 0-3.85-3.85V3.57h-.97v1a4.36 4.36 0 0 0-3.85 3.849H2.5v.97h.999a4.36 4.36 0 0 0 3.85 3.85v.998h.97v-.999a4.36 4.36 0 0 0 3.849-3.85h.999v-.97h-1Zm-4.335 3.879A3.391 3.391 0 0 1 4.44 8.903a3.391 3.391 0 0 1 3.394-3.394 3.391 3.391 0 0 1 3.394 3.394 3.391 3.391 0 0 1-3.394 3.394Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M7.833 6.963a1.939 1.939 0 1 0 0 3.878 1.939 1.939 0 0 0 0-3.878Zm4.335 1.455a4.361 4.361 0 0 0-3.85-3.85V3.57h-.97v1a4.36 4.36 0 0 0-3.85 3.849H2.5v.97h.999a4.36 4.36 0 0 0 3.85 3.85v.998h.97v-.999a4.36 4.36 0 0 0 3.849-3.85h.999v-.97h-1Zm-4.335 3.879A3.391 3.391 0 0 1 4.44 8.903a3.391 3.391 0 0 1 3.394-3.394 3.391 3.391 0 0 1 3.394 3.394 3.391 3.391 0 0 1-3.394 3.394ZM15.166 3.667h-4V3h4v.667Z" })
);
const ForwardRef = forwardRef(SvgKeyPointMinus);
export default ForwardRef;
