import * as React from "react";
import { forwardRef } from "react";
const SvgCheck = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m4.414 9.45 4.95 4.95-1.414 1.414L3 10.864 4.414 9.45Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M7.95 12.985 16.435 4.5l1.414 1.414L9.364 14.4 7.95 12.986Z" })
);
const ForwardRef = forwardRef(SvgCheck);
export default ForwardRef;
