import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { Typography } from '../../foundation/Typography/Typography.js';
import { getColor } from '../../theme/color.js';
import '../../theme/sprinkles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { chipRecipe, selectedColor, selectedFontColor } from './styles.css.vanilla.js';

const Chip = forwardRef(function Chip2({ children, color = "gray", fill = "soft", style, variant = "s-regular", ...props }, ref) {
  const weight = fill === "soft" ? "100" : "400";
  const fontColor = fill === "soft" ? getColor(color, "500", true) : "white";
  return /* @__PURE__ */ jsx(
    Typography,
    {
      variant,
      className: chipRecipe({ fill }),
      style: {
        ...style,
        ...assignInlineVars({
          [selectedColor]: getColor(color, weight),
          [selectedFontColor]: fontColor
        })
      },
      ref,
      ...props,
      children
    }
  );
});

export { Chip };
