import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useRouter } from 'next/router';
import { i18n } from 'next-i18next';

import {
  getIsRoleOrderSameOrGreater,
  getIsRoleOrderSameOrLess,
} from '../components/pages/label/labelList/AssignDialog/utils';
//import TierUnion from '../union/TierUnion';
import UsersService from '../services/AccountService';
import { useAuthService } from '../services/NewAuthService';
import ProjectService from '../services/ProjectService';
import { AccountRole, ProjectRole } from '../types/memberTypes';
import { Tier } from '../union/TierUnion';
import UserRoleUnion from '../union/UserRoleUnion';
import { hideChatWidget, showChatWidget } from '../utils/chatWidget';
import { useCommandBar } from '../utils/external';
import { getLoginInfoFromStorage, resetLoginTokens } from '../utils/LoginUtils';
import {
  identifyAdminOnLogin,
  identifyCollaboratorOnLogin,
  identifyOwnerOnLogin,
  resetSegmentOnLogout,
} from '../utils/SegmentIdentifyGroup';
import UserUtils, { UserTokenInfo } from '../utils/UserUtils';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

type MfaRole = 'Admin' | 'Owner' | 'Collaborator';
type MfaStatus = 'Verified' | 'Associated' | 'Deactivated' | '';

export type AuthContextProps = StateGetterSetter<['isGuest', 'setIsGuest'], boolean> &
  StateGetterSetter<['accountName', 'setAccountName'], string | null> &
  StateGetterSetter<['email', 'setEmail'], string | null> &
  StateGetterSetter<['role', 'setRole'], AccountRole | null> &
  StateGetterSetter<['projectRole', 'setProjectRole'], ProjectRole | null> &
  StateGetterSetter<['tier', 'setTier'], Tier | null> &
  StateGetterSetter<['name', 'setName'], string | null> &
  StateGetterSetter<['firstName', 'setFirstName'], string | null> &
  StateGetterSetter<['lastName', 'setLastName'], string | null> &
  StateGetterSetter<['avatarUrl', 'setAvatarUrl'], string | null> &
  StateGetterSetter<['mfaRequiredRoles', 'setMfaRequiredRoles'], MfaRole[]> &
  StateGetterSetter<['mfaStatus', 'setMfaStatus'], MfaStatus> & {
    isInitialized: boolean;
    isLoggedIn: boolean;
    login(
      accountName: string,
      email: string,
      password: string,
    ): Promise<{ hasSession: boolean; data: any }>;
    loginWithMfa(
      accountName: string,
      email: string,
      session: string,
      userCode: string,
      rememberDevice: boolean,
    ): Promise<void>;
    sysAdminLogin(idToken: string): void;
    resetLoginInfos(): void;
    finishLogin(idToken: string, identify?: boolean): Promise<UserTokenInfo | null>;
  };

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [accountName, setAccountName] = useState<string | null>(null);
  const [role, setRole] = useState<AccountRole | null>(null);
  const [tier, setTier] = useState<Tier | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [name, setName] = useState<string | null>('');
  const [firstName, setFirstName] = useState<string | null>('');
  const [lastName, setLastName] = useState<string | null>('');
  const [avatarUrl, setAvatarUrl] = useState<string | null>('');
  const [mfaRequiredRoles, setMfaRequiredRoles] = useState<MfaRole[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isGuest, setIsGuest] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [projectRole, setProjectRole] = useState<ProjectRole | null>(null);
  const [mfaStatus, setMfaStatus] = useState<MfaStatus>('');

  const routeInfo = useRouteInfo();
  const router = useRouter();
  const { urlMatchInfo } = routeInfo;
  // for annotation app
  const projectId = urlMatchInfo?.projectId ?? router.query?.projectId ?? null;

  const history = useHistory();

  const { login: loginFetcher, loginWithMfa: loginWithMfaFetcher } = useAuthService();

  const updateAuthInfoViaToken = (userTokenInfo: UserTokenInfo) => {
    setAccountName(userTokenInfo.accountName);
    setEmail(userTokenInfo.email);
    setRole(userTokenInfo.role as AccountRole);
    setTier(`${userTokenInfo.tier}`.toUpperCase() as Tier);
    setName(userTokenInfo.name);
    setFirstName(userTokenInfo.firstName);
    setLastName(userTokenInfo.lastName);
    setIsLoggedIn(true);
  };

  const resetAuthInfo = () => {
    setAccountName(null);
    setEmail(null);
    setRole(null);
    setTier(null);
    setName(null);
    setFirstName('');
    setLastName('');
    setIsLoggedIn(false);
    setIsGuest(true);
  };

  const resetLoginInfos = () => {
    resetLoginTokens();
    resetAuthInfo();
    resetSegmentOnLogout();
  };

  const finishLogin = async (idToken: string, identify = true): Promise<UserTokenInfo | null> => {
    const userTokenInfo = UserUtils.getUserTokenInfo(idToken);
    if (!userTokenInfo) {
      return null;
    }
    const { accountName, email, role, tier, firstName, lastName } = userTokenInfo;
    updateAuthInfoViaToken(userTokenInfo);

    if (identify) {
      if (role === 'owner') {
        identifyOwnerOnLogin({
          email,
          accountId: accountName,
          plan: tier,
          firstName,
          lastName,
        });
      } else if (role === 'admin') {
        identifyAdminOnLogin({
          email,
          accountId: accountName,
          plan: tier,
          firstName,
          lastName,
        });
      } else {
        identifyCollaboratorOnLogin({
          email,
          accountId: accountName,
          plan: tier,
        });
      }
    }

    if (!UserRoleUnion.isSystemAdmin(role)) {
      UsersService.getUserInfo({
        userEmail: email,
        isGuest: false,
        urlInfo: { accountName },
      }).then(({ data }) => {
        setMfaStatus(data.data?.mfaStatus ?? '');
        setAvatarUrl(data.data?.avatarUrl ?? '');
      });
    }

    function bootCommandBar() {
      const language = i18n.language;
      const hmac = localStorage.getItem('hmac_token') ?? undefined;
      window.CommandBar?.boot(
        email,
        {
          // user properties
          email,
          role,
          accountId: accountName,
          plan: tier,
          language,
        },
        { hmac },
      );
      window.CommandBar?.setContext({
        email,
        role,
        accountId: accountName,
        plan: tier,
        language,
      });
      window.CommandBar?.addRouter((newUrl: string) => history.push(newUrl));
    }

    if (useCommandBar) {
      // Let's try this two times
      setTimeout(() => {
        if (window.CommandBar) {
          bootCommandBar();
        } else {
          setTimeout(() => {
            bootCommandBar();
          }, 1000);
        }
      }, 100);
    }

    return userTokenInfo;
  };

  /**
   * Load login info from storage
   */
  useEffect(() => {
    const idToken = getLoginInfoFromStorage();
    if (idToken) {
      finishLogin(idToken);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Load additional info based on account and role
   */
  useEffect(() => {
    setIsInitialized(true);
    // eslint-disable-next-line
  }, [accountName, role]);

  useEffect(() => {
    if (role && UserRoleUnion.isCollaborator(role)) {
      // Show chat if collaborator (account role) has a "manager" project role
      if (UserRoleUnion.isManager(projectRole)) {
        showChatWidget();
      } else {
        hideChatWidget();
      }
    } else {
      showChatWidget();
    }
  }, [projectRole]);

  /**
   * Load project role
   */
  useEffect(() => {
    (async () => {
      const isCollaborator = UserRoleUnion.isCollaborator(role as string);

      if (isGuest || !(isCollaborator && projectId)) {
        setProjectRole(role as ProjectRole);
      } else {
        const roleRes = await ProjectService.getUserRole({
          projectId,
          userId: email as string,
          isGuest,
          urlInfo: urlMatchInfo,
        });
        setProjectRole(roleRes.role);
      }
    })();

    return () => {
      setProjectRole(null);
    };

    // eslint-disable-next-line
  }, [projectId]);

  const sysAdminLogin = (idToken: string) => {
    const userTokenInfo = UserUtils.getUserTokenInfo(idToken);
    updateAuthInfoViaToken(userTokenInfo);
  };

  const login = async (accountName: string, email: string, password: string) => {
    const { data } = await loginFetcher({
      email,
      password,
      tenantId: accountName,
    });
    if (data.session) {
      const { session, challengeName } = data;
      return { hasSession: true, data: { accountName, email, session, challengeName } };
    }
    if (data.idToken) {
      await finishLogin(data.idToken);
    }

    return { hasSession: false, data };
  };

  const loginWithMfa = async (
    accountName: string,
    email: string,
    session: string,
    userCode: string,
    rememberDevice: boolean,
  ) => {
    const { data } = await loginWithMfaFetcher({
      email,
      session,
      userCode,
      rememberDevice,
      tenantId: accountName,
    });
    await finishLogin(data.idToken);
  };

  return (
    <AuthContext.Provider
      value={{
        isInitialized,
        isLoggedIn,
        isGuest,
        setIsGuest,
        accountName,
        setAccountName,
        email,
        setEmail,
        role,
        setRole,
        projectRole,
        setProjectRole,
        tier,
        setTier,
        name,
        setName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        avatarUrl,
        setAvatarUrl,
        mfaRequiredRoles,
        setMfaRequiredRoles,
        mfaStatus,
        setMfaStatus,
        sysAdminLogin,
        login,
        loginWithMfa,
        finishLogin,
        resetLoginInfos,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthInfo = (): AuthContextProps => {
  return useContext(AuthContext);
};

export function canManageProject(authInfo?: AuthContextProps): boolean {
  return (
    !!authInfo &&
    !authInfo.isGuest &&
    getIsRoleOrderSameOrLess(authInfo.projectRole || authInfo.role || '', 'Manager')
  );
}

export function isLabelerOrReviewer(authInfo?: AuthContextProps): boolean {
  return (
    !!authInfo &&
    !authInfo.isGuest &&
    getIsRoleOrderSameOrGreater(authInfo.projectRole || authInfo.role || '', 'Reviewer')
  );
}

export function isLabeler(authInfo?: AuthContextProps): boolean {
  return (
    !!authInfo &&
    !authInfo.isGuest &&
    getIsRoleOrderSameOrGreater(authInfo.projectRole || authInfo.role || '', 'Labeler')
  );
}

export function isOwnerOrAdminOrSystemAdmin(authInfo?: AuthContextProps): boolean {
  return (
    !!authInfo &&
    !authInfo.isGuest &&
    getIsRoleOrderSameOrLess(authInfo.projectRole || authInfo.role || '', 'System Admin')
  );
}

export function isOwnerOrAdmin(authInfo?: AuthContextProps): boolean {
  return (
    !!authInfo &&
    !authInfo.isGuest &&
    getIsRoleOrderSameOrLess(authInfo.projectRole || authInfo.role || '', 'Admin')
  );
}

export function isSystemAdmin(authInfo?: AuthContextProps): boolean {
  return !!authInfo && !authInfo.isGuest && authInfo.role === 'system admin';
}

export function isOwner(authInfo?: AuthContextProps): boolean {
  return (
    !!authInfo &&
    !authInfo.isGuest &&
    (authInfo.projectRole || authInfo.role)?.toLocaleLowerCase() === 'owner'
  );
}

export function isMfaRequired(authInfo?: AuthContextProps): boolean {
  const requiredRoles = (authInfo?.mfaRequiredRoles ?? []).map(r => r.toUpperCase());
  const role = authInfo?.role?.toUpperCase() ?? '';
  return !!authInfo && (requiredRoles.includes('ALL') || requiredRoles.includes(role));
}
