import * as React from "react";
import { forwardRef } from "react";
const SvgScatterToolbarDrawBox = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 3h4v2H5v2H3V3Zm18 18h-4v-2h2v-2h2v4ZM19 3h-2v2h2v2h2V3h-2ZM7 21H3v-4h2v2h2v2ZM9 3h2v2H9V3ZM5 9H3v2h2V9Zm16 0h-2v2h2V9ZM3 13h2v2H3v-2Zm18 0h-2v2h2v-2Zm-10 6H9v2h2v-2Zm2-16h2v2h-2V3Zm2 16h-2v2h2v-2Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgScatterToolbarDrawBox);
export default ForwardRef;
