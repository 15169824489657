import * as React from "react";
import { forwardRef } from "react";
const SvgWarningOutline = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M11 10h2v5h-2v-5ZM12 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M2.734 21.002a1 1 0 0 1-.865-1.501l9.265-16.004a1 1 0 0 1 1.731 0L22.131 19.5a1 1 0 0 1-.866 1.501H2.735Zm16.797-2L12 5.994 4.469 19.002H19.53Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M11 16h2v2h-2v-2Z" })
);
const ForwardRef = forwardRef(SvgWarningOutline);
export default ForwardRef;
