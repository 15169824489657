import * as React from "react";
import { forwardRef } from "react";
const SvgQueryBuilder = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20 6.444V12h-2V9.341c-1.479.941-3.637 1.548-6 1.548s-4.521-.607-6-1.548V12c.069.548 2.309 2.222 6 2.222v2.222c-2.363 0-4.521-.606-6-1.547v2.659c.069.547 2.309 2.222 6 2.222V22c-4.337 0-8-2.036-8-4.444V6.444C4 4.036 7.663 2 12 2s8 2.036 8 4.444Zm-8-2.222c3.691 0 5.931 1.675 6 2.216-.069.554-2.309 2.229-6 2.229S6.069 6.992 6 6.45c.069-.554 2.309-2.229 6-2.229Zm8.434 14.798 2.568 2.568L21.586 23l-2.566-2.566A3.972 3.972 0 0 1 17 21c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4c0 .739-.216 1.425-.566 2.02ZM19 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgQueryBuilder);
export default ForwardRef;
