import { TFunction } from 'i18next';
import { startCase } from 'lodash';

const USER_ROLE = [
  'OWNER',
  'ADMIN',
  'MANAGER',
  'REVIEWER',
  'WORKER',
  'LABELER',
  'COLLABORATOR',
  'SYSTEM_ADMIN',
  'GUEST',
  'OWNER_ADMIN',
] as const;

export type UserRole = (typeof USER_ROLE)[number];

export const AccountRoles = ['owner', 'admin', 'collaborator'] as const;
export const ProjectRoles = ['manager', 'reviewer', 'worker'] as const;

const isOwner = (userRole: string | null): boolean => 'OWNER' === userRole?.toUpperCase();

const isAdmin = (userRole: string | null): boolean =>
  'ADMIN' === userRole?.toUpperCase() || isSystemAdmin(userRole);

const isManager = (userRole: string | null): boolean => 'MANAGER' === userRole?.toUpperCase();

const isReviewer = (userRole: string | null): boolean => 'REVIEWER' === userRole?.toUpperCase();

const isWorker = (userRole: string | null): boolean => 'WORKER' === userRole?.toUpperCase();

const isLabeler = (userRole: string | null): boolean => 'LABELER' === userRole?.toUpperCase();

const isCollaborator = (userRole: string | null): boolean =>
  'COLLABORATOR' === userRole?.toUpperCase() ||
  isManager(userRole) ||
  isWorker(userRole) ||
  isReviewer(userRole);

const isSystemAdmin = (userRole: string | null): boolean =>
  'SYSTEM_ADMIN' === userRole?.toUpperCase().replace(' ', '_');

export function getRoleLabel(t: TFunction, userRole: string): string {
  const key = `projectMembers.role.${userRole?.toLowerCase()}`;
  return t(key, startCase(userRole));
}

export default {
  USER_ROLE,
  isOwner,
  isAdmin,
  isManager,
  isWorker,
  isReviewer,
  isLabeler,
  isCollaborator,
  isSystemAdmin,
};
