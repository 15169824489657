import * as React from "react";
import { forwardRef } from "react";
const SvgImageViewReset = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 2H2v6h2V4h4V2Zm8 20h6v-6h-2v4h-4v2Zm6-18v4h-2V4h-4V2h6v2ZM2 16v6h6v-2H4v-4H2Zm5.967-1.224v-6.81h8.066v6.243l-2.358-2.948a.245.245 0 0 0-.382 0l-2.602 3.253-1.204-1.204a.244.244 0 0 0-.364.02l-1.156 1.446ZM6.5 16.61v.279c0 .337.274.611.611.611h9.778a.611.611 0 0 0 .611-.611V7.11a.611.611 0 0 0-.611-.611H7.11a.611.611 0 0 0-.611.611v9.498Zm4.889-5.771a.917.917 0 1 1-1.833 0 .917.917 0 0 1 1.833 0Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgImageViewReset);
export default ForwardRef;
