import * as React from "react";
import { forwardRef } from "react";
const SvgNumericType = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M7.598 2h1l-1.193 8H6.402l1.196-8ZM4.598 2h1l-1.193 8H3.402l1.196-8Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M10.152 5.006H2.146L2.302 4h8l-.15 1.006ZM9.704 7.998H1.698l.156-1.006h8l-.15 1.006Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgNumericType);
export default ForwardRef;
