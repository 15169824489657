import * as React from "react";
import { forwardRef } from "react";
const SvgCursorNeSwResize = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { filter: "url(#cursorNeSwResize_svg__a)", clipRule: "evenodd" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      d: "m14.748 12.087-4.08 4.078L13.503 19l-8.49-.013L5 10.497l2.847 2.848 4.083-4.078 1.417-1.418L10.497 5H19v8.505l-2.836-2.834-1.416 1.416Z",
      fill: "#fff"
    }
  ), /* @__PURE__ */ React.createElement("path", { d: "M13.686 11.733 9.25 16.168 11.082 18l-5.067-.016-.014-5.066 1.846 1.845 4.437-4.432 2.479-2.482-1.848-1.847h5.083v5.083l-1.833-1.832-2.48 2.48Z" })),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement(
    "filter",
    {
      id: "cursorNeSwResize_svg__a",
      x: -2.1,
      y: -1,
      width: 28,
      height: 28,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ React.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ React.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ React.createElement("feOffset", { dx: -0.1, dy: 1 }),
    /* @__PURE__ */ React.createElement("feGaussianBlur", { stdDeviation: 1 }),
    /* @__PURE__ */ React.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.42 0" }),
    /* @__PURE__ */ React.createElement("feBlend", { in2: "BackgroundImageFix", result: "effect1_dropShadow" }),
    /* @__PURE__ */ React.createElement("feBlend", { in: "SourceGraphic", in2: "effect1_dropShadow", result: "shape" })
  ))
);
const ForwardRef = forwardRef(SvgCursorNeSwResize);
export default ForwardRef;
