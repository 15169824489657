import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var buttonColor = "var(--_8bdbnc0)";
var buttonHoverColor = "var(--_8bdbnc1)";
var buttonRecipe = createRuntimeFn({ defaultClassName: "_1cuzvx55 _1cuzvx5l _1cuzvx5f _1cuzvx56i _1cuzvx5v _8bdbnc3", variantClassNames: { size: { xs: "_8bdbnc6", s: "_8bdbnc7", m: "_8bdbnc8", l: "_8bdbnc9", xl: "_8bdbnca" }, variant: { text: "_8bdbncb", stroke: "_8bdbncc", "strong-fill": "_8bdbncd", "soft-fill": "_8bdbnce" }, square: { true: "_8bdbncf" }, segmented: { true: "_8bdbncg" } }, defaultVariants: { variant: "stroke", size: "m" }, compoundVariants: [] });
var buttonStrokeColor = "var(--_8bdbnc2)";
var comboboxRecipe = createRuntimeFn({ defaultClassName: "_1cuzvx55 _1cuzvx5l _1cuzvx5f _1cuzvx56i _1cuzvx5v _8bdbnc3 _8bdbnch", variantClassNames: { size: { xs: "_8bdbncj", s: "_8bdbnck", m: "_8bdbncl", l: "_8bdbncm", xl: "_8bdbncn" }, variant: { text: "_8bdbnco", stroke: "_8bdbncp", "strong-fill": "_8bdbncq", "soft-fill": "_8bdbncr" } }, defaultVariants: { variant: "stroke", size: "m" }, compoundVariants: [] });
var iconBadgeStyle = "_8bdbnct _1cuzvx530 _1cuzvx53i _1cuzvx5ji _1cuzvx5ae _1cuzvx55 _1cuzvx5f _1cuzvx5u";

export { buttonColor, buttonHoverColor, buttonRecipe, buttonStrokeColor, comboboxRecipe, iconBadgeStyle };
