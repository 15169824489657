import * as React from "react";
import { forwardRef } from "react";
const SvgLink2 = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m12.707 15.536 1.414 1.414L12 19.07A5 5 0 1 1 4.929 12L7.05 9.879l1.414 1.414-2.12 2.121a3 3 0 1 0 4.242 4.243l2.121-2.121ZM11.293 8.464 9.879 7.05 12 4.93A5 5 0 0 1 19.071 12l-2.121 2.121-1.414-1.414 2.12-2.121a3 3 0 1 0-4.242-4.243l-2.121 2.121Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "m14.829 7.757-7.072 7.071 1.415 1.415 7.07-7.071-1.414-1.415Z" })
);
const ForwardRef = forwardRef(SvgLink2);
export default ForwardRef;
