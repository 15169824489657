import { jsxs, jsx } from 'react/jsx-runtime';
import { ChevronDown } from '@superb-ai/icons';
import '../../foundation/Box/Box.js';
import { Icon } from '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import 'react';
import '@react-aria/utils';
import '../../contrib/Chip/Chip.js';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import { DropdownButton } from '../Button/Button.js';
import '../Button/IconButton.js';
import '../Button/IconButtonWithTriangle.js';
import '../Button/styles.css.vanilla.js';
import { popoverStyle } from './styles.css.vanilla.js';
import '../Input/Input.js';
import '../Select/styles.css.vanilla.js';
import '../Checkbox/Checkbox.js';
import '../Label/Label.js';
import '../Radio/Radio.js';
import 'reakit/Radio';
import '@vanilla-extract/dynamic';
import 'classnames';
import '../../theme/color-util.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import '../../theme/sprinkles.css.vanilla.js';
import '../Range/Range.js';
import '../Range/styles.css.vanilla.js';
import 'reakit/Composite';
import '../SegmentedControl/styles.css.vanilla.js';
import 'rc-overflow';
import '../ToggleButton/ToggleButton.js';
import '../../modal/Dialog/Dialog.js';
import '../../modal/Dialog/contexts.js';
import { Popover } from '../../modal/Popover/Popover.js';
import 'react-popper';
import '../../modal/Tooltip/styles.css.vanilla.js';

function Dropdown({
  children,
  disclosure,
  hideOnClick,
  fixed,
  useDefaultDisclosureSetup = true,
  initialFocusRef,
  onChangeVisible,
  ...props
}) {
  const input = /* @__PURE__ */ jsxs(DropdownButton, { ...props, children: [
    disclosure,
    /* @__PURE__ */ jsx(Icon, { icon: ChevronDown, className: "disclosure-arrow" })
  ] });
  const onToggle = (visible) => {
    onChangeVisible?.(visible);
  };
  return /* @__PURE__ */ jsx(
    Popover,
    {
      disclosure: useDefaultDisclosureSetup ? input : disclosure,
      modal: true,
      hideOnClick,
      sameWidth: true,
      className: popoverStyle,
      onToggle,
      unstable_initialFocusRef: initialFocusRef,
      fixed,
      children
    }
  );
}

export { Dropdown };
