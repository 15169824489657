import * as React from "react";
import { forwardRef } from "react";
const SvgPen = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m18.096 11.307-3.932 7.078c-.143.256-.387.44-.673.504l-9.235 2.085a1.029 1.029 0 0 1-1.23-1.23l2.085-9.235c.065-.286.248-.53.504-.673l7.078-3.932-.207-.208c-.201-.2-.201-.526 0-.727l1.818-1.818c.2-.201.526-.201.727 0l5.818 5.818c.201.2.201.526 0 .727l-1.818 1.818c-.2.201-.526.201-.727 0l-.208-.207Zm-5.283 5.274 3.77-6.787-2.377-2.377-6.787 3.77c-.256.143-.44.387-.504.673l-1.27 5.622 3.586-3.586a1.543 1.543 0 1 1 .873.873l-3.585 3.585 5.621-1.27c.286-.064.53-.247.673-.503Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPen);
export default ForwardRef;
