import * as React from "react";
import { forwardRef } from "react";
const SvgFile = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: "1em", viewBox: "0 0 24 24", height: "1em", fill: "currentColor", ref, ...props }, /* @__PURE__ */ React.createElement(
  "path",
  {
    d: "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z",
    fill: "currentColor"
  }
));
const ForwardRef = forwardRef(SvgFile);
export default ForwardRef;
