import axios from 'axios';

export const resetLoginTokens = (): void => {
  if (localStorage.getItem('spb_user')) {
    localStorage.removeItem('spb_access');
    localStorage.removeItem('spb_user');
    localStorage.removeItem('spb_');
    localStorage.removeItem('hmac_token');
    delete axios.defaults.headers.Authorization;
  }
};

export const getLoginInfoFromStorage = (): string | null => {
  const idToken = localStorage.getItem('spb_user');
  return idToken;
};

export const setLoginTokens = (
  accessToken: string,
  idToken: string,
  refreshToken: string,
  hmacToken: string,
): void => {
  localStorage.setItem('spb_access', accessToken);
  localStorage.setItem('spb_user', idToken);
  localStorage.setItem('spb_', refreshToken);
  if (hmacToken) {
    localStorage.setItem('hmac_token', hmacToken);
  }
};
