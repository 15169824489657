import * as React from "react";
import { forwardRef } from "react";
const SvgClock = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M13 7h-2v6h6v-2h-4V7Z" })
);
const ForwardRef = forwardRef(SvgClock);
export default ForwardRef;
