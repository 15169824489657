import { scalePx } from './scale.js';

const numerics = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8];
const numericalDef = Object.fromEntries(numerics.map((n) => [`${n}`, scalePx(n)]));
const size = {
  xsmall: scalePx(2),
  small: scalePx(3),
  medium: scalePx(4),
  large: scalePx(5),
  xlarge: scalePx(6),
  xs: scalePx(2),
  s: scalePx(3),
  m: scalePx(4),
  l: scalePx(5),
  ...numericalDef
};
const sizes = Object.keys(size);

export { size, sizes };
