import * as React from "react";
import { forwardRef } from "react";
const SvgHistory = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 12a9 9 0 1 1 3.326 6.986l1.26-1.552A7 7 0 1 0 5 12h3.001l-4 5-4-5h3Zm10 .577V8h-2v5.727l.004-.002-.004.007 4.33 2.5 1-1.732L13 12.577Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgHistory);
export default ForwardRef;
