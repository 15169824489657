import * as React from "react";
import { forwardRef } from "react";
const SvgTerminal = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2ZM4 18V6h16l.002 12H4Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M12 14h6v2h-6v-2Zm-6-4 2.293 2.293L6 14.586 7.414 16l3.707-3.707-3.707-3.707L6 10Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgTerminal);
export default ForwardRef;
