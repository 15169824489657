import * as React from "react";
import { forwardRef } from "react";
const SvgChevronFold = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M15.536 19.778 12 16.243l-3.535 3.535-1.415-1.414 4.95-4.95 4.944 4.955-1.408 1.41ZM8.465 4.222 12 7.757l3.536-3.535 1.414 1.414-4.95 4.95L7.056 5.63l1.409-1.41Z" })
);
const ForwardRef = forwardRef(SvgChevronFold);
export default ForwardRef;
