import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonAutoEdit = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M7.017 3.037c.125-.137.31-.22.65-.37-.34-.151-.525-.234-.65-.372-.151-.167-.213-.414-.35-.962-.137.548-.2.795-.35.962-.125.138-.311.22-.65.371.339.151.525.234.65.371.15.167.213.415.35.963.137-.548.199-.796.35-.963Zm-3.756 3.1c.208-.234.517-.378 1.072-.637-.555-.26-.864-.404-1.072-.637-.26-.292-.363-.724-.594-1.697-.232.973-.335 1.405-.595 1.697-.208.233-.516.377-1.072.637.556.259.864.403 1.072.637.26.292.363.724.595 1.696.231-.972.334-1.404.594-1.696Zm9.072 6.196c-.386.184-.601.287-.747.451-.184.209-.256.517-.42 1.216-.162-.699-.234-1.007-.419-1.216-.145-.165-.361-.267-.747-.451.386-.184.602-.287.747-.451.185-.209.257-.517.42-1.216.163.699.235 1.007.419 1.216.146.165.361.267.747.451Zm-8.812.673a1.118 1.118 0 0 0 1.58 0L13.007 5.1a1.118 1.118 0 0 0 0-1.58l-.527-.527a1.118 1.118 0 0 0-1.58 0l-7.905 7.904a1.118 1.118 0 0 0 0 1.58l.527.528Zm5.796-6.85a1.116 1.116 0 0 0-.308-.22l2.416-2.415a.373.373 0 0 1 .527 0l.527.527a.373.373 0 0 1 0 .527L10.063 6.99a1.117 1.117 0 0 0-.219-.309l-.527-.527Z"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonAutoEdit);
export default ForwardRef;
