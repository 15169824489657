import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { isEqual } from 'lodash';

import { useAuthService } from '../services/NewAuthService';
import { useAuthInfo } from './AuthContext';
import { useRouteInfo } from './RouteContext';

export type BreadcrumbItem = {
  title: string;
  path: string;
};
export type Region = 'ap-northeast-2' | 'us-east-1';

interface Context {
  setLastUrl(app: string, url: string): void;
  getLastUrl(app: string): string;
  pageTitle: string | JSX.Element;
  setPageTitle(title: string | JSX.Element): void;
  pageId: string;
  setPageId(name: string): void;
  tenantAvatar: null | string;
  tenantCountry: null | string;
  setTenantAvatar(avatar: string): void;
  breadcrumb: BreadcrumbItem[];
  setBreadcrumb(value: BreadcrumbItem[]): void;
  region: Region | '';
}

// This is global state, not managed by React
const lastUrlByApp: Record<string, string> = {};

const AppContext = React.createContext({} as Context);

export const AppContextProvider: React.FC = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string | JSX.Element>('');
  const [pageId, setPageId] = useState<string>('');
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([]);
  const [tenantAvatar, setTenantAvatar] = useState<null | string>(null);
  const [tenantCountry, setTenantCountry] = useState<null | string>(null);
  const [region, setRegion] = useState<Region | ''>('');

  const routeInfo = useRouteInfo();
  const { accountName } = routeInfo.urlMatchInfo;
  const authInfo = useAuthInfo();

  const { getTenantInfo } = useAuthService();

  async function fetchTenantInfo() {
    try {
      const { data } = await getTenantInfo();
      setTenantAvatar(data.avatarUrl);
      authInfo.setMfaRequiredRoles(data.mfaRequiredRoles ?? []);
      setTenantCountry(data.country);
      setRegion(data.region);
    } catch (e: any) {
      // Ignore
    }
  }

  useEffect(() => {
    if (!accountName || !authInfo.isLoggedIn || authInfo.isGuest) {
      return;
    }
    fetchTenantInfo();
  }, [accountName, authInfo.isLoggedIn, authInfo.isGuest]);

  const setLastUrl = (app: string, url: string) => {
    lastUrlByApp[app] = url;
  };
  function getLastUrl(app: string) {
    return lastUrlByApp[app];
  }

  useEffect(() => {
    document.title = `${pageTitle} - Superb Platform`;
    // Set page id to use for page view tracking
    document.documentElement.setAttribute('data-page-id', pageId);
  }, [pageId, pageTitle]);

  return (
    <AppContext.Provider
      value={{
        setLastUrl,
        getLastUrl,
        pageTitle,
        setPageTitle,
        tenantAvatar,
        setTenantAvatar,
        breadcrumb,
        setBreadcrumb,
        tenantCountry,
        region,
        pageId,
        setPageId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): Context => {
  return useContext(AppContext);
};

/**
 * Set page title based on component props.
 * Page title is set automatically in AppRoutes when using MenuItems for navigation,
 * so this is only needed for pages that do not have MenuItems.
 */
export const useSetPageTitle = (title: string | JSX.Element | undefined, pageId: string): void => {
  const { setPageTitle, setPageId } = useAppContext();
  useEffect(() => {
    if (title) {
      ReactDOM.unstable_batchedUpdates(() => {
        setPageId(pageId);
        setPageTitle(title);
      });
    }
  }, [pageId, setPageId, setPageTitle, title]);
};

/**
 * Set breadcrumbs based on component props.
 * this is only needed for pages that do not have MenuItems.
 */
export const useSetBreadcrumb = (newBreadcrumb: BreadcrumbItem[] | undefined): void => {
  const { setBreadcrumb, pageTitle, breadcrumb } = useAppContext();
  useEffect(() => {
    if (newBreadcrumb && !isEqual(newBreadcrumb, breadcrumb)) {
      setBreadcrumb(newBreadcrumb);
    }
  }, [newBreadcrumb]);
};
