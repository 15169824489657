import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import Overflow from 'rc-overflow';
import { Chip } from '../../contrib/Chip/Chip.js';
import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { Typography } from '../../foundation/Typography/Typography.js';
import 'react';
import '@react-aria/utils';
import '@superb-ai/icons';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import { Dropdown } from '../Dropdown/Dropdown.js';
import '../Input/Input.js';
import { SelectList } from './SelectList.js';
import '../Button/Button.js';
import '../Button/IconButton.js';
import '../Button/IconButtonWithTriangle.js';
import '../Button/styles.css.vanilla.js';
import '../Checkbox/Checkbox.js';
import '../Label/Label.js';
import '../Radio/Radio.js';
import 'reakit/Radio';
import '@vanilla-extract/dynamic';
import 'classnames';
import '../../theme/color-util.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import '../../theme/sprinkles.css.vanilla.js';
import '../Range/Range.js';
import '../Range/styles.css.vanilla.js';
import 'reakit/Composite';
import '../SegmentedControl/styles.css.vanilla.js';
import '../ToggleButton/ToggleButton.js';
import '../../modal/Dialog/Dialog.js';
import '../../modal/Dialog/contexts.js';
import 'reakit/Popover';
import { Tooltip } from '../../modal/Tooltip/Tooltip.js';

function Select(props) {
  const {
    value,
    formatValue,
    multiple,
    data,
    placeholder = "Please select",
    initialFocusRef
  } = props;
  const { hideOnClick = !multiple } = props;
  const {
    fixed,
    variant,
    color,
    size,
    disabled,
    prefix,
    suffix,
    disclosure,
    onClickDisclosure,
    ...listProps
  } = props;
  function formatSelected(value2) {
    if (Array.isArray(value2)) {
      return /* @__PURE__ */ jsx(
        Overflow,
        {
          data: value2,
          renderItem: (v) => formatValue?.(v) ?? /* @__PURE__ */ jsx(Chip, { color: "gray", fill: "strong", whiteSpace: "nowrap", children: data?.find((option) => v === option.value)?.label ?? v }),
          renderRest: (v) => /* @__PURE__ */ jsx(
            Tooltip,
            {
              content: /* @__PURE__ */ jsx(
                Box,
                {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 0.25,
                  overflow: "auto",
                  children: v.map(
                    (v2, i) => formatValue?.(v2) ?? /* @__PURE__ */ jsx(Chip, { color: "gray", fill: "strong", whiteSpace: "nowrap", children: data?.find((option) => v2 === option.value)?.label ?? v2 }, `${v2}-${i}`)
                  )
                }
              ),
              children: /* @__PURE__ */ jsxs(Chip, { color: "gray", fill: "strong", whiteSpace: "nowrap", children: [
                "+",
                v.length
              ] })
            }
          ),
          maxCount: "responsive",
          style: { width: "100%", display: "flex", gap: "4px" }
        }
      );
    }
    return /* @__PURE__ */ jsx(Typography, { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", variant: "m-regular", children: formatValue?.(value2) ?? data?.find((option) => value2 === option.value)?.label ?? value2 });
  }
  const defaultDisclosure = /* @__PURE__ */ jsx(Fragment, { children: value === null || multiple && value.length === 0 ? /* @__PURE__ */ jsx(
    Typography,
    {
      color: props.variant === "strong-fill" ? "white" : "gray-300",
      variant: "m-regular",
      children: placeholder
    }
  ) : /* @__PURE__ */ jsx(Box, { width: "100%", display: "flex", alignItems: "center", gap: 0.5, overflow: "hidden", children: formatSelected(value) }) });
  return /* @__PURE__ */ jsxs(
    Dropdown,
    {
      disclosure: disclosure || defaultDisclosure,
      hideOnClick,
      ...{ fixed, variant, color, size, disabled, initialFocusRef, onClick: onClickDisclosure },
      children: [
        prefix && prefix,
        /* @__PURE__ */ jsx(SelectList, { ...listProps, py: 0.5, maxHeight: props.maxHeight ?? 300 }),
        suffix && suffix
      ]
    }
  );
}

export { Select };
