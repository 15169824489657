import { lowerCase } from 'lodash';

import { formatCurationType } from './curateUtils';
import {
  AccountId,
  AutoCurateReportDownloadedProps,
  AutoCurateRequestedProps,
  AutoLabelConfiguredProperties,
  AutoLabelInitiatedProperties,
  ChartClickedProperties,
  ChartDownloadedProperties,
  ContactFormSubmittedProperties,
  ContactUsClickedProperties,
  CreateCustomAutoLabelAIInitiatedProperties,
  CreateProjectButtonClickedProperties,
  CurateDataComparedProps,
  CurateDataUploadRequestedProps,
  CustomAutoLabelAIAppliedProperties,
  CustomAutoLabelRequestCanceledProperties,
  DataSliceCreatedProps,
  DiagnoseModelButtonClicked,
  DocsLinkClickedProperties,
  ExportCompareReportGenerated,
  HelpHubClickedProperties,
  IntegrationAddedProperties,
  InvitationsSentProperties,
  LabelDataUploadRequestedProperties,
  LabelExportDownloadedProperties,
  LabelExportRequestedProperties,
  LabelListFilteredProperties,
  LabelReviewedProps,
  LabelSelfAssignRequestedProperties,
  LabelSkippedProperties,
  LabelSubmittedProperties,
  LabelUploadDialogOpenedProperties,
  MembersAssignedProps,
  ModelCancelCompleteRequestedProps,
  ModelDetailClickedProps,
  ModelTrainingRequestedProps,
  ModelTrainingStepCompletedProps,
  OverviewLinkClickedProps,
  PersonalAccessKeyCreatedProperties,
  ProjectCreatedProperties,
  QueryBuilderAppliedProps,
  ReturnToAccountClickedProperties,
  ReviewSelfAssignRequestedProperties,
  SampleProjectRequestedProperties,
  SendToCurateRequestedProps,
  SendToLabelRequestedProps,
  SignInClickedProperties,
  SignupStepCompletedProperties,
  StartLabelingClickedProperties,
  StartReviewClickedProperties,
  StorylaneClickedProperties,
  TrainModelEnteredProps,
  UpgradePlanClickedProperties,
  UserReportDownloadedProps,
  UserReportRequestedProps,
} from './types';
import {
  combineFiltersForTracking,
  filterKeysWithValues,
  stringifyFiltersForTracking,
} from './utils';

/**
 * AnalyticsTracker is a wrapper around Segment's analytics.js library.
 *
 *
 */
class AnalyticsTracker {
  // private AnalyticsTracker: SegmentAnalytics.AnalyticsJS | null | undefined;

  /**
   * Track any event.
   * @param eventName Name of the tracked event. (Ex. 'User Signed Up')
   * @param options Options for this track call.
   */
  protected safelyTrack<T extends Record<string, any> & { accountId?: string }>(
    eventName: string,
    properties: T,
  ) {
    // Reference: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
    // Segment library attaches `analytics` (SegmentAnalytics.AnalyticsJS) to the window object.
    if (window && window?.analytics) {
      try {
        window.analytics.track(eventName, properties);
      } catch (error) {
        console.error('AnalyticsTracker: Error tracking event:', error);
      }
      return;
    }
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `AnalyticsTracker: Unable to track event '${eventName}'. Analytics instance is null.`,
      );
    }
  }

  /**
   * Reset (e.g. on logout) all analytics state for the current user and group.
   */
  public reset() {
    if (!window.analytics) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          'AnalyticsTracker: Unable to reset segment analytics. Analytics instance is null.',
        );
      }
      return;
    }
    window.analytics.reset();
  }

  public async flush() {
    // IMPLEMENT ME
    // await this.AnalyticsTracker.flush();
  }

  /**
   * Associate the current user with a group.
   * @param groupId The group's ID.
   * @param options Options for this group call.
   */
  // group(groupId: string, options?: SegmentAnalytics.SegmentOpts) {
  //   this.AnalyticsTracker && this.AnalyticsTracker.group(groupId, undefined, options);
  // }

  /** ACCOUNT **/
  /**
   * User submits a contact form on the platform
   */
  contactFormSubmitted(properties: ContactFormSubmittedProperties) {
    const eventName = 'Contact Form Submitted';
    this.safelyTrack(eventName, properties);
  }

  /**
   * User clicks 'Contact Us' button on the platform
   */
  contactUsClicked(properties: ContactUsClickedProperties) {
    const eventName = 'Contact Us Clicked';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user clicks a link to Superb AI documentation
   * TODO: track links in Curate & Model
   */
  docsLinkClicked(properties: DocsLinkClickedProperties) {
    const eventName = 'Docs Link Clicked';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user adds an integration to their account
   */
  integrationAdded(properties: IntegrationAddedProperties) {
    const eventName = 'Integration Added';
    this.safelyTrack(eventName, properties);
  }

  /**
   * Create new Personal Access Key from account settings > Access
   */
  personalAccessKeyCreated(properties: PersonalAccessKeyCreatedProperties) {
    const eventName = 'Personal Access Key Created';
    this.safelyTrack(eventName, properties);
  }

  /**
   */
  returnToAccountClicked(properties: ReturnToAccountClickedProperties) {
    const eventName = 'Return to Account Clicked';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user clicks sign in
   */
  signInClicked(properties: SignInClickedProperties) {
    const eventName = 'Sign In Clicked';
    this.safelyTrack(eventName, properties);
  }

  /**
   * [Critical] When a user completes a step in the sign up process
   */
  signupStepCompleted(properties: SignupStepCompletedProperties) {
    const eventName = 'Signup Step Completed';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user clicks 'Change Plan' with intent to upgrade their account
   * Upon click, page is routed to Contact Sales in webpage
   */
  upgradePlanClicked(properties: UpgradePlanClickedProperties) {
    const eventName = 'Upgrade Plan Clicked';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user downloads a chart
   */
  chartDownloaded(properties: ChartDownloadedProperties) {
    const eventName = 'Chart Downloaded';
    this.safelyTrack(eventName, properties);
  }

  chartClicked(properties: ChartClickedProperties) {
    const eventName = 'Chart Clicked';
    this.safelyTrack(eventName, properties);
  }

  storylaneClicked(properties: StorylaneClickedProperties) {
    const eventName = 'Storylane Clicked';
    this.safelyTrack(eventName, properties);
  }

  helphubClicked(properties: HelpHubClickedProperties) {
    const eventName = 'Helphub Clicked';
    this.safelyTrack(eventName, properties);
  }

  /** LABEL product **/
  /**
   * Click 'Save' on the Auto Label Mapping dialog
   */
  autoLabelConfigured(properties: AutoLabelConfiguredProperties) {
    const eventName = 'Auto Label Configured';
    this.safelyTrack(eventName, properties);
  }

  /**
   * [CRITICAL] User requests labels to auto-label
   */
  autoLabelInitiated(properties: AutoLabelInitiatedProperties) {
    const eventName = 'Auto Label Initiated';
    this.safelyTrack(eventName, properties);
  }

  /**
   * User clicks 'Cancel' on the Custom Auto Label AI dialog
   */
  createCustomAutoLabelAiCanceled(properties: CustomAutoLabelRequestCanceledProperties) {
    const eventName = 'Create Custom Auto Label Canceled';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When user creates a CAL based on an export
   */
  createCustomAutoLabelAiInitiated(properties: CreateCustomAutoLabelAIInitiatedProperties) {
    const eventName = 'Create Custom Auto Label AI Initiated';
    this.safelyTrack(eventName, properties);
  }

  /**
   * TODO: how is this different from Create Project????
   */
  createProjectButtonClicked(properties: CreateProjectButtonClickedProperties) {
    const eventName = 'Create Project Button Clicked';
    this.safelyTrack(eventName, properties);
  }

  /**
   * Apply button on Custom Auto-Label tab
   */
  customAutoLabelAiApplied(properties: CustomAutoLabelAIAppliedProperties) {
    const eventName = 'Custom Auto-Label AI Applied';
    this.safelyTrack(eventName, properties);
  }

  /**
   * [Critical] When a user requests to download a label Export
   */
  labelExportDownloaded(properties: LabelExportDownloadedProperties) {
    const eventName = 'Label Export Downloaded';
    this.safelyTrack(eventName, properties);
  }

  /**
   * [Closed Label feature] When a user request Export Comparison Report
   */
  exportCompareReportRequested(properties: ExportCompareReportGenerated) {
    const eventName = 'Export Compare Report Generated';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user clicks label to create issue thread from Label Editor
   */
  issueCreated(properties: AccountId) {
    const eventName = 'Issue Created';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user re-opens a previously closed issue
   */
  issueReopened(properties: AccountId) {
    const eventName = 'Issue Reopened';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user issue is resolved
   */
  issueResolved(properties: AccountId) {
    const eventName = 'Issue Resolved';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user sends invites to other yet-to-be members
   */
  invitationsSent(properties: InvitationsSentProperties) {
    const eventName = 'Invitations Sent';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user requests a label export (e.g. from label list)
   */
  labelExportRequested(properties: LabelExportRequestedProperties) {
    const eventName = 'Label Export Requested';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user applied filter in label list
   */
  labelListFiltered(properties: LabelListFilteredProperties) {
    const eventName = 'Label List Filtered';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user clicks "Assign New Label" in the annotation app
   */
  labelSelfAssignRequested(properties: LabelSelfAssignRequestedProperties) {
    const eventName = 'Label Self Assign Requested';
    this.safelyTrack(eventName, properties);
  }

  /**
   * User skips label in Label Editor
   *
   * TODO: check role. don't send event for collaborator
   */
  labelSkipped(properties: LabelSkippedProperties) {
    const eventName = 'Label Skipped';
    this.safelyTrack(eventName, properties);
  }

  /**
   * User submits label in editor
   *
   * TODO: check role. don't send event for collaborator
   */
  labelSubmitted(properties: LabelSubmittedProperties) {
    const eventName = 'Label Submitted';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user assigns labelers to labels
   */
  membersAssigned(properties: MembersAssignedProps) {
    const eventName = 'Members Assigned';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user (reviewer) Approves or Rejects a label from label viewer
   */
  labelReviewed(properties: LabelReviewedProps) {
    const eventName = 'Label Reviewed';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user uploads data on label platform
   *
   * @properties {LabelDataUploadRequestedProperties}
   */
  labelDataUploadRequested(properties: LabelDataUploadRequestedProperties) {
    const eventName = 'Label Data Upload Requested';
    const { uploadType, dataType, cloudStorage, cloudSaveMethod, ...otherProperties } = properties;
    this.safelyTrack(eventName, {
      ...otherProperties,
      uploadType: lowerCase(uploadType),
      dataType: dataType ? lowerCase(dataType) : '',
      ...(cloudStorage && {
        useSuperbAIStorage: Boolean(cloudSaveMethod === 'copy'),
        cloudStorage: cloudStorage,
      }),
    });
  }

  /**
   * When a project is created succesfully
   */
  projectCreated(properties: ProjectCreatedProperties) {
    const eventName = 'Project Created';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user clicks self-assign to self-assigns a review
   */
  reviewSelfAssignRequested(properties: ReviewSelfAssignRequestedProperties) {
    const eventName = 'Review Self-Assign Requested';
    this.safelyTrack(eventName, properties);
  }

  /**
   * Create Sample Project from sample project dialog after sign up.
   */
  sampleProjectRequested(properties: SampleProjectRequestedProperties) {
    const eventName = 'Sample Project Requested';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user clicks 'Label' button in the project menu
   */
  startLabelingClicked(properties: StartLabelingClickedProperties) {
    const eventName = 'Start Labeling Clicked';
    this.safelyTrack(eventName, properties);
  }

  /**
   * When a user clicks 'Review' button in the project menu
   */
  startReviewClicked(properties: StartReviewClickedProperties) {
    const eventName = 'Start Review Clicked';
    this.safelyTrack(eventName, properties);
  }

  /**
   * [Label] When a user opens upload data dialog to initiate data upload
   */
  labelUploadDialogOpened(properties: LabelUploadDialogOpenedProperties) {
    const eventName = 'Label Upload Dialog Opened';
    this.safelyTrack(eventName, properties);
  }

  /**
   * User requests a user report
   */
  userReportRequested(properties: UserReportRequestedProps) {
    const eventName = 'User Report Requested';
    this.safelyTrack(eventName, properties);
  }

  /**
   * User downloads a user report
   */
  userReportDownloaded(properties: UserReportDownloadedProps) {
    const eventName = 'User Report Downloaded';
    this.safelyTrack(eventName, properties);
  }

  /** Curate Product **/
  autoCurateRequested(properties: AutoCurateRequestedProps) {
    const eventName = 'Auto Curate Requested';
    const { appliedFilters, curationType, embeddingType, queryString, ...otherProperties } =
      properties;
    this.safelyTrack(eventName, {
      ...otherProperties,
      ...(queryString && { queryString }),
      embeddingType: embeddingType.toLowerCase(),
      curationType: formatCurationType(curationType),
      filterBy: appliedFilters ? filterKeysWithValues(appliedFilters) : undefined,
    });
  }

  autoCurateReportDownloaded(properties: AutoCurateReportDownloadedProps) {
    const eventName = 'Auto Curate Report Downloaded';
    this.safelyTrack(eventName, properties);
  }

  _dataSliceCreatedOrUpdated(properties: DataSliceCreatedProps, eventName: string) {
    const { filters, queryString, viewType, ...otherProperties } = properties;
    const readableViewType = viewType === '2d' ? 'scatter' : viewType;
    this.safelyTrack(eventName, {
      viewType: readableViewType,
      ...(filters && {
        filterBy: filterKeysWithValues(filters),
        filterString: stringifyFiltersForTracking(filters),
      }),
      ...(queryString && { queryString }),
      ...otherProperties,
    });
  }

  dataSliceCreated(properties: DataSliceCreatedProps) {
    const eventName = 'Data Slice Created';
    this._dataSliceCreatedOrUpdated(properties, eventName);
  }

  dataSliceUpdated(properties: DataSliceCreatedProps) {
    const eventName = 'Data Slice Updated';
    this._dataSliceCreatedOrUpdated(properties, eventName);
  }

  sendToCurateRequested(properties: SendToCurateRequestedProps) {
    const eventName = 'Send to Curate Requested';
    this.safelyTrack(eventName, properties);
  }

  sendToLabelRequested(properties: SendToLabelRequestedProps) {
    const eventName = 'Send to Label Requested';
    this.safelyTrack(eventName, properties);
  }

  curateDataUploadRequested(properties: CurateDataUploadRequestedProps) {
    const eventName = 'Curate Data Upload Requested';
    this.safelyTrack(eventName, properties);
  }

  curateDataCompared(properties: CurateDataComparedProps) {
    const eventName = 'Curate Data Compared';
    const { appliedFilters, queryString, sliceId, ...otherProperties } = properties;
    const filters = combineFiltersForTracking(appliedFilters, sliceId, undefined);
    this.safelyTrack(eventName, {
      ...otherProperties,
      ...(queryString && { queryString }),
      filterBy: filterKeysWithValues(filters),
    });
  }

  queryBuilderApplied(properties: QueryBuilderAppliedProps) {
    const eventName = 'Query Builder Applied';
    this.safelyTrack(eventName, properties);
  }

  /** Model Product **/
  diagnoseModelButtonClicked(properties: DiagnoseModelButtonClicked) {
    const eventName = 'Diagnose Model Button Clicked';
    this.safelyTrack(eventName, properties);
  }

  generateSyntheticImagesRequested(properties: { accountId: string; datasetId: string }) {
    const eventName = 'Generate Synthetic Images Requested';
    this.safelyTrack(eventName, properties);
  }

  prepareDownloadRequested(properties: { accountId: string; datasetId: string; sliceId?: string }) {
    const eventName = 'Prepare Download Requested';
    this.safelyTrack(eventName, properties);
  }

  modelTrainingStepCompleted(properties: ModelTrainingStepCompletedProps) {
    const eventName = 'Model Training Step Completed';
    this.safelyTrack(eventName, {
      ...properties,
    });
  }

  modelTrainingRequested(properties: ModelTrainingRequestedProps) {
    const eventName = 'Model Training Requested';
    this.safelyTrack(eventName, properties);
  }

  overviewLinkClicked(properties: OverviewLinkClickedProps) {
    const eventName = 'Overview Link Clicked';
    this.safelyTrack(eventName, {
      ...properties,
    });
  }

  trainModelEntered(properties: TrainModelEnteredProps) {
    const eventName = 'Train Model Entered';
    this.safelyTrack(eventName, {
      ...properties,
    });
  }

  modelDetailClicked(properties: ModelDetailClickedProps) {
    const eventName = 'Model Detail Clicked';
    this.safelyTrack(eventName, {
      ...properties,
    });
  }

  modelCancelCompleteRequested(properties: ModelCancelCompleteRequestedProps) {
    const eventName = 'Model Cancel or Early Completed Requested';
    this.safelyTrack(eventName, {
      ...properties,
    });
  }
}

export default new AnalyticsTracker();
