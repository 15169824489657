import * as React from "react";
import { forwardRef } from "react";
const SvgSyncProject = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m3.795 8.652-.01.028a.99.99 0 0 0-.05.258c0 .01-.003.02-.006.029-.003.01-.006.022-.006.033v11c0 1.103.897 2 2 2h3.965a8.548 8.548 0 0 1-1.475-2H5.72l.002-10h5a1 1 0 0 0 1-1V4h6v3.713a8.462 8.462 0 0 1 2 .755V4c0-1.103-.897-2-2-2h-7c-.012 0-.023.003-.034.006a.147.147 0 0 1-.028.007.991.991 0 0 0-.26.051l-.03.01c-.021.007-.043.013-.064.023a.997.997 0 0 0-.293.196l-6 6a.997.997 0 0 0-.195.293c-.01.022-.017.044-.024.066ZM9.723 8H7.137l2.586-2.586V8Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.723 18.892a2.893 2.893 0 0 0 2.827-2.283h2.027a4.854 4.854 0 0 1-.134.68 4.894 4.894 0 0 1-8.172 2.178l-1.412 1.41V16.61h4.271l-1.444 1.443a2.883 2.883 0 0 0 2.037.84Zm0-5.784a2.893 2.893 0 0 0-2.828 2.283h-2.027a4.893 4.893 0 0 1 8.32-2.844l1.427-1.425v4.268h-4.271l1.43-1.429a2.883 2.883 0 0 0-2.051-.853Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSyncProject);
export default ForwardRef;
