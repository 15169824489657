import jwtDecode from 'jwt-decode';
import { concat, lowerCase, reduce } from 'lodash';

import { MemberData } from '../types/memberTypes';
import RegexUtils from './RegexUtils';

export interface UserObject {
  [key: string]: any;
  email: string;
  role?: string;
}

export type UserProfile = Pick<
  MemberData,
  'email' | 'name' | 'status' | 'role' | 'avatarUrl' | 'createdAt' | 'lastLoginedAt' | 'projectUrl'
>;
export type UserProfileObject = Record<string, UserProfile>;

type UserRoles = 'owners' | 'admins' | 'managers' | 'reviewers' | 'workers' | 'collaborators';

const createUserObjects = (
  userObject: Partial<Record<UserRoles, UserObject[]>>,
): Record<string, UserObject> => {
  const {
    owners = [],
    admins = [],
    managers = [],
    reviewers = [],
    workers = [],
    collaborators = [],
  } = userObject;
  return Object.fromEntries([
    ...owners.map(u => [u.email, { ...u, role: 'owner' }]),
    ...admins.map(u => [u.email, { ...u, role: 'admin' }]),
    ...managers.map(u => [u.email, { ...u, role: 'manager' }]),
    ...reviewers.map(u => [u.email, { ...u, role: 'reviewer' }]),
    ...workers.map(u => [u.email, { ...u, role: 'worker' }]),
    ...collaborators.map(u => [u.email, { ...u, role: 'collaborator' }]),
  ]);
};

const getUserObjectForComment = (
  userObjects: Record<string, UserObject>,
  userId: string,
): UserObject => {
  const user = userObjects[userId];
  if (user) {
    return user;
  }
  return { name: userId, email: userId };
};

type UserTokenInfoKeys =
  | 'accountName'
  | 'tier'
  | 'role'
  | 'name'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'userId';
export type UserTokenInfo = Record<UserTokenInfoKeys, string>;

function getUserTokenInfo(idToken: string): UserTokenInfo;
function getUserTokenInfo(idToken: string | null): UserTokenInfo | Record<never, unknown> {
  if (!idToken) return {};

  const decodedToken = jwtDecode<Record<string, string>>(idToken);
  return {
    accountName: decodedToken['custom:tenant_name'],
    tier: lowerCase(decodedToken['custom:tier']),
    role: lowerCase(decodedToken['custom:tenant_role']),
    name: `${decodedToken.given_name} ${decodedToken.family_name}`,
    firstName: decodedToken.given_name,
    lastName: decodedToken.family_name,
    email: decodedToken.email,
    userId: `${decodedToken['custom:tenant_name']}&${decodedToken.email}`,
  };
}

const isSuperbEmail = (email: string): boolean => {
  if (RegexUtils.IS_EMAIL(email)) {
    return email.split('@')[1] === 'superb-ai.com';
  }
  return false;
};

const convertUserRole = (userRole: string): string => {
  const WORKER = 'worker';
  const LABELER = 'labeler';
  if (userRole === LABELER) return WORKER;
  if (userRole === WORKER) return LABELER;
  return userRole;
};

interface UserWithRole {
  projectRole?: string;
  tenantRole?: string;
}
function filterByRole<T extends UserWithRole>(users: T[], role: string | null): T[] {
  if (!role) return users;
  const roleUppercase = role.toUpperCase();
  return users.filter(
    user =>
      user.projectRole?.toUpperCase() === roleUppercase ||
      user.tenantRole?.toUpperCase() === roleUppercase,
  );
}

/**
 * @param projectInfo From project context
 * @returns Mapping of labeler (all roles) email to names
 */
const getActiveLabelers = (projectInfo: Record<string, any>): Record<string, string> => {
  return reduce(
    concat(projectInfo.admins, projectInfo.workers, projectInfo.owners, projectInfo.managers),
    (agg, labeler) => {
      if (labeler.status !== 'Active') return agg;
      return { ...agg, [labeler.email]: labeler?.name || labeler.email };
    },
    { '': 'No Assignee' } as Record<string, string>,
  );
};

export default {
  createUserObjects,
  getUserObjectForComment,
  getUserTokenInfo,
  isSuperbEmail,
  convertUserRole,
  filterByRole,
  getActiveLabelers,
};
