import * as React from "react";
import { forwardRef } from "react";
const SvgSubLeft = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M20 5h-2v8H7.83l2.58-2.59L9 9l-5 5 5 5 1.41-1.41L7.83 15H20V5Z" })
);
const ForwardRef = forwardRef(SvgSubLeft);
export default ForwardRef;
