import { jsx, jsxs } from 'react/jsx-runtime';
import { Fragment } from 'react';
import { useRadioState, RadioGroup as RadioGroup$1, Radio } from 'reakit/Radio';
import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import '@react-aria/utils';
import '@superb-ai/icons';
import '../../contrib/Chip/Chip.js';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import '../Button/Button.js';
import '../Button/IconButton.js';
import '../Button/IconButtonWithTriangle.js';
import '../Button/styles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './../Dropdown/styles.css.ts.vanilla.css';
import '../Input/Input.js';
import '../Select/styles.css.vanilla.js';
import '../Checkbox/Checkbox.js';
import { Label } from '../Label/Label.js';
import { Radio as Radio$1 } from './Radio.js';
import '@vanilla-extract/dynamic';
import 'classnames';
import '../../theme/color-util.js';
import '../../theme/sprinkles.css.vanilla.js';
import '../Range/Range.js';
import '../Range/styles.css.vanilla.js';
import 'reakit/Composite';
import '../SegmentedControl/styles.css.vanilla.js';
import 'rc-overflow';
import '../ToggleButton/ToggleButton.js';
import '../../modal/Dialog/Dialog.js';
import '../../modal/Dialog/contexts.js';
import 'reakit/Popover';
import { Tooltip } from '../../modal/Tooltip/Tooltip.js';

const defaultBoxProps = {
  display: "grid",
  gap: 1
};
function RadioGroup(props) {
  const { options, value, onChangeValue, labelProps, ...boxProps } = props;
  const radio = useRadioState({ state: value });
  return /* @__PURE__ */ jsx(RadioGroup$1, { ...radio, children: (radioGroupProps) => /* @__PURE__ */ jsx(Box, { ...radioGroupProps, ...defaultBoxProps, ...boxProps, children: options.map((option) => {
    return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(Tooltip, { content: option.tooltip, strategy: "fixed", hideOnEmptyContent: true, children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(Radio, { ...radio, children: (radioProps) => /* @__PURE__ */ jsxs(Label, { ...labelProps, ...option.disabled ? { color: "gray-300" } : {}, children: [
      /* @__PURE__ */ jsx(
        Radio$1,
        {
          ...radioProps,
          disabled: option.disabled,
          value: value === option.value,
          onClick: (e) => {
            onChangeValue?.(option.value);
            props.onClick?.(e);
          }
        }
      ),
      option.label
    ] }) }) }) }) }, `${option.value}`);
  }) }) });
}

export { RadioGroup };
