import * as React from "react";
import { forwardRef } from "react";
const SvgCurveSendTo = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.636 11.485 17.05 12.9l3.536-3.536L22 7.95 17.05 3l-1.414 1.414 2.536 2.536H15.5c-2.703 0-4.855.815-6.332 2.293C7.693 10.717 7 12.742 7 14.95v2h2v-2c0-1.792.557-3.268 1.582-4.293 1.023-1.023 2.62-1.707 4.918-1.707h2.671l-2.535 2.535ZM5 16H3v5h18v-5h-2v3H5v-3Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCurveSendTo);
export default ForwardRef;
