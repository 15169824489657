import * as React from "react";
import { forwardRef } from "react";
const SvgSort = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m18 21-4-4h3V7h-3l4-4 4 4h-3v10h3M2 19v-2h10v2M2 13v-2h7v2M2 7V5h4v2H2z" })
);
const ForwardRef = forwardRef(SvgSort);
export default ForwardRef;
