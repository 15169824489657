import * as React from "react";
import { forwardRef } from "react";
const SvgFolderClear = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m14.121 9.464 1.415 1.415L13.414 13l2.122 2.121-1.415 1.414L12 14.415l-2.121 2.12-1.415-1.414L10.586 13l-2.122-2.121L9.88 9.464 12 11.586l2.121-2.122Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M12.414 5H20c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2h6c.266 0 .52.105.707.293L12.414 5ZM4 7v12h16.002L20 7H4Z"
    }
  )
);
const ForwardRef = forwardRef(SvgFolderClear);
export default ForwardRef;
