import * as React from "react";
import { forwardRef } from "react";
const SvgLabelLogoInverted = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 11.203V17a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H9v8.87l-4-2.667Zm0-1.202 3 2V5H7a2 2 0 0 0-2 2v3.001Z",
      fill: "#FFC0BD"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 4.536v7.465l-3-2-3 2V4.536a1 1 0 0 1 .445-.832l2-1.333a1 1 0 0 1 1.11 0l2 1.333A1 1 0 0 1 8 4.536ZM5 5.14a.835.835 0 1 0 0-1.67.835.835 0 0 0 0 1.67Z",
      fill: "#fff"
    }
  )
);
const ForwardRef = forwardRef(SvgLabelLogoInverted);
export default ForwardRef;
