import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var captionRecipe = createRuntimeFn({ defaultClassName: "eme0mp5", variantClassNames: { side: { top: "eme0mp6", bottom: "eme0mp7" } }, defaultVariants: { side: "top" }, compoundVariants: [] });
var tableRecipe = createRuntimeFn({ defaultClassName: "_1cuzvx5v _1cuzvx57q eme0mp0", variantClassNames: { variant: { simple: "eme0mp1" } }, defaultVariants: {}, compoundVariants: [] });

export { captionRecipe, tableRecipe };
