import * as React from "react";
import { forwardRef } from "react";
const SvgImageSequence = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2ZM8 16V4h12l.002 12H8Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m12.056 11.833-.89-.889L8.5 14.5h10.667l-4.445-6.222-2.666 3.555ZM10.722 9.167a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgImageSequence);
export default ForwardRef;
