import React, { useEffect, useState } from 'react';

import { Clear } from '@superb-ai/icons';
import { Box, IconButton, Label, ToggleButton, Tooltip, Typography } from '@superb-ai/ui';
import flagsmith from 'flagsmith';

import ServiceUtils from '../utils/ServiceUtils';
import StringUtils from '../utils/StringUtils';
import { useAuthInfo } from './AuthContext';
const environmentID = `${process.env.NEXT_PUBLIC_FLAGSMITH_TOKEN}`;

type IFlags = ReturnType<typeof flagsmith.getAllFlags>;

/**
 * Even though flagsmith uses kebab_case, we use camelCase in JS.
 * Flags coming from flagsmith are converted using ServiceUtils.toCamelCaseKeys.
 */
export const FlagDefaults = {
  commandbarHelphubBeta: { enabled: false },
  consensusLabeling: { enabled: false },
  analyticsProxy: { enabled: false },
  overviewProxy: { enabled: false },
  lidar: { enabled: false },
  mfa: { enabled: false },
  calDownload: { enabled: false },
  videoStream: { enabled: false },
  advancedAiTestingFeatures: { enabled: false },
  mislabelDetectionBeta: { enabled: false },
  embeddingStoreBeta: { enabled: false },
  userApiKey: { enabled: false },
  labelsLoki: { enabled: false },
  exportFormats: { enabled: false, value: '' },
  exportsAnalytics: { enabled: false },
  customizeAnalytics: { enabled: false },
  experimentalLidar: { enabled: false },
  usePropertyFilter: { enabled: false },
  usePolygonSnap: { enabled: false },
  pointCloudsAutoLabel: { enabled: false },
  createCustomAutoLabelWithoutLimitation: { enabled: false },
  curateClassification: { enabled: false },
  focalClick: { enabled: false },
  modelDiagnosis: { enabled: false },
  modelPlatform: { enabled: false },
  appsPlatform: { enabled: false },
  ipWhitelist: { enabled: false },
  semanticSearchDemo: { enabled: false },
};

export type Flag = keyof typeof FlagDefaults;
export type FeatureFlags = Record<Flag, { enabled: boolean; value?: string }>;

interface ContextProps {
  flags: Partial<FeatureFlags>;
  getFlag: (flag: Flag) => FeatureFlags[typeof flag];
  updateFlags: (flag: IFlags) => void;
}

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const [flags, setFlags] = useState<Partial<FeatureFlags>>({});
  const authInfo = useAuthInfo();

  const getFlag = (flag: Flag): FeatureFlags[typeof flag] => {
    return flags[flag] ?? FlagDefaults[flag];
  };

  const updateFlags = (flags: IFlags) => {
    setFlags(ServiceUtils.toCamelCaseKeys(flags));
  };

  useEffect(() => {
    let mounted = true;

    flagsmith.init({
      environmentID,
      onChange: async () => {
        const flags = await flagsmith.getAllFlags();
        if (flags && mounted) {
          updateFlags(flags);
        }
      },
      cacheFlags: true,
      enableLogs: process.env.NODE_ENV !== 'production',
      preventFetch: true,
      defaultFlags: {
        ...FlagDefaults,
      },
    });
    const refreshTime = 60 * 60 * 1000;
    const debugRefreshTime = 60 * 1000;
    const flagRefreshTime = process.env.NODE_ENV === 'production' ? refreshTime : debugRefreshTime;
    flagsmith.startListening(flagRefreshTime);
    return () => {
      mounted = false;
      flagsmith.stopListening();
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (authInfo.email) {
      const identifier = authInfo.email;
      flagsmith
        .identify(identifier, {
          role: authInfo.role ?? '',
          accountId: authInfo.accountName ?? '',
          plan: authInfo.tier ?? '',
          email: authInfo.email,
        })
        .then(() => {
          if (mounted) {
            flagsmith.getFlags();
          }
        });
    }
    return () => {
      mounted = false;
    };
  }, [authInfo.email, authInfo.role, authInfo.accountName, authInfo.tier]);

  return {
    flags,
    getFlag,
    updateFlags,
  };
};

export const useFeatureFlags = (): ContextProps => {
  return React.useContext(Context);
};

export const FeatureFlagProvider: React.FC = ({ children }) => {
  const provider = useProvider();
  return (
    <Context.Provider value={provider}>
      <FeatureFlagDevTools />
      {children}
    </Context.Provider>
  );
};

export const useFeatureFlag = (flag: Flag): boolean => {
  const { getFlag } = useFeatureFlags();
  return getFlag(flag).enabled;
};

export function getFeatureFlag(
  key: keyof FeatureFlags,
): ReturnType<(typeof flagsmith)['getAllFlags']>[string] {
  let flag = flagsmith.getAllFlags()[StringUtils.snakeCase(key)];
  if (!flag) {
    flag = FlagDefaults[key];
  }
  if (!flag) {
    throw new Error(`flag '${key}' not found`);
  }
  return flag;
}

export function FeatureFlagDevTools() {
  const { flags, updateFlags } = useFeatureFlags();
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'f') {
        setIsVisible(visible => !visible);
      }
    };

    window.addEventListener('keydown', onKeydown);

    return () => window.removeEventListener('keydown', onKeydown);
  }, []);

  if (process.env.APP_ENV === 'local' && isVisible) {
    return (
      <>
        <Box
          position="fixed"
          borderRadius="100%"
          backgroundColor="gray-500"
          style={{
            bottom: '12px',
            left: '160px',
            width: '30px',
            height: '30px',
            zIndex: 9999,
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={() => setIsOpen(true)}
        >
          <Tooltip
            content="Feature Flag Devtools (You can toggle by pressing 'ctrl + f')"
            strategy="fixed"
            placement="top"
          >
            <Typography variant="m-strong" color="white">
              FF
            </Typography>
          </Tooltip>
        </Box>
        {isOpen && (
          <>
            <Box
              position="fixed"
              borderRadius="2px"
              backgroundColor="gray"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              border="1px solid"
              borderColor="gray-500"
              style={{ bottom: '12px', left: '160px', zIndex: 9999 }}
            >
              <Box ml="auto">
                <IconButton
                  icon={Clear}
                  size="s"
                  variant="text"
                  color="white"
                  onClick={() => setIsOpen(false)}
                />
              </Box>
              {Object.entries(flags).map(([key, value]) => (
                <Box
                  display="grid"
                  gap={1}
                  key={key}
                  borderTop="1px solid"
                  borderColor="gray-500"
                  p={0.5}
                  width="100%"
                  backgroundColor={{ hover: 'gray-opacity-200' }}
                >
                  <Label variant="s-regular" color="white" mr={1}>
                    <ToggleButton
                      size="xs"
                      checked={value.enabled}
                      onClick={() =>
                        updateFlags({ ...flags, [key]: { ...value, enabled: !value.enabled } })
                      }
                    />
                    {key}
                  </Label>
                </Box>
              ))}
            </Box>
          </>
        )}
      </>
    );
  }

  return null;
}
