import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var typoRecipe = createRuntimeFn({ defaultClassName: "wbddfs0", variantClassNames: { variant: { h1: "wbddfs1", h2: "wbddfs2", h3: "wbddfs3", h1Capsize: "wbddfs4", h2Capsize: "wbddfs5", h3Capsize: "wbddfs6", "l-strong": "wbddfs7", "l-regular": "wbddfs8", "m-strong": "wbddfs9", "m-medium": "wbddfsa", "m-regular": "wbddfsb", "s-strong": "wbddfsc", "s-regular": "wbddfsd" }, underline: { true: "wbddfss" } }, defaultVariants: {}, compoundVariants: [] });
var typoVariants = { h1: "wbddfs1", h2: "wbddfs2", h3: "wbddfs3", h1Capsize: "wbddfs4", h2Capsize: "wbddfs5", h3Capsize: "wbddfs6", "l-strong": "wbddfs7", "l-regular": "wbddfs8", "m-strong": "wbddfs9", "m-medium": "wbddfsa", "m-regular": "wbddfsb", "s-strong": "wbddfsc", "s-regular": "wbddfsd" };

export { typoRecipe, typoVariants };
