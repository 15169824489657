import * as React from "react";
import { forwardRef } from "react";
const SvgBrushUnion = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.25 10h-1.5V7.25H3v-1.5h2.75V3h1.5v2.75H10v1.5H7.25V10Zm6.956 4.964 6.508-8.778c.57-.762.46-1.836-.255-2.466a1.816 1.816 0 0 0-2.472.065l-7.84 7.604c.94.07 1.863.43 2.626 1.102a4.487 4.487 0 0 1 1.433 2.473Zm-3.488 4.533c-1.965 1.008-5.361 1.273-7.8-.874.217-.077.431-.141.642-.204 1.086-.324 2.055-.613 2.448-2.505a3.365 3.365 0 0 1 .802-1.733 3.364 3.364 0 0 1 4.755-.308 3.385 3.385 0 0 1-.847 5.624Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgBrushUnion);
export default ForwardRef;
