import * as React from "react";
import { forwardRef } from "react";
const SvgChevronShrink = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M15 4v5h5v2h-7l.008-7H15ZM9 20v-5H4v-2h7l-.008 7H9Z" })
);
const ForwardRef = forwardRef(SvgChevronShrink);
export default ForwardRef;
