import * as React from "react";
import { forwardRef } from "react";
const SvgPinFilled = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M1.308 5.374a.625.625 0 0 0 .884.884l.742-.743 1.819 2.222c-.032.15-.054.3-.066.45-.052.68.11 1.323.458 1.84.154.229.476.227.67.032L7.585 8.29l2.121 2.121a.5.5 0 0 0 .707-.707L8.291 7.584l1.768-1.768c.195-.195.197-.517-.032-.67-.517-.348-1.16-.511-1.84-.459-.15.012-.3.034-.45.066L5.515 2.934l.743-.742a.625.625 0 0 0-.884-.884L1.308 5.374Z",
      fill: "#5A7BFF"
    }
  )
);
const ForwardRef = forwardRef(SvgPinFilled);
export default ForwardRef;
