import * as React from "react";
import { forwardRef } from "react";
const SvgLoadingSpinnerSmall = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    stroke: "currentColor",
    width: "1em",
    viewBox: "0 0 66 66",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "g",
    {
      style: {
        transformOrigin: "center",
        animation: "rotator 10s linear infinite"
      }
    },
    /* @__PURE__ */ React.createElement(
      "g",
      {
        style: {
          transformOrigin: "center",
          animation: "rotator 1.4s linear infinite"
        }
      },
      /* @__PURE__ */ React.createElement(
        "circle",
        {
          fill: "none",
          strokeWidth: 5.5,
          cx: 33,
          cy: 33,
          r: 22,
          style: {
            strokeDasharray: 187,
            strokeDashoffset: 0,
            transformOrigin: "center",
            animation: "dash 1.4s ease-in-out infinite"
          }
        }
      )
    )
  ),
  /* @__PURE__ */ React.createElement("style", null, "@keyframes rotator{0%{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes dash{0%{stroke-dashoffset:187}50%{stroke-dashoffset:46.75;transform:rotate(135deg)}to{stroke-dashoffset:187;transform:rotate(450deg)}}")
);
const ForwardRef = forwardRef(SvgLoadingSpinnerSmall);
export default ForwardRef;
