export { namedColors } from './color-util.js';
import { vars } from './theme.css.vanilla.js';

const colorsWithOpacity = ["gray", "primary", "secondary"];
function hasOpacityColor(color) {
  return colorsWithOpacity.includes(color);
}
function getOpacityColor(color, fallback = "gray") {
  return hasOpacityColor(color) ? `${color}-opacity` : `${fallback}-opacity`;
}
function getColor(color, weight, overrideWeight) {
  const weightSpecifier = color.indexOf("-");
  const namedColor = !overrideWeight || weightSpecifier === -1 ? color : color.slice(0, weightSpecifier);
  if (weight && `${namedColor}-${weight}` in vars.color) {
    return vars.color[`${namedColor}-${weight}`];
  }
  if (color in vars.color) {
    return vars.color[color];
  }
  if (color && color.startsWith("#")) {
    return color;
  }
  throw new Error("undefined color");
}

export { colorsWithOpacity, getColor, getOpacityColor, hasOpacityColor };
