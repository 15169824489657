import * as React from "react";
import { forwardRef } from "react";
const SvgCursor = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M16.388 14.285H12.74l1.92 4.25c.133.295-.02.625-.325.75l-1.69.67a.63.63 0 0 1-.802-.304l-1.824-4.035L7.04 18.4c-.396.371-1.04.085-1.04-.402V4.572c0-.513.685-.763 1.04-.402l9.777 9.142c.395.35.104.973-.43.973Z" })
);
const ForwardRef = forwardRef(SvgCursor);
export default ForwardRef;
