import * as React from "react";
import { forwardRef } from "react";
const SvgCloseBig = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M9.5 3.205 8.795 2.5 6 5.295 3.205 2.5l-.705.705L5.295 6 2.5 8.795l.705.705L6 6.705 8.795 9.5l.705-.705L6.705 6 9.5 3.205Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCloseBig);
export default ForwardRef;
