import * as React from "react";
import { forwardRef } from "react";
const SvgDiagnosis = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m10.842 1.48 3.686 13.76 2.377-8.363L18.336 11H22v2h-4.756l-2.772 9.76-3.814-14.24-2.961 9.11L5.382 13H2v-2h4.618l.685 1.37 3.54-10.89Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgDiagnosis);
export default ForwardRef;
