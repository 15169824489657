import * as React from "react";
import { forwardRef } from "react";
const SvgTransfer = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m15 12 5-4-5-4v2.999H2v2h13V12Zm7 3H9v-3l-5 4 5 4v-3h13v-2Z" })
);
const ForwardRef = forwardRef(SvgTransfer);
export default ForwardRef;
