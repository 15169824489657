import * as React from "react";
import { forwardRef } from "react";
const SvgUnavailableSmall = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16.174 12a4 4 0 0 1-6.032 3.446l5.478-5.478c.352.596.554 1.29.554 2.032Zm-7.446 2.032 5.478-5.478a4 4 0 0 0-5.478 5.478ZM18.174 12a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgUnavailableSmall);
export default ForwardRef;
