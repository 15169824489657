import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import { CheckboxMixed, CheckboxChecked, Checkbox as Checkbox$1 } from '@superb-ai/icons';
import { Icon } from '../../foundation/Icon/Icon.js';
import { checkboxRecipe } from './styles.css.vanilla.js';

const Checkbox = forwardRef(function Checkbox2({ value, onClick, ...props }, ref) {
  const icon = value === "mixed" ? CheckboxMixed : value ? CheckboxChecked : Checkbox$1;
  function handleOnClick(e) {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }
  }
  return /* @__PURE__ */ jsx(
    "button",
    {
      ...mergeProps(props, {
        role: "checkbox",
        "aria-checked": value,
        className: checkboxRecipe({ checked: !!value, disabled: !!props.disabled }),
        ref
      }),
      onClick: handleOnClick,
      children: /* @__PURE__ */ jsx(Icon, { icon, size: "16px" })
    }
  );
});

export { Checkbox };
