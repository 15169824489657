import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonAutoEditOld = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 17.25v-2h2v2H0Zm0-3.5h2v-2H0v2Zm5.25-2h-2v2h2v-2ZM0 22v2h2v-2H0Zm10.25 2h2v-2h-2v2Zm2-5.25h-2v2h2v-2ZM8.75 24v-2h-2v2h2Zm-3.5 0v-2h-2v2h2ZM0 20.75h2v-2H0v2ZM7.653 19.509a1.677 1.677 0 0 1-2.371 0l-.79-.79a1.677 1.677 0 0 1 0-2.372L16.346 4.491a1.677 1.677 0 0 1 2.371 0l.79.79a1.677 1.677 0 0 1 0 2.372L7.654 19.509Zm5.86-10.603c.168.08.325.189.463.328l.79.79c.139.139.248.295.328.463l3.624-3.625a.559.559 0 0 0 0-.79l-.79-.79a.559.559 0 0 0-.79 0l-3.625 3.624Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M19.5 17.5c-1.313.625-1.313.625-1.75 2.5-.438-1.875-.438-1.875-1.75-2.5 1.313-.625 1.313-.625 1.75-2.5.438 1.875.438 1.875 1.75 2.5ZM8.5 5.5c-1.313.625-1.313.625-1.75 2.5C6.312 6.125 6.312 6.125 5 5.5c1.313-.625 1.313-.625 1.75-2.5.438 1.875.438 1.875 1.75 2.5Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonAutoEditOld);
export default ForwardRef;
