import * as React from "react";
import { forwardRef } from "react";
const SvgAnalytics = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M14.77 14.098a.985.985 0 0 0 1.374 0L21 9.344 19.627 8l-4.17 4.082-2.227-2.18a.985.985 0 0 0-1.374 0L7 14.656 8.373 16l4.17-4.082 2.227 2.18Z" })
);
const ForwardRef = forwardRef(SvgAnalytics);
export default ForwardRef;
