import * as React from "react";
import { forwardRef } from "react";
const SvgDashboard = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { clipRule: "evenodd", d: "M11 9H3V3h8v6Zm10 4h-8V3h8v10ZM3 21h8V11H3v10Zm10 0v-6h8v6h-8Z" })
);
const ForwardRef = forwardRef(SvgDashboard);
export default ForwardRef;
