import * as React from "react";
import { forwardRef } from "react";
const SvgSlider = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M8.667 3.333h6v1.334h-6V3.333ZM1.334 4.667H6V6h1.333V2H6v1.333H1.333v1.334ZM6 11.333h8.667v1.334H6v-1.334Zm6.667-4h2v1.334h-2V7.333ZM11.334 10V6.008H10v1.325H1.334v1.334H10V10h1.334Zm-6.667 4v-4H3.334v1.333h-2v1.334h2V14h1.333Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSlider);
export default ForwardRef;
