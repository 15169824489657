import * as React from "react";
import { forwardRef } from "react";
const SvgCursorMove = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { filter: "url(#cursorMove_svg__a)", fillRule: "evenodd", clipRule: "evenodd" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M14.004 8.997h1.002v1.002h-1.002V8.997Zm1.002 6.011h-1.002v-1.002h1.002v1.002Zm-5.01-5.01H8.996V8.998h1.002v1.002Zm0 5.01H8.996v-1.002h1.002v1.002ZM12.002 4 4 12.004l5.232 5.23 2.769 2.769 7.999-8L12 4Z",
      fill: "#fff"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m11 13.002.007 3.01H9.175l2.813 2.581 2.81-2.58H12.99L13 13h3.018l-.001 1.812L18.58 12l-2.562-2.815v1.833h-3.015V7.994h1.81L12.001 5.42 9.186 7.994H11v3.024H7.992V9.185L5.416 12l2.577 2.813v-1.812H11Z",
      fill: "#000"
    }
  )),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement(
    "filter",
    {
      id: "cursorMove_svg__a",
      x: -2.1,
      y: -1,
      width: 28,
      height: 28,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ React.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ React.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ React.createElement("feOffset", { dx: -0.1, dy: 1 }),
    /* @__PURE__ */ React.createElement("feGaussianBlur", { stdDeviation: 1 }),
    /* @__PURE__ */ React.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.42 0" }),
    /* @__PURE__ */ React.createElement("feBlend", { in2: "BackgroundImageFix", result: "effect1_dropShadow" }),
    /* @__PURE__ */ React.createElement("feBlend", { in: "SourceGraphic", in2: "effect1_dropShadow", result: "shape" })
  ))
);
const ForwardRef = forwardRef(SvgCursorMove);
export default ForwardRef;
