import * as React from "react";
import { forwardRef } from "react";
const SvgMessageCircle = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m8.551 17.828.654.35a6.96 6.96 0 0 0 3.292.822h.003a7 7 0 1 0-7-7v.003a6.96 6.96 0 0 0 .822 3.292l.35.654-.538 2.417 2.417-.538ZM3.5 21l1.058-4.762A9 9 0 0 1 12.5 3a9 9 0 0 1 9 9 9 9 0 0 1-13.238 7.942L3.5 21Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgMessageCircle);
export default ForwardRef;
