import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonBrushSubtract = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 14 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "m8.47 7.976 4.339-5.852a1.218 1.218 0 0 0-.17-1.644 1.21 1.21 0 0 0-1.648.043l-5.227 5.07A2.991 2.991 0 0 1 8.47 7.976Zm-2.325 3.022c-1.31.672-3.574.849-5.2-.583.144-.051.287-.094.428-.136.724-.216 1.37-.408 1.632-1.67A2.242 2.242 0 0 1 6.71 7.25a2.257 2.257 0 0 1-.565 3.749ZM5.666 2.5v-1H1v1h4.666Z"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonBrushSubtract);
export default ForwardRef;
