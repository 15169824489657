import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import { RadioChecked, Radio as Radio$1 } from '@superb-ai/icons';
import { Icon } from '../../foundation/Icon/Icon.js';
import { checkboxRecipe } from '../Checkbox/styles.css.vanilla.js';

const Radio = forwardRef(function Radio2({ value, onClick, ...props }, ref) {
  const icon = value ? RadioChecked : Radio$1;
  function handleOnClick(e) {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }
  }
  return /* @__PURE__ */ jsx(
    "button",
    {
      ...mergeProps(props, {
        role: "radio",
        "aria-checked": !!value,
        className: checkboxRecipe({ checked: !!value }),
        ref
      }),
      onClick: handleOnClick,
      children: /* @__PURE__ */ jsx(Icon, { icon, size: "16px" })
    }
  );
});

export { Radio };
