import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonPenUnion = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M5.25 8h-1.5V5.25H1v-1.5h2.75V1h1.5v2.75H8v1.5H5.25V8Zm8.914 10.385 3.932-7.078.208.207c.2.201.526.201.727 0l1.818-1.818c.201-.2.201-.526 0-.727L15.031 3.15c-.2-.201-.526-.201-.727 0l-1.818 1.818c-.201.2-.201.526 0 .727l.207.208-7.078 3.932c-.256.143-.44.387-.504.673l-2.085 9.235a1.029 1.029 0 0 0 1.23 1.23l9.235-2.085c.286-.065.53-.248.673-.504Zm2.42-8.591-3.771 6.787c-.143.256-.387.44-.673.504l-5.621 1.27 3.585-3.586a1.543 1.543 0 1 0-.873-.873l-3.585 3.585 1.27-5.621c.064-.286.247-.53.503-.673l6.787-3.77 2.377 2.377Z"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonPenUnion);
export default ForwardRef;
