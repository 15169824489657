import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { LinkExternal } from '@superb-ai/icons';
import '../../foundation/Box/Box.js';
import { Icon } from '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { LinkTypography } from '../../foundation/Typography/Typography.js';
import { extendComponent } from '../../util/extend-component.js';

const ExternalLink = extendComponent(LinkTypography, {
  display: "inline-flex",
  alignItems: "center",
  gap: 0.5,
  target: "_blank",
  as: "a",
  variant: "m-regular",
  children: ({ children }) => /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Icon, { icon: LinkExternal }),
    " ",
    children
  ] })
});

export { ExternalLink };
