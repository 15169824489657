import * as React from "react";
import { forwardRef } from "react";
const SvgSearchSmallPlus = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6.117 6.405a2.518 2.518 0 1 0-.712.712L8.288 10 9 9.288 6.117 6.405ZM5.085 3.949a1.51 1.51 0 1 1-2.136 2.136 1.51 1.51 0 0 1 2.136-2.136Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M8.5 3.5v-1h1v1h1v1h-1v1h-1v-1h-1v-1h1Z", fill: "currentColor" })
);
const ForwardRef = forwardRef(SvgSearchSmallPlus);
export default ForwardRef;
