import * as React from "react";
import { forwardRef } from "react";
const SvgRotatedBox = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M11 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM18 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM4 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM11 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.293 7.708a3.012 3.012 0 0 0 1.415-1.415l2.999 3a3.012 3.012 0 0 0-1.415 1.414l-2.999-3Zm3 5.585-3 3a3.012 3.012 0 0 1 1.415 1.414l2.999-3a3.012 3.012 0 0 1-1.415-1.414Zm-8.585 0 2.999 3a3.012 3.012 0 0 0-1.415 1.414l-2.999-3a3.013 3.013 0 0 0 1.415-1.414Zm0-2.586 2.999-3a3.012 3.012 0 0 1-1.415-1.414l-2.999 3a3.012 3.012 0 0 1 1.415 1.414Z"
    }
  )
);
const ForwardRef = forwardRef(SvgRotatedBox);
export default ForwardRef;
