import * as React from "react";
import { forwardRef } from "react";
const SvgReviewReject = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 3h14c1.103 0 2 .897 2 2v10c0 1.103-.897 2-2 2H9a2 2 0 0 0-1.2.4L3 21V5c0-1.103.897-2 2-2Zm3.334 12H19V5H5v12l2.134-1.6a2 2 0 0 1 1.2-.4Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m13.81 7.01 1.208 1.208-1.811 1.81 1.81 1.811-1.206 1.207L12 11.236l-1.81 1.81-1.208-1.207 1.81-1.81-1.81-1.811L10.19 7.01 12 8.82l1.812-1.81Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgReviewReject);
export default ForwardRef;
