export function filterKeysWithValues(obj: { [key: string]: any }): string[] {
  return Object.keys(obj).filter(
    key => obj[key] !== undefined && !(Array.isArray(obj[key]) && obj[key].length === 0),
  );
}

function isObjectNotEmpty(obj: object): boolean {
  return Object.keys(obj).length > 0;
}

export const combineFiltersForTracking = (
  appliedFilters: { [key: string]: any } | undefined,
  sliceId?: string | undefined,
  splitIn?: string[] | undefined,
) => {
  const { metadata_in, ...otherFilters } = appliedFilters || {};
  let filters = {} as Record<string, any>;
  if (otherFilters) filters = otherFilters;
  // exclude metadata_in if empty (clutters analysis)
  if (metadata_in && isObjectNotEmpty(metadata_in)) filters['metadata_in'] = metadata_in;
  if (sliceId) filters['slice_in'] = [sliceId];
  if (splitIn) filters['split_in'] = splitIn;
  return filters;
};

export function stringifyFiltersForTracking(filters: { [key: string]: any }): string {
  return Object.entries(filters)
    .filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else if (typeof value === 'object' && value !== null) {
        return Object.values(value).some(v => v !== undefined);
      } else {
        return value !== undefined;
      }
    })
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}=[${value.join(', ')}]`;
      } else if (typeof value === 'object') {
        const rangeDetails = Object.entries(value)
          .map(([prop, val]) => `${prop}: ${val}`)
          .join(', ');
        return `${key}={${rangeDetails}}`;
      } else {
        return `${key}=${value}`;
      }
    })
    .join('&');
}

export const getExpirationDistance = (expirationDate: Date) => {
  const now = new Date();
  const diffInMilliseconds = expirationDate.getTime() - now.getTime();

  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  if (diffInMinutes < 60) {
    return diffInMinutes;
  } else if (diffInHours < 24) {
    return diffInHours;
  } else {
    return diffInDays;
  }
};

export const getExpirationDistanceKey = (expirationDate: Date) => {
  const now = new Date();
  const diffInMilliseconds = expirationDate.getTime() - now.getTime();

  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));

  if (diffInMinutes < 60) {
    return 'commandBar.inMinutes';
  } else if (diffInHours < 24) {
    return 'commandBar.inHours';
  } else {
    return 'commandBar.inDays';
  }
};
