import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonFill = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 14 14",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.695 2.89.924 6.663c-.521.52-.521 1.365 0 1.885l4.242 4.243c.521.52 1.365.52 1.886 0l4.714-4.714L4 .333l-.943.943 1.638 1.615Zm.943.943L1.87 7.6h7.533L5.638 3.833Zm6.695 9.39a1.167 1.167 0 0 0 1.158-1.308c.009-1.026-1.158-2.581-1.158-2.581s-1.158 1.555-1.158 2.582a1.167 1.167 0 0 0 1.158 1.307Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonFill);
export default ForwardRef;
