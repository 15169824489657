import * as React from "react";
import { forwardRef } from "react";
const SvgStopwatch = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M12 5c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm0 14c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M11 9h2v5h-2V9ZM9 2h6v2H9V2ZM19.293 7.707l-2-2 1.414-1.414 2 2-1.414 1.414Z" })
);
const ForwardRef = forwardRef(SvgStopwatch);
export default ForwardRef;
