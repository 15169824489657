import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var comboboxInputRecipe = createRuntimeFn({ defaultClassName: "_1cuzvx51 _1cuzvx5a _1cuzvx57q _1cuzvx5l _1cuzvx5f _1cuzvx56i _1k1zzo82 _1k1zzo8e", variantClassNames: { size: { s: "_1k1zzo8i", m: "_1k1zzo8j", l: "_1k1zzo8k" }, disabled: { true: "_1k1zzo8l" } }, defaultVariants: { size: "m" }, compoundVariants: [] });
var comboboxInputStyle = "_1k1zzo8f";
var inputColor = "var(--_1k1zzo80)";
var inputRecipe = createRuntimeFn({ defaultClassName: "_1cuzvx52o _1cuzvx536 _1cuzvx51 _1cuzvx57q _1cuzvx5l _1cuzvx5f _1cuzvx56i _1k1zzo82", variantClassNames: { size: { s: "_1k1zzo85", m: "_1k1zzo86", l: "_1k1zzo87" }, variant: { text: "_1k1zzo88", stroke: "_1k1zzo89", "soft-fill": "_1k1zzo8a", "strong-fill": "_1k1zzo8b" }, disabled: { true: "_1k1zzo8c" } }, defaultVariants: { variant: "stroke", size: "m" }, compoundVariants: [] });
var inputStyle = "_1k1zzo8d";
var inputTextColor = "var(--_1k1zzo81)";

export { comboboxInputRecipe, comboboxInputStyle, inputColor, inputRecipe, inputStyle, inputTextColor };
