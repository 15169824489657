import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import { typoRecipe } from '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import { extendComponent } from '../../util/extend-component.js';
import { iconBadgeStyle } from './styles.css.vanilla.js';

const IconBadge = extendComponent(Box, {
  backgroundColor: "primary",
  className: [typoRecipe({ variant: "s-regular" }), iconBadgeStyle].join(" ")
});

export { IconBadge };
