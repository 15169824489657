import {
  addDays as addDaysFNS,
  differenceInDays,
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
  formatDuration as formatDurationFns,
  intervalToDuration,
  isBefore,
  parseISO,
  subDays,
} from 'date-fns';

import { formatCount } from './numberFormat';

export const daysAgo = (n: number): Date => subDays(new Date(), n);

/**
 * Parse ISO date.
 * If forceUTC is true, date is always parsed as UTC. This is needed for some
 * API responses that return timezone-unaware dates.
 */
export function parseDate(date: string | Date, forceUTC = false): Date {
  if (typeof date === 'string') {
    const d = forceUTC && date[date.length - 1] !== 'Z' ? `${date}Z` : date;
    return parseISO(d);
  }
  return date;
}

/**
 * Parse unix timestamp (epoch) in seconds
 */
export function parseEpoch(epoch: number): Date {
  const d = new Date(0);
  d.setUTCSeconds(epoch);
  return d;
}

export function addDays(date: string | Date, days: number): Date {
  if (typeof date === 'string') date = parseDate(date);
  const newDate = addDaysFNS(date, days);
  return newDate;
}

/**
 * Example: Jan 3, 2022
 */
export function formatDate(date: string | Date): string {
  if (typeof date === 'string') date = parseDate(date);
  return format(date, 'PP');
}

/**
 * Example: January 3rd, 2022
 */
export function formatDateLong(date: string | Date): string {
  if (typeof date === 'string') date = parseDate(date);
  return format(date, 'PPP');
}

/**
 * Example: 12:08 PM
 */
export function formatTime(date: string | Date): string {
  if (typeof date === 'string') date = parseDate(date);
  return format(date, 'p');
}

/**
 * Example: Jan 3, 2022, 12:08 PM
 */
export function formatDateTime(date: string | Date): string {
  if (typeof date === 'string') date = parseDate(date);
  return format(date, 'PPp');
}

/**
 * Example: January 3rd, 2022 at 12:08 PM
 */
export function formatDateTimeLong(date: string | Date): string {
  if (typeof date === 'string') date = parseDate(date);
  return format(date, 'PPPp');
}

/**
 * Example: 2023-02-22_10-34" (local time)
 */
export function formatDateTimeDownload(
  date: string | Date,
  formatStr = 'yyyy-MM-dd_HH-mm',
): string {
  if (typeof date === 'string') date = parseDate(date);
  return format(date, formatStr);
}

/**
 * format distance as words, e.g. '1 day ago'
 */
export function formatDistance(date: string | Date, addSuffix = true): string {
  if (typeof date === 'string') date = parseDate(date);
  if (differenceInSeconds(new Date(), date) < 60) return `a few seconds${addSuffix ? ' ago' : ''}`;
  return formatDistanceToNowStrict(date, { addSuffix });
}

/**
 * format distance as words, e.g. '1 day ago' but show full dates for dates older than showFullForOlderThan
 */
export function formatRecentDistance(
  date: string | Date,
  showFullForOlderThan: Date,
  addSuffix = true,
): string {
  if (typeof date === 'string') date = parseDate(date);
  if (showFullForOlderThan && isBefore(date, showFullForOlderThan)) return formatDate(date);
  return formatDistance(date, addSuffix);
}

/**
 * Today, Yesterday, formatDate
 */
export function formatDateTodayYesterday(date: string | Date): string {
  if (typeof date === 'string') date = parseDate(date);
  const diffInDays = differenceInDays(new Date(), date);
  if (diffInDays === 0) return 'Today';
  if (diffInDays === 1) return 'Yesterday';
  return formatDate(date);
}

/**
 * [Today, Yesterday, formatDate], 12:30 AM
 */
export function formatDateTodayYesterdayTime(date: string | Date): string {
  return `${formatDateTodayYesterday(date)}, ${formatTime(date)}`;
}

/**
 * '2 days', '1 day', '23 hrs', '1 hr', '76 mins', '23 mins', '1 min', '< min'
 */
export function formatDistanceShort(date: string | Date): string {
  if (typeof date === 'string') date = parseDate(date);
  const diff = Math.abs(+new Date() - +date);
  if (diff >= 86400000) {
    const days = Math.floor(diff / 86400000);
    return formatCount(days, 'day');
  }
  if (diff >= 6000000) {
    const hours = Math.round(diff / 3600000);
    return formatCount(hours, 'hr');
  }
  if (diff >= 60000) {
    const mins = Math.round(diff / 60000);
    return formatCount(mins, 'min');
  }
  return '< 1 min';
}

/**
 * '2 days', '1 day', '23 hrs', '1 hr', '76 mins', '23 mins', '1 min', '< min'
 */
export function formatPositiveDistanceShort(date: string | Date): string {
  if (typeof date === 'string') date = parseDate(date);
  const diff = +date - +new Date();
  if (diff >= 86400000) {
    const days = Math.floor(diff / 86400000);
    return formatCount(days, 'day');
  }
  if (diff >= 6000000) {
    const hours = Math.round(diff / 3600000);
    return formatCount(hours, 'hr');
  }
  if (diff >= 60000) {
    const mins = Math.round(diff / 60000);
    return formatCount(mins, 'min');
  }
  return '< 1 min';
}

/**
 * Convert Date to UTC and return date string.
 * Mon Apr 13 2020 00:00:00 GMT+0900 (Korean Standard Time) -> '2020-04-12'
 * Mon Apr 13 2020 09:00:00 GMT+0900 (Korean Standard Time) -> '2020-04-13'
 */
export function getUTCDate(date: string | Date): string {
  const d = parseDate(date);
  const isoDate = d.toISOString(); // toISOString always formats date in UTC
  return isoDate.substring(0, 10);
}

/**
 * Convert time (in seconds) to a format that is easy to read.
 */
export function formatDuration(time_sec: number): string {
  if (Number.isNaN(time_sec)) return 'N/a';
  if (time_sec >= 48 * 3600) {
    if (time_sec % (24 * 3600) === 0) return formatCount(time_sec / (24 * 3600), 'day');
    const days = Math.floor(time_sec / (24 * 3600));
    const hours = Math.floor((time_sec % (24 * 3600)) / 3600);
    return hours
      ? `${formatCount(days, 'day')} ${formatCount(hours, 'hour')}`
      : `${formatCount(days, 'day')}`;
  }
  if (time_sec >= 3600) {
    if (time_sec % 3600 === 0) return formatCount(time_sec / 3600, 'hour');
    const hours = Math.floor(time_sec / 3600);
    const minutes = Math.round((time_sec % 3600) / 60);
    return minutes
      ? `${formatCount(hours, 'hour')} ${minutes} min`
      : `${formatCount(hours, 'hour')}`;
  }
  if (time_sec >= 60) {
    if (time_sec % 60 === 0) return formatCount(time_sec / 60, 'minute');
    const minutes = Math.floor(time_sec / 60);
    const seconds = Math.round(time_sec % 60);
    return `${minutes} min ${seconds} sec`;
  }
  return formatCount(time_sec, 'second');
}

/**
 * Convert time (in seconds) to a format like mm:ss or hh:mm:ss
 */
export function formatDurationShort(seconds: number): string {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const zeroPad = (num: number) => String(num).padStart(2, '0');
  return formatDurationFns(duration, {
    format: seconds >= 3600 ? ['hours', 'minutes', 'seconds'] : ['minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  });
}
