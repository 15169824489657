import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonBrushUnion = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 14 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M3.833 4.667h-1V2.833H1v-1h1.833V0h1v1.833h1.834v1H3.833v1.834ZM8.47 7.976l4.34-5.852A1.218 1.218 0 0 0 12.64.48a1.21 1.21 0 0 0-1.649.043l-5.227 5.07A2.991 2.991 0 0 1 8.47 7.976Zm-2.325 3.022c-1.31.672-3.574.849-5.2-.583a8.23 8.23 0 0 1 .428-.136c.724-.216 1.37-.408 1.632-1.67.061-.414.238-.817.535-1.155a2.242 2.242 0 0 1 3.17-.205 2.257 2.257 0 0 1-.565 3.749Z"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonBrushUnion);
export default ForwardRef;
