import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './../../foundation/Typography/styles.css.ts.vanilla.css';
import './style.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var activeColor = "var(--_1phaq644)";
var tabListRecipe = createRuntimeFn({ defaultClassName: "_1phaq647 _1cuzvx51 _1cuzvx513", variantClassNames: { variant: { inline: "_1phaq648", fill: "_1phaq649 _1cuzvx5y6" }, size: { small: "_1phaq64a wbddfs0 wbddfsd", medium: "_1phaq64b wbddfs0 wbddfsb", large: "_1phaq64c wbddfs0 wbddfs8" }, fullWidth: { true: "_1phaq64d" } }, defaultVariants: {}, compoundVariants: [] });
var tabStyle = "_1phaq64f _1cuzvx5ah _1cuzvx511";

export { activeColor, tabListRecipe, tabStyle };
