import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import { LoadingSpinner, LoadingSpinnerSmall, LoadingSpinnerAlt, LoadingDots } from '@superb-ai/icons';
import { Box } from '../../foundation/Box/Box.js';
import { Icon } from '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import '../../theme/color-util.js';
import { vars } from '../../theme/theme.css.vanilla.js';
import '../../theme/sprinkles.css.vanilla.js';

const indicatorTypes = {
  spinner: LoadingSpinner,
  "spinner-small": LoadingSpinnerSmall,
  "spinner-alt": LoadingSpinnerAlt,
  dots: LoadingDots
};
const LoadingIndicator = forwardRef(function LoadingIndicator2({ type = "spinner", size = "medium", color = "gray", ...props }, ref) {
  const { ref: _ref, ...boxProps } = mergeProps(
    { ref, display: "grid", placeItems: "center", color },
    props
  );
  return /* @__PURE__ */ jsx(Box, { ref, ...boxProps, children: /* @__PURE__ */ jsx(Icon, { icon: indicatorTypes[type], size: vars.size[size] }) });
});

export { LoadingIndicator };
