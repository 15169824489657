import * as React from "react";
import { forwardRef } from "react";
const SvgSplit = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m2.293 20.293 7.828-7.829A5 5 0 0 1 13.657 11H15.5V7l6.5 5-6.5 5v-4h-1.843a3 3 0 0 0-2.121.879l-7.829 7.828-1.414-1.414Zm7-9.586-7-7 1.414-1.414 7 7-1.414 1.414Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSplit);
export default ForwardRef;
