import * as React from "react";
import { forwardRef } from "react";
const SvgModelLogo = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("rect", { x: 3.238, y: 13.238, width: 7.524, height: 7.524, rx: 1, fill: "#A8FFE8" }),
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      x: 17,
      y: 2.223,
      width: 6.757,
      height: 6.757,
      rx: 1,
      transform: "rotate(45 17 2.223)",
      fill: "#4AE2B9"
    }
  ),
  /* @__PURE__ */ React.createElement("circle", { cx: 7, cy: 7, r: 4, fill: "#A8FFE8" }),
  /* @__PURE__ */ React.createElement("path", { fill: "#A8FFE8", d: "M6 10h2v4.488H6zM15 16v2H9v-2z" }),
  /* @__PURE__ */ React.createElement("circle", { cx: 17, cy: 17, r: 4, fill: "#A8FFE8" })
);
const ForwardRef = forwardRef(SvgModelLogo);
export default ForwardRef;
