import * as React from "react";
import { forwardRef } from "react";
const SvgFileFind = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M11.422 1.91a.83.83 0 0 0-.589-.244H5c-.92 0-1.667.748-1.667 1.667v13.333c0 .92.748 1.667 1.667 1.667h10c.92 0 1.667-.748 1.667-1.667V7.499a.83.83 0 0 0-.245-.589l-5-5ZM5 3.333h5.488L15 7.844l.002 7.645-2.14-2.14a3.31 3.31 0 0 0 .471-1.683A3.337 3.337 0 0 0 10 8.333a3.337 3.337 0 0 0-3.333 3.333A3.337 3.337 0 0 0 10 14.999a3.31 3.31 0 0 0 1.683-.471l2.139 2.138H5V3.333Zm5 10c-.92 0-1.667-.748-1.667-1.667 0-.92.748-1.667 1.667-1.667.92 0 1.667.748 1.667 1.667 0 .92-.748 1.667-1.667 1.667Z" })
);
const ForwardRef = forwardRef(SvgFileFind);
export default ForwardRef;
