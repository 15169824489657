function relative({
  value,
  max = 0,
  min = 0
}) {
  if (value <= min) {
    return 0;
  }
  if (value >= max) {
    return 1;
  }
  return Math.abs(value - min) / Math.abs(max - min);
}

export { relative };
