import * as React from "react";
import { forwardRef } from "react";
const SvgLidar = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    fill: "currentColor",
    viewBox: "0 0 24 24",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("circle", { cx: 12, cy: 12, r: 2 }),
  /* @__PURE__ */ React.createElement("path", { d: "M15.16 9.547c.527.678.84 1.529.84 2.453 0 .924-.313 1.775-.84 2.453l1.421 1.421A5.976 5.976 0 0 0 18.002 12c0-1.477-.534-2.83-1.419-3.874L15.16 9.547zm-6.32 4.906-1.422 1.421A5.976 5.976 0 0 1 6 12c0-1.477.534-2.83 1.418-3.874L8.84 9.547A3.983 3.983 0 0 0 8 12c0 .924.314 1.775.84 2.453z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M4.584 5.291A9.963 9.963 0 0 0 2 12c0 2.582.978 4.935 2.584 6.709l1.417-1.417A7.97 7.97 0 0 1 4 12a7.97 7.97 0 0 1 2-5.292L4.585 5.29zm14.832 13.418-1.417-1.417A7.97 7.97 0 0 0 20 12a7.97 7.97 0 0 0-2-5.292l1.416-1.417A9.964 9.964 0 0 1 22 12a9.963 9.963 0 0 1-2.584 6.709z" })
);
const ForwardRef = forwardRef(SvgLidar);
export default ForwardRef;
