import * as React from "react";
import { forwardRef } from "react";
const SvgEmptyResult = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.17 15H4v5h16v-5h-3.17A3.001 3.001 0 0 1 14 17h-4a3.001 3.001 0 0 1-2.83-2ZM2 13v8a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-8h-7v1a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-1H2ZM2 13h2.394l2.667-4h9.937l2.666 4H22l-4-6H6l-4 6Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("rect", { x: 11, y: 1, width: 2, height: 4, rx: 1, fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      x: 6.134,
      y: 2.768,
      width: 2,
      height: 4,
      rx: 1,
      transform: "rotate(-30 6.134 2.768)",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      x: 16.134,
      y: 1.768,
      width: 2,
      height: 4,
      rx: 1,
      transform: "rotate(30 16.134 1.768)",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgEmptyResult);
export default ForwardRef;
