import tokens from './tokens.js';

function colorsFromTokens() {
  const colors2 = {};
  for (const [color, colorDef] of Object.entries(tokens)) {
    for (const [weight, weightDef] of Object.entries(colorDef)) {
      const key = `${color.toLowerCase()}-${weight}`;
      colors2[key] = weightDef.value;
    }
  }
  return colors2;
}
const colorMap = colorsFromTokens();
const namedColors = Object.keys(tokens).map((str) => str.toLowerCase());
Object.fromEntries(
  namedColors.map((color) => [color, colorMap[`${color}-400`]])
);
Object.fromEntries(
  Object.keys(tokens).map((color) => [
    color.toLowerCase(),
    Object.keys(tokens[color])
  ])
);

export { colorMap, namedColors };
