import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { v4 } from 'uuid';
import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import { getColor } from '../../theme/color.js';
import '../../theme/sprinkles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { extendComponent } from '../../util/extend-component.js';
import { inputRecipe, inputStyle, inputColor, inputTextColor, comboboxInputStyle, comboboxInputRecipe } from './styles.css.vanilla.js';

function getColorsFromProps({
  variant,
  color
}) {
  const weight = variant === "soft-fill" ? "100" : color === "gray" && variant !== "strong-fill" ? "200" : "400";
  return {
    [inputColor]: getColor(color, weight),
    [inputTextColor]: getColor(color, "400")
  };
}
const Input = forwardRef(function Input2({ size, variant, color, prefix, suffix, boxProps, ...inputProps }, ref) {
  const className = inputRecipe({ size, variant, disabled: inputProps.disabled });
  const style = color ? assignInlineVars(getColorsFromProps({ variant, color })) : {};
  return /* @__PURE__ */ jsxs(Box, { display: "flex", ...mergeProps({ className, style }, boxProps ?? {}), children: [
    prefix,
    /* @__PURE__ */ jsx(
      "input",
      {
        ref,
        ...mergeProps({ className: inputStyle }, inputProps, { className: v4() })
      }
    ),
    suffix
  ] });
});
const ComboboxInput = extendComponent(Input, {
  variant: "text",
  className: comboboxInputStyle,
  boxProps: ({ size, color, disabled }) => ({
    className: comboboxInputRecipe({ size, disabled }),
    style: color ? assignInlineVars(getColorsFromProps({ color })) : {}
  })
});

export { ComboboxInput, Input };
