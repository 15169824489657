import * as React from "react";
import { forwardRef } from "react";
const SvgRedo = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M12.267 7.066a6.976 6.976 0 0 0-4.6-1.733c-3.1 0-5.72 2.02-6.64 4.813l1.573.52A5.335 5.335 0 0 1 7.667 7c1.3 0 2.486.48 3.413 1.253l-2.413 2.413h6v-6l-2.4 2.4Z" })
);
const ForwardRef = forwardRef(SvgRedo);
export default ForwardRef;
