import * as React from "react";
import { forwardRef } from "react";
const SvgStar = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.37 10.425 12 6.318l-1.37 4.107-4.433.094L9.73 13.31l-1.238 4.262L12 15.03l3.507 2.542-1.238-4.262 3.534-2.791-4.433-.094ZM9.18 8.455l-5.808.123c-.938.02-1.335 1.204-.599 1.785l4.663 3.682-1.675 5.768c-.266.915.776 1.648 1.547 1.089L12 17.5l4.693 3.402c.771.559 1.813-.174 1.547-1.089l-1.675-5.768 4.663-3.682c.736-.581.339-1.765-.599-1.785l-5.808-.123-1.872-5.612c-.304-.912-1.594-.912-1.898 0L9.18 8.455Z"
    }
  )
);
const ForwardRef = forwardRef(SvgStar);
export default ForwardRef;
