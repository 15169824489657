import * as React from "react";
import { forwardRef } from "react";
const SvgWindowTerminal = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M4 21h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Zm0-2V7h16l.001 12H4Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M12 15h6v2h-6v-2Zm-6-4 2.293 2.293L6 15.586 7.414 17l3.707-3.707-3.707-3.707L6 11Z" })
);
const ForwardRef = forwardRef(SvgWindowTerminal);
export default ForwardRef;
