import * as React from "react";
import { forwardRef } from "react";
const SvgLoadingSpinnerAlt = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 64 64",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    fill: "currentColor",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { strokeWidth: 5.2, stroke: "currentColor" }, /* @__PURE__ */ React.createElement("path", { d: "m35.625 13.35 1.527-7.854" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m42.487 16.156 4.415-6.67" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m47.752 21.375 6.632-4.473" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m50.619 28.212 7.839-1.595" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m50.65 35.625 7.854 1.527" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m47.844 42.487 6.67 4.415" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m42.625 47.752 4.473 6.632" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m35.788 50.619 1.595 7.839" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m28.375 50.65-1.527 7.854" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60;0.53",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m21.513 47.844-4.415 6.67" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67;0.60",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m16.248 42.625-6.632 4.473" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73;0.67",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m13.381 35.788-7.839 1.595" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80;0.73",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m13.35 28.375-7.854-1.527" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87;0.80",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m16.156 21.513-6.67-4.415" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93;0.87",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m21.375 16.248-4.473-6.632" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00;0.93",
      repeatCount: "indefinite"
    }
  )), /* @__PURE__ */ React.createElement("path", { d: "m28.212 13.381-1.595-7.839" }, /* @__PURE__ */ React.createElement(
    "animate",
    {
      attributeName: "stroke-opacity",
      dur: "1000ms",
      values: "0.93;0.87;0.80;0.73;0.67;0.60;0.53;0.47;0.40;0.33;0.27;0.20;0.13;0.07;0.00;1.00",
      repeatCount: "indefinite"
    }
  )))
);
const ForwardRef = forwardRef(SvgLoadingSpinnerAlt);
export default ForwardRef;
