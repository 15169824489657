import * as React from "react";
import { forwardRef } from "react";
const SvgChevronDuoDown = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "m12 18.425-6.01-6.01L7.403 11 12 15.596 16.596 11l1.414 1.414-6.01 6.01Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M12 13 5.99 6.99l1.414-1.415L12 10.172l4.596-4.597L18.01 6.99 12 13Z" })
);
const ForwardRef = forwardRef(SvgChevronDuoDown);
export default ForwardRef;
