import * as React from "react";
import { forwardRef } from "react";
const SvgSnapPoint = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m11.46 4.018.102 1.496a6.585 6.585 0 0 0-1.436.261l-.43-1.437a8.088 8.088 0 0 1 1.763-.32Zm-3.016.813.666 1.344a6.45 6.45 0 0 0-1.221.788l-.95-1.16c.46-.376.963-.703 1.505-.972ZM5.974 6.74l1.13.985c-.32.368-.599.77-.83 1.199l-1.32-.711a8.048 8.048 0 0 1 1.02-1.473ZM4.416 9.445l1.421.48a6.452 6.452 0 0 0-.307 1.421l-1.493-.15a7.95 7.95 0 0 1 .38-1.75Zm8.124 10.538-.103-1.497a6.584 6.584 0 0 0 1.436-.261l.43 1.437a8.11 8.11 0 0 1-1.763.32Zm3.015-.814-.666-1.344c.44-.218.848-.483 1.221-.788l.95 1.16a7.95 7.95 0 0 1-1.505.972Zm2.47-1.907-1.13-.986c.32-.367.599-.77.83-1.199l1.32.712a8.055 8.055 0 0 1-1.02 1.473Zm1.557-2.707-1.421-.48c.154-.457.258-.933.307-1.42l1.493.15a7.949 7.949 0 0 1-.38 1.75Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M9 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM19 7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 20.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM7 22a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM17 10.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm0 1.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSnapPoint);
export default ForwardRef;
