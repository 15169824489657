import * as React from "react";
import { forwardRef } from "react";
const SvgInfoCircle = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm2.01 15h-3.02v-4.005h-1V10.99h3.02L13 15h1.01v2Zm-1-7.98h-2.02V7h2.02v2.02Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgInfoCircle);
export default ForwardRef;
