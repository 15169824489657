import * as React from "react";
import { forwardRef } from "react";
const SvgBoxDelete = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM4 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM18 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16 5c0-.35.06-.687.17-1H7.83a2.995 2.995 0 0 1 0 2h8.34c-.11-.313-.17-.65-.17-1Zm3 3c.35 0 .687-.06 1-.17v8.34a2.997 2.997 0 0 0-2 0V7.83c.313.11.65.17 1 .17Zm-2.83 10H7.83a2.995 2.995 0 0 1 0 2h8.34a2.997 2.997 0 0 1 0-2ZM4 16.17V7.83a2.995 2.995 0 0 0 2 0v8.34a2.996 2.996 0 0 0-2 0Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m12 13.413-2.293 2.293-1.415-1.414 2.293-2.293-2.292-2.292 1.415-1.414L12 10.586l2.293-2.293 1.413 1.414L13.415 12l2.293 2.293-1.414 1.414L12 13.413Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgBoxDelete);
export default ForwardRef;
