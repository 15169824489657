import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonRemovePoint = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 11",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.333 1.167v-1H0v1h3.333ZM7 9.667a3.667 3.667 0 1 0 0-7.333 3.667 3.667 0 0 0 0 7.333Zm0 1a4.667 4.667 0 1 0 0-9.333 4.667 4.667 0 0 0 0 9.333ZM9 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonRemovePoint);
export default ForwardRef;
