import * as React from "react";
import { forwardRef } from "react";
const SvgOverlap = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    fill: "currentColor",
    viewBox: "0 0 24 24",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      d: "M17.4 10.2a7.2 7.2 0 0 1-7.637 7.187 5.4 5.4 0 1 0 7.624-7.624c.009.145.013.29.013.437zM3 10.2a7.201 7.201 0 0 0 4.236 6.564 7.201 7.201 0 1 0 9.528-9.528A7.202 7.202 0 0 0 3 10.2zm5.824 1.498a5.401 5.401 0 0 1 6.545-3.067 5.401 5.401 0 0 0-6.544 3.067zM4.8 10.2c0 1.606.7 3.048 1.813 4.037a7.2 7.2 0 0 1 7.623-7.624A5.4 5.4 0 0 0 4.8 10.2z",
      clipRule: "evenodd"
    }
  )
);
const ForwardRef = forwardRef(SvgOverlap);
export default ForwardRef;
