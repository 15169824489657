import * as React from "react";
import { forwardRef } from "react";
const SvgSliceAction = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m14.546 11.192-2.002 2.003.55 1.242.015.038a4.308 4.308 0 0 1 .239.994c.073.646-.032 1.152-.329 1.503-1.215 1.436-4.013 2.403-6.436 1.964-.457-.083-.9-.187-1.317-.3l7.135-6.712 1.438-1.439.707.707Zm1.414 1.415-1.032 1.032s1.213 2.739-.382 4.625c-2.68 3.168-8.22 2.874-10.966 1.947-1.237-.418-2.05-.821-2.05-.821l9.48-8.905 1.415-1.414-.707-.707 3.889-3.89a3.5 3.5 0 1 1 4.95 4.95l-3.89 3.89-.707-.707Zm.707-2.122 2.475-2.475a1.5 1.5 0 1 0-2.121-2.12l-2.475 2.474 2.121 2.121Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSliceAction);
export default ForwardRef;
