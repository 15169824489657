import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var chipRecipe = createRuntimeFn({ defaultClassName: "_1cuzvx530 _1cuzvx53i _1cuzvx55 _1cuzvx5l _1cuzvx5f _1cuzvx56i _1cuzvx5v _1a3gihf2", variantClassNames: { fill: { strong: "_1a3gihf5", soft: "_1a3gihf6" } }, defaultVariants: { fill: "soft" }, compoundVariants: [] });
var selectedColor = "var(--_1a3gihf0)";
var selectedFontColor = "var(--_1a3gihf1)";

export { chipRecipe, selectedColor, selectedFontColor };
