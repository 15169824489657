import * as React from "react";
import { forwardRef } from "react";
const SvgNotificationActive = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M2 10h2c.15-2.65 1.37-5.12 3.39-6.57L5.97 2C3.58 3.83 2.15 6.8 2 10Zm19.97 0h-2c-.15-2.65-1.37-5.12-3.39-6.57L18 2c2.39 1.83 3.82 4.8 3.97 8Zm-3.97.5c0-3.07-1.14-5.64-4-6.32V2h-4v2.18c-2.87.68-4 3.24-4 6.32V16l-2 1v2h16v-2l-2-1v-5.5Zm-5.6 11.46A2.014 2.014 0 0 1 9.99 20h4c0 .28-.05.54-.15.78-.26.6-.79 1.04-1.44 1.18Z"
    }
  )
);
const ForwardRef = forwardRef(SvgNotificationActive);
export default ForwardRef;
