import * as React from "react";
import { forwardRef } from "react";
const SvgVideoFrames = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2.01 4.796A2.003 2.003 0 0 1 4 3h16a2.003 2.003 0 0 1 2 2v14c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V5c0-.069.003-.137.01-.204ZM20 7H4v10h16V7Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "m10 15 5-3-5-3v6Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M5 4h2v2H5V4ZM5 18h2v2H5v-2ZM9 4h2v2H9V4ZM9 18h2v2H9v-2ZM13 4h2v2h-2V4ZM13 18h2v2h-2v-2ZM17 4h2v2h-2V4ZM17 18h2v2h-2v-2Z",
      fill: "#fff"
    }
  )
);
const ForwardRef = forwardRef(SvgVideoFrames);
export default ForwardRef;
