import * as React from "react";
import { forwardRef } from "react";
const SvgScatterToolbarGroup = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { opacity: 0.14, d: "M21 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { opacity: 0.3, d: "M11 17a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z",
      fill: "currentColor"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "M21 17a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z", fill: "currentColor" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgScatterToolbarGroup);
export default ForwardRef;
