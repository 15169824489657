import { forwardRef, createElement } from 'react';
import { mergeProps } from '@react-aria/utils';
import { objectEntries, evaluate, objectFromEntries } from './functional.js';

function createEvaluator(definition) {
  return function evaluator(props) {
    const evaluatedPropsEntries = objectEntries(definition ?? {}).map(
      ([prop, value]) => [prop, evaluate(value)(props)]
    );
    return objectFromEntries(evaluatedPropsEntries);
  };
}
function extendComponent(base, extendedProps) {
  const ExtendedComponent = forwardRef((props, ref) => {
    const evaluator = createEvaluator(extendedProps);
    const evaluatedProps = evaluator(props);
    const { children, style, ...elementProps } = props;
    const mergedProps = mergeProps({ children }, evaluatedProps, elementProps, {
      style: { ...evaluatedProps.style, ...style },
      ref
    });
    return createElement(base, mergedProps);
  });
  const name = base.displayName ?? base.name ?? base;
  ExtendedComponent.displayName = `extended(${name})`;
  return ExtendedComponent;
}

export { extendComponent };
