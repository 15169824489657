import { extendComponent } from '../../util/extend-component.js';
import { IconButton } from './IconButton.js';

const triangle = '<svg fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><polygon points="0,50 50,50 50,0" /></svg>';
const IconButtonWithTriangle = extendComponent(IconButton, {
  style: {
    backgroundImage: `url('data:image/svg+xml;utf8,${triangle}')`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "8px 8px"
  }
});

export { IconButtonWithTriangle };
