import * as React from "react";
import { forwardRef } from "react";
const SvgSelectedShape = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8.282 20.911a.206.206 0 0 1-.37-.072L4.006 3.25a.206.206 0 0 1 .305-.222l16.588 9.76a.206.206 0 0 1-.068.38l-7.344 1.296c-.54.096-1.02.403-1.333.855l-3.87 5.591Zm.72-4.652-2.067-9.3 8.595 5.057-2.401.424a4.112 4.112 0 0 0-2.667 1.71l-1.46 2.11Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgSelectedShape);
export default ForwardRef;
