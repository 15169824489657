import * as React from "react";
import { forwardRef } from "react";
const SvgShapeSquarePlus = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M19.158 4.842H22v1.895h-2.842v2.842h-1.895V6.737h-2.842V4.842h2.842V2h1.895v2.842Zm-1.895 13.263v-5.684h1.895V19a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5.842a1 1 0 0 1 1-1h6.579v1.895H5.895v11.368h11.368Z" })
);
const ForwardRef = forwardRef(SvgShapeSquarePlus);
export default ForwardRef;
