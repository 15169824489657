import * as React from "react";
import { forwardRef } from "react";
const SvgListAssign = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M2 7h13v2H2V7Zm0 4h12v2H2v-2Zm0 4h10v2H2v-2ZM22 15.01h-6.5v2H22v-2Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M17.01 12 13 16.01l4.01 4.01 1.415-1.413-2.597-2.597 2.597-2.596L17.01 12Z" })
);
const ForwardRef = forwardRef(SvgListAssign);
export default ForwardRef;
