import * as React from "react";
import { forwardRef } from "react";
const SvgKeyFramePrimary = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M2.41 12.992a1.403 1.403 0 0 1 0-1.984l8.598-8.597a1.403 1.403 0 0 1 1.984 0l8.597 8.597a1.403 1.403 0 0 1 0 1.984l-8.597 8.597a1.403 1.403 0 0 1-1.984 0l-8.597-8.597Z",
      fill: "#FF625A"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "m11.008 2.41-8.597 8.598a1.403 1.403 0 0 0 0 1.984l8.597 8.597c.274.274.633.41.992.411V2c-.36 0-.718.137-.992.41Z",
      fill: "#000",
      fillOpacity: 0.14
    }
  )
);
const ForwardRef = forwardRef(SvgKeyFramePrimary);
export default ForwardRef;
