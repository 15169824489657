import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './../../foundation/Typography/styles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var arrowStyle = "_5g1pxja";
var buttonIconStyle = "_5g1pxjc _1cuzvx55";
var tooltipRecipe = createRuntimeFn({ defaultClassName: "_1cuzvx5ji _1cuzvx51 _1cuzvx56i _1cuzvx5ab _1cuzvx5l _5g1pxj1 wbddfs0 wbddfsb", variantClassNames: { arrow: { true: "_1cuzvx531 _1cuzvx53j _1cuzvx51o _1cuzvx526", false: "_1cuzvx52o _1cuzvx536 _1cuzvx520 _1cuzvx52i" }, clickable: { true: "_1cuzvx5ah" } }, defaultVariants: {}, compoundVariants: [] });

export { arrowStyle, buttonIconStyle, tooltipRecipe };
