import * as React from "react";
import { forwardRef } from "react";
const SvgFolderPlus = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M11 9h2v3h3v2h-3v3h-2v-3H8v-2h3V9Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M12.414 5H20c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2h6c.266 0 .52.105.707.293L12.414 5ZM4 7v12h16.002L20 7H4Z"
    }
  )
);
const ForwardRef = forwardRef(SvgFolderPlus);
export default ForwardRef;
