import * as React from "react";
import { forwardRef } from "react";
const SvgVisible = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M8 6.4c-.985 0-1.8.815-1.8 1.8 0 .985.815 1.8 1.8 1.8.985 0 1.8-.815 1.8-1.8 0-.985-.815-1.8-1.8-1.8Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M8 4C3.445 4 2.076 7.97 2.063 8.01L2 8.2l.063.19C2.076 8.43 3.445 12.4 8 12.4c4.555 0 5.924-3.97 5.937-4.01L14 8.2l-.063-.19C13.924 7.97 12.555 4 8 4Zm0 7.2c-3.193 0-4.43-2.308-4.73-3 .3-.695 1.538-3 4.73-3 3.193 0 4.43 2.308 4.73 3-.3.695-1.538 3-4.73 3Z" })
);
const ForwardRef = forwardRef(SvgVisible);
export default ForwardRef;
