import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { mergeProps, clamp } from '@react-aria/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { getColor, getOpacityColor } from '../../theme/color.js';
import '../../theme/sprinkles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { relative } from '../../util/math.js';
import { selectedColor, selectedOpacityColor, currentValue, inputRecipe, wrap, symbol } from './styles.css.vanilla.js';

function useRange({ value, min, max, onValueChange, onValueInput }) {
  function onChange(e) {
    const numberValue = clamp(Number(e.currentTarget.value), min, max);
    onValueInput?.(numberValue);
  }
  function onMouseUp(e) {
    const numberValue = clamp(Number(e.currentTarget.value), min, max);
    onValueChange?.(numberValue);
  }
  function getStepClickHandler(change) {
    return () => {
      const newValue = clamp(value + change, min, max);
      onValueInput?.(newValue);
      onValueChange?.(newValue);
    };
  }
  return {
    onChange,
    onMouseUp,
    getStepClickHandler
  };
}
function getRangeProps({
  size = "small",
  theme = "light",
  color = "primary",
  style,
  ...props
}) {
  const { value, min, max } = props;
  const relativeValue = relative({ value, min, max });
  const varStyle = assignInlineVars({
    [selectedColor]: getColor(color),
    [selectedOpacityColor]: getColor(getOpacityColor(color, "primary"), "300"),
    [currentValue]: `${relativeValue}`
  });
  return mergeProps(props, {
    type: "range",
    className: inputRecipe({ size, theme }),
    style: { ...varStyle, ...style || {} }
  });
}
const RangeInput = forwardRef(function RangeInput2(props, ref) {
  return /* @__PURE__ */ jsx("input", { ref, ...getRangeProps(props) });
});
function Range({ onValueChange, onValueInput, minSymbol, maxSymbol, ...props }) {
  const { value, min, max, step = 1 } = props;
  const { onChange, onMouseUp, getStepClickHandler } = useRange({
    value,
    min,
    max,
    onValueChange,
    onValueInput
  });
  return /* @__PURE__ */ jsxs("div", { className: wrap, children: [
    minSymbol && /* @__PURE__ */ jsx("span", { className: symbol, onClick: getStepClickHandler(-step), children: minSymbol }),
    /* @__PURE__ */ jsx(
      RangeInput,
      {
        ...mergeProps(props, {
          ref: void 0,
          onChange,
          onMouseUp
        })
      }
    ),
    maxSymbol && /* @__PURE__ */ jsx("span", { className: symbol, onClick: getStepClickHandler(step), children: maxSymbol })
  ] });
}

export { Range, RangeInput, getRangeProps, useRange };
