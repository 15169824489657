import * as React from "react";
import { forwardRef } from "react";
const SvgPolygonDrawingPen = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "m12.064 7.538-2.622 4.719a.686.686 0 0 1-.448.335l-6.157 1.39a.686.686 0 0 1-.82-.82l1.39-6.156a.686.686 0 0 1 .336-.448l4.72-2.622-.14-.139a.343.343 0 0 1 0-.484L9.537 2.1a.343.343 0 0 1 .485 0L13.9 5.98a.343.343 0 0 1 0 .484l-1.213 1.212a.343.343 0 0 1-.484 0l-.139-.138Zm-3.522 3.516 2.514-4.525L9.47 4.944 4.946 7.458a.686.686 0 0 0-.336.449l-.846 3.747 2.39-2.39a1.029 1.029 0 1 1 .582.582l-2.39 2.39 3.747-.846a.686.686 0 0 0 .449-.336Z"
    }
  )
);
const ForwardRef = forwardRef(SvgPolygonDrawingPen);
export default ForwardRef;
