import * as React from "react";
import { forwardRef } from "react";
const SvgCommentCheck = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M13.922 6.545 9.168 11.3l-3.09-3.089 1.179-1.178 1.91 1.91 3.578-3.577 1.178 1.179Z" }),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.167 2.5h11.666c.92 0 1.667.748 1.667 1.667V12.5c0 .92-.747 1.667-1.667 1.667H7.5c-.36 0-.712.117-1 .333l-4 3V4.167c0-.92.748-1.667 1.667-1.667Zm2.778 10h8.888V4.167H4.167v10l1.778-1.334c.288-.216.64-.333 1-.333Z"
    }
  )
);
const ForwardRef = forwardRef(SvgCommentCheck);
export default ForwardRef;
