import * as React from "react";
import { forwardRef } from "react";
const SvgCommentClear = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 4h11c1.103 0 2 .897 2 2v4h-2V6H5v9l2.134-1.6a2 2 0 0 1 1.2-.4H11v2H9a2 2 0 0 0-1.2.4L3 19V6c0-1.103.897-2 2-2Z"
    }
  ),
  /* @__PURE__ */ React.createElement("path", { d: "m16.707 17.12-2.293 2.293L13 17.999l2.293-2.293-2.292-2.292L14.415 12l2.293 2.293L19.001 12l1.414 1.414-2.293 2.293L20.415 18l-1.414 1.414-2.294-2.294Z" })
);
const ForwardRef = forwardRef(SvgCommentClear);
export default ForwardRef;
