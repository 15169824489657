import * as React from "react";
import { forwardRef } from "react";
const SvgEndpoint = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { clipPath: "url(#endpoint_svg__a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6.25 4a1.75 1.75 0 1 0-1.622-1.092l-1.55 1.55a1.75 1.75 0 1 0-.165 3.162l1.653 1.653A1.751 1.751 0 0 0 6.25 11.5a1.75 1.75 0 1 0-1.13-3.087L3.695 6.988c.101-.148.18-.312.233-.488h4.144a1.75 1.75 0 1 0 0-1H3.928a1.739 1.739 0 0 0-.14-.337l1.47-1.471c.282.194.624.308.992.308Zm0-1a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm-4 3.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 6A.75.75 0 1 1 9 6a.75.75 0 0 1 1.5 0Zm-4.25 4.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z",
      fill: "currentColor"
    }
  )),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "endpoint_svg__a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 0h12v12H0z" })))
);
const ForwardRef = forwardRef(SvgEndpoint);
export default ForwardRef;
