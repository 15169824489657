import * as React from "react";
import { forwardRef } from "react";
const SvgChartBar = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M11 5H8v16h3V5ZM6 13H3v8h3v-8ZM13 8h3v13h-3V8ZM21 11h-3v10h3V11Z" })
);
const ForwardRef = forwardRef(SvgChartBar);
export default ForwardRef;
