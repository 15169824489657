import { ExtractRouteParams, generatePath } from 'react-router';

import { createPath } from 'history';
import { stringify } from 'qs';

/**
 * Join path segments and remove duplicate slashes (empty segments)
 */
export function joinRelativePaths(...segments: string[]): string {
  return segments.join('/').replace('//', '/');
}

/**
 * Join path segments and return absolute path
 */
export function joinPaths<Path extends string>(...segments: Path[]): Path {
  const concated = joinRelativePaths(...segments);
  if (concated[0] !== '/') {
    return `/${concated}` as Path;
  }
  return concated as Path;
}

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

/**
 * Get a URL from a path or path segments by filling in params
 */
export function getUrl<
  Path extends string = string,
  Params extends ExtractRouteParams<Path, string | number | boolean> = ExtractRouteParams<
    Path,
    string | number | boolean
  >,
>(
  path: Path | readonly Path[],
  params?: UnionToIntersection<Params>,
  query?: string | Record<string, any>,
  hash?: string,
): string {
  const concatPath = typeof path === 'string' ? path : joinPaths(...path);
  const pathname = generatePath(concatPath, params as Params);
  const search = typeof query === 'string' ? query : stringify(query, { arrayFormat: 'brackets' });
  return createPath({
    pathname,
    search,
    hash,
  });
}

/**
 * Get the parent path or URL by removing the last segment
 */
export function getParentPath(path: string, steps = -1): string {
  const segments = path.split('/');
  return joinPaths(...segments.slice(0, segments.length + steps));
}
