import * as React from "react";
import { forwardRef } from "react";
const SvgAutolabelCreate = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { fillRule: "evenodd", clipRule: "evenodd", fill: "currentColor" }, /* @__PURE__ */ React.createElement("path", { d: "m11.5 9.758-1.5-4.5-1.5 4.5-3.5 1.5 3.5 1.5 1.5 4.5 1.5-4.5 3.5-1.5-3.5-1.5Zm-.551-6.654c-.304-.912-1.594-.912-1.898 0L7.1 8.358l-3.955 1.98c-.808.347-.808 1.493 0 1.84l3.955 1.98 1.951 5.254c.304.912 1.594.912 1.898 0l1.951-5.254 3.955-1.98c.808-.347.808-1.493 0-1.84L12.9 8.359l-1.951-5.254ZM17.5 19.5V23h2v-3.5H23v-2h-3.5V14h-2v3.5H14v2h3.5Z" }))
);
const ForwardRef = forwardRef(SvgAutolabelCreate);
export default ForwardRef;
