import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { cloneElement } from 'react';
import { DialogDisclosure, DialogBackdrop, Dialog as Dialog$1 } from 'reakit/Dialog';
export { useDialogState } from 'reakit/Dialog';
import { mergeProps } from '@react-aria/utils';
import { Clear } from '@superb-ai/icons';
import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { Typography, Paragraph } from '../../foundation/Typography/Typography.js';
import '../../contrib/Chip/Chip.js';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import './contexts.js';
import 'reakit/Popover';
import 'react-popper';
import '../Tooltip/styles.css.vanilla.js';
import '../../inputs/Button/Button.js';
import { IconButton } from '../../inputs/Button/IconButton.js';
import '../../inputs/Button/IconButtonWithTriangle.js';
import '../../inputs/Button/styles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './../../inputs/Dropdown/styles.css.ts.vanilla.css';
import '../../inputs/Input/Input.js';
import '../../inputs/Select/styles.css.vanilla.js';
import '../../inputs/Checkbox/Checkbox.js';
import '../../inputs/Label/Label.js';
import '../../inputs/Radio/Radio.js';
import 'reakit/Radio';
import '@vanilla-extract/dynamic';
import 'classnames';
import '../../theme/color-util.js';
import '../../theme/sprinkles.css.vanilla.js';
import '../../inputs/Range/Range.js';
import '../../inputs/Range/styles.css.vanilla.js';
import 'reakit/Composite';
import '../../inputs/SegmentedControl/styles.css.vanilla.js';
import 'rc-overflow';
import '../../inputs/ToggleButton/ToggleButton.js';
import { backgroundStyle, dialogStyle, closeButtonStyle } from './styles.css.vanilla.js';

function Dialog({ disclosure, state, ...props }) {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    disclosure && /* @__PURE__ */ jsx(DialogDisclosure, { ...state, children: (disclosureProps) => cloneElement(disclosure, disclosureProps) }),
    /* @__PURE__ */ jsx(DialogBackdrop, { ...state, className: backgroundStyle, children: /* @__PURE__ */ jsx(Dialog$1, { ...state, ...mergeProps(props, { className: dialogStyle }) }) })
  ] });
}
Dialog.Disclosure = DialogDisclosure;
Dialog.Content = Box;
Dialog.Header = function DialogTitle({
  children,
  subheader,
  onClickClose
}) {
  return /* @__PURE__ */ jsxs(Box, { display: "flex", alignItems: "baseline", children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "h2", color: "primary", mr: "auto", children: [
      children,
      subheader && /* @__PURE__ */ jsx(Paragraph, { variant: "m-regular", color: "gray", children: subheader })
    ] }),
    onClickClose && /* @__PURE__ */ jsx(
      IconButton,
      {
        icon: Clear,
        variant: "text",
        size: "l",
        onClick: onClickClose,
        className: closeButtonStyle
      }
    )
  ] });
};
Dialog.Title = Dialog.Header;
Dialog.Actions = function DialogActions(props) {
  return /* @__PURE__ */ jsx(
    Box,
    {
      display: "flex",
      gap: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      mt: "auto",
      ...props
    }
  );
};

export { Dialog };
