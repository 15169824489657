import { jsxs, jsx } from 'react/jsx-runtime';
import { useEffect, Fragment } from 'react';
import { CheckboxChecked, CheckboxMixed, Checkbox } from '@superb-ai/icons';
import '../../contrib/Chip/Chip.js';
import '../../contrib/Collapse/Collapse.js';
import '../../contrib/ExternalLink/ExternalLink.js';
import { LoadingIndicator } from '../../contrib/LoadingIndicator/LoadingIndicator.js';
import '../../contrib/PasswordInput/PasswordInput.js';
import '../../contrib/ProgressBar/ProgressBar.js';
import '../../contrib/Skeleton/BoxSkeleton.js';
import '../../contrib/Skeleton/TypographySkeleton.js';
import '../../contrib/SuiteLogo/SuiteLogo.js';
import '../../contrib/Table/Table.js';
import '../../contrib/Tabs/Tabs.js';
import { Box } from '../../foundation/Box/Box.js';
import { Icon } from '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import { Typography } from '../../foundation/Typography/Typography.js';
import { useOnScreen } from '../../hooks/on-screen.js';
import '@react-aria/utils';
import '../Button/Button.js';
import '../Button/IconButton.js';
import '../Button/IconButtonWithTriangle.js';
import '../Button/styles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './../Dropdown/styles.css.ts.vanilla.css';
import '../Input/Input.js';
import { optionRecipe } from './styles.css.vanilla.js';
import '../Checkbox/Checkbox.js';
import '../Label/Label.js';
import '../Radio/Radio.js';
import 'reakit/Radio';
import '@vanilla-extract/dynamic';
import 'classnames';
import '../../theme/color-util.js';
import '../../theme/sprinkles.css.vanilla.js';
import '../Range/Range.js';
import '../Range/styles.css.vanilla.js';
import 'reakit/Composite';
import '../SegmentedControl/styles.css.vanilla.js';
import 'rc-overflow';
import '../ToggleButton/ToggleButton.js';
import '../../modal/Dialog/Dialog.js';
import '../../modal/Dialog/contexts.js';
import 'reakit/Popover';
import { Tooltip } from '../../modal/Tooltip/Tooltip.js';

function SelectList({
  data,
  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  formatValue,
  isLoading,
  loadingText = "Loading...",
  selectAllText = "Select All",
  maxHeight = "100%",
  hasNextPage,
  emptyContent = "No options found",
  fetchNextPage,
  children,
  ...props
}) {
  const { multiple, value, onChangeValue, ...restProps } = props;
  const { measureRef, isIntersecting } = useOnScreen({
    rootMargin: "100px"
  });
  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage?.();
    }
  }, [isIntersecting, hasNextPage]);
  const Loader = /* @__PURE__ */ jsxs(Box, { display: "flex", alignItems: "center", color: "gray-300", px: 1, py: 0.5, gap: 0.5, children: [
    /* @__PURE__ */ jsx(LoadingIndicator, { type: "spinner-small", size: "xsmall", color: "gray-300" }),
    " ",
    /* @__PURE__ */ jsx(Typography, { variant: "m-regular", children: loadingText })
  ] });
  function toggle(toggledValue) {
    if (multiple) {
      const nextValue = value?.includes(toggledValue) ? value?.filter((v) => v !== toggledValue) : [...value, toggledValue];
      onChangeValue?.(nextValue);
    } else {
      onChangeValue?.(toggledValue);
    }
  }
  return /* @__PURE__ */ jsxs(
    Box,
    {
      display: "flex",
      flexDirection: "column",
      role: "listbox",
      overflow: "overlay",
      style: { maxHeight },
      ...restProps,
      children: [
        props.multiple && props.enableSelectAll && Array.isArray(value) && (() => {
          const isSelected = (value?.length ?? 0) >= (data?.length ?? 0);
          const isMixed = (value?.length ?? 0) > 0;
          const nextValue = isSelected ? [] : data?.map(({ value: value2 }) => value2) ?? [];
          return /* @__PURE__ */ jsxs(
            Box,
            {
              "aria-selected": isSelected,
              color: isSelected ? "primary" : "gray-400",
              backgroundColor: {
                default: isSelected || isMixed ? "primary-100" : void 0,
                hover: "gray-100"
              },
              borderBottom: "1px solid",
              borderColor: "gray-150",
              onClick: () => props.onChangeValue?.(nextValue),
              onKeyPress: () => props.onChangeValue?.(nextValue),
              children: [
                /* @__PURE__ */ jsx(
                  Icon,
                  {
                    icon: isSelected ? CheckboxChecked : isMixed ? CheckboxMixed : Checkbox,
                    color: isSelected || isMixed ? "primary" : "gray",
                    className: "option-checkbox"
                  }
                ),
                /* @__PURE__ */ jsx(Typography, { as: "div", variant: "m-regular", style: { flex: 1 }, children: selectAllText })
              ]
            }
          );
        })(),
        isLoading && Loader,
        !isLoading && !data?.length && (children || /* @__PURE__ */ jsx(Typography, { variant: "m-regular", color: "gray-300", px: 1.5, py: 1, children: emptyContent })),
        data?.map((option) => {
          const isSelected = multiple ? value.includes(option.value) : value === option.value;
          const icon = isSelected ? CheckboxChecked : Checkbox;
          return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(Tooltip, { content: option.tooltip, hideOnEmptyContent: true, children: /* @__PURE__ */ jsxs(
            Box,
            {
              role: "option",
              "aria-selected": isSelected,
              "aria-disabled": option.disabled,
              tabIndex: 0,
              className: optionRecipe({}),
              color: option.disabled ? "gray-200" : isSelected ? "primary" : "gray-400",
              backgroundColor: {
                default: isSelected ? "primary-100" : void 0,
                hover: option.disabled ? void 0 : "gray-100"
              },
              onClick: () => !option.disabled && toggle(option.value),
              onKeyPress: () => !option.disabled && toggle(option.value),
              children: [
                multiple && /* @__PURE__ */ jsx(Icon, { icon, className: "option-checkbox" }),
                /* @__PURE__ */ jsx(Typography, { as: "div", variant: "m-regular", style: { flex: 1 }, children: option.label })
              ]
            }
          ) }) }, `${option.value}`);
        }),
        hasNextPage && /* @__PURE__ */ jsx("div", { ref: measureRef, children: Loader })
      ]
    }
  );
}

export { SelectList };
