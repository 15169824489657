import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var currentValue = "var(--_1ojyh1b0)";
var dualInputs = "_1ojyh1bi _1cuzvx57q _1cuzvx5u";
var dualWrap = "_1cuzvx5v";
var from = "_1ojyh1bf";
var inputRecipe = createRuntimeFn({ defaultClassName: "_1ojyh1bj", variantClassNames: { theme: { light: "_1ojyh1bk", dark: "_1ojyh1bl" }, size: { small: "_1ojyh1bm", medium: "_1ojyh1bn" } }, defaultVariants: {}, compoundVariants: [] });
var maxPercent = "var(--_1ojyh1b8)";
var minPercent = "var(--_1ojyh1b7)";
var selectedColor = "var(--_1ojyh1b1)";
var selectedOpacityColor = "var(--_1ojyh1b2)";
var symbol = "_1ojyh1be _1cuzvx51 _1cuzvx5l";
var to = "_1ojyh1bg";
var wrap = "_1ojyh1ba _1cuzvx51 _1cuzvx5l";

export { currentValue, dualInputs, dualWrap, from, inputRecipe, maxPercent, minPercent, selectedColor, selectedOpacityColor, symbol, to, wrap };
