import * as React from "react";
import { forwardRef } from "react";
const SvgRemovePoint = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6.5 4.75v-1.5h-5v1.5h5ZM12 17.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Zm0 1.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm3-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgRemovePoint);
export default ForwardRef;
