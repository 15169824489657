import { jsx } from 'react/jsx-runtime';
import { createContext, useMemo, useEffect, useContext } from 'react';
import { useTabState, Tab as Tab$1, TabList as TabList$1, TabPanel as TabPanel$1 } from 'reakit/Tab';
import { mergeProps } from '@react-aria/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { Box } from '../../foundation/Box/Box.js';
import '../../foundation/Icon/Icon.js';
import '../../foundation/Typography/styles.css.vanilla.js';
import '../../foundation/Typography/Typography.js';
import { getColor } from '../../theme/color.js';
import '../../theme/sprinkles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { tabStyle, tabListRecipe, activeColor } from './style.css.vanilla.js';

const TabsContext = createContext(null);
function Tabs({ children, onSelectTab, ...initialState }) {
  const tab = useTabState(initialState);
  const value = useMemo(() => tab, Object.values(tab));
  useEffect(() => {
    onSelectTab?.(tab.selectedId ?? null);
  }, [tab.selectedId]);
  return /* @__PURE__ */ jsx(TabsContext.Provider, { value, children });
}
function Tab(props) {
  const tab = useContext(TabsContext);
  return /* @__PURE__ */ jsx(
    Tab$1,
    {
      ...tab,
      ...mergeProps(props, {
        className: tabStyle
      })
    }
  );
}
function TabList(props) {
  const {
    variant = "inline",
    size = "medium",
    fullWidth = false,
    color = "primary",
    ...otherProps
  } = props;
  const mergedProps = mergeProps(otherProps, {
    className: tabListRecipe({
      variant,
      size,
      fullWidth
    }),
    style: assignInlineVars({
      [activeColor]: getColor(color)
    })
  });
  const tab = useContext(TabsContext);
  return /* @__PURE__ */ jsx(TabList$1, { as: Box, ...tab, ...mergedProps });
}
function TabPanel(props) {
  const tab = useContext(TabsContext);
  return /* @__PURE__ */ jsx(TabPanel$1, { ...tab, ...props });
}

export { Tab, TabList, TabPanel, Tabs };
