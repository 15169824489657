import * as React from "react";
import { forwardRef } from "react";
const SvgArrowLongUp = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 12 12",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M6.5 11V2.915l1.295 1.29L8.5 3.5 6 1 3.5 3.5l.705.705L5.5 2.915V11h1Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgArrowLongUp);
export default ForwardRef;
