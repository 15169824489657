import * as React from "react";
import { forwardRef } from "react";
const SvgUnavailableFit = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 8 8",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 4a4 4 0 1 0-8 0 4 4 0 0 0 8 0ZM6.314 2.674a2.667 2.667 0 0 1-3.64 3.64l3.64-3.64Zm-.93-.954L1.72 5.383A2.667 2.667 0 0 1 5.384 1.72Z",
      fill: "#B3B3B3"
    }
  )
);
const ForwardRef = forwardRef(SvgUnavailableFit);
export default ForwardRef;
