import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import './../../theme/sprinkles.css.ts.vanilla.css';
import './styles.css.ts.vanilla.css';
import { createRuntimeFn } from '@vanilla-extract/recipes/createRuntimeFn';

var progressBarRecipe = createRuntimeFn({ defaultClassName: "_1cuzvx51 _1cuzvx5ab _1cuzvx512 _1cuzvx5v _1qs5scg2", variantClassNames: { size: { small: "_1qs5scg6", medium: "_1qs5scg7", large: "_1qs5scg8" }, animated: { true: "_1qs5scg9", false: "_1qs5scga" }, indeterminate: { true: "_1qs5scgb" }, showExceeding: { true: "_1qs5scgc" } }, defaultVariants: {}, compoundVariants: [] });
var progressColor = "var(--_1qs5scg1)";
var progressValue = "var(--_1qs5scg0)";

export { progressBarRecipe, progressColor, progressValue };
