import * as React from "react";
import { forwardRef } from "react";
const SvgKey = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      clipRule: "evenodd",
      d: "M13.131 12.783a5.002 5.002 0 0 0 6.294-7.708 5 5 0 0 0-7.708 6.294l-7.849 7.849 1.414 1.414 1.415-1.415 2.121 2.122 1.414-1.414-2.121-2.122.707-.707 1.414 1.414 1.415-1.414-1.415-1.414 2.9-2.9Zm4.88-2.05a3 3 0 1 1-4.243-4.243 3 3 0 0 1 4.242 4.242Z"
    }
  )
);
const ForwardRef = forwardRef(SvgKey);
export default ForwardRef;
