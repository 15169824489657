import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import React from 'react';
import { LoadingSpinnerSmall } from '@superb-ai/icons';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { Icon } from '../../foundation/Icon/Icon.js';
import { getOpacityColor, getColor } from '../../theme/color.js';
import '../../theme/sprinkles.css.vanilla.js';
import './../../theme/global.css.ts.vanilla.css';
import './../../theme/theme.css.ts.vanilla.css';
import { extendComponent } from '../../util/extend-component.js';
import { buttonColor, buttonHoverColor, buttonStrokeColor, buttonRecipe, comboboxRecipe } from './styles.css.vanilla.js';

function getColorsFromProps({ color = "gray", variant, inactive, disabled }) {
  const resolvedColor = inactive ? "gray" : color;
  const weight = variant === "soft-fill" ? "100" : inactive && variant === "text" ? "300" : "400";
  const colorHover = variant === "text" ? getOpacityColor(color) : resolvedColor;
  const weightHover = variant === "strong-fill" ? inactive ? "400" : "500" : variant === "soft-fill" ? "200" : "100";
  const weightStroke = inactive && !disabled ? "100" : inactive ? "150" : "200";
  return {
    [buttonColor]: getColor(resolvedColor, weight),
    [buttonHoverColor]: getColor(colorHover, weightHover),
    [buttonStrokeColor]: getColor(resolvedColor, weightStroke)
  };
}
const loadingOrChildren = (props) => !props.loading ? props.children : /* @__PURE__ */ jsxs(Fragment, { children: [
  /* @__PURE__ */ jsx(Icon, { icon: LoadingSpinnerSmall }),
  /* @__PURE__ */ jsx("span", { children: props.loadingText ?? "Loading\u2026" })
] });
const sharedProps = {
  style: (props) => assignInlineVars(getColorsFromProps(props)),
  "data-loading": (props) => props.loading ? true : void 0,
  disabled: (props) => props.loading || props.disabled || false,
  children: loadingOrChildren
};
const NativeButton = React.forwardRef((props, ref) => {
  const { as: Component = "button", to, ...rest } = props;
  const componentProps = {
    ref,
    className: props.className,
    "data-loading": sharedProps["data-loading"](props),
    disabled: sharedProps.disabled(props),
    onClick: props.onClick,
    type: props.type,
    children: sharedProps.children(props),
    style: { ...sharedProps.style(props), cursor: props.disabled ? "default" : void 0 },
    ...rest
  };
  if (Component && to) {
    return /* @__PURE__ */ jsx(Component, { to, ...componentProps, children: sharedProps.children(props) });
  }
  return /* @__PURE__ */ jsx(Component, { ...componentProps, children: sharedProps.children(props) });
});
NativeButton.displayName = "NativeButton";
const Button = extendComponent(NativeButton, {
  className: ({ size, variant, square, segmented }) => buttonRecipe({ size, variant, square, segmented }),
  ...sharedProps
});
Button.displayName = "Button";
const DropdownButton = extendComponent(NativeButton, {
  className: ({ size, variant }) => comboboxRecipe({ size, variant }),
  ...sharedProps
});
DropdownButton.displayName = "DropdownButton";

export { Button, DropdownButton };
