import * as React from "react";
import { forwardRef } from "react";
const SvgRightToCopy = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18 4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h2v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-2V4Zm-2 0H4v12h12v-4h-5v1.784c0 .192-.227.288-.36.152L7.154 10.38a.546.546 0 0 1 0-.76l3.486-3.557c.133-.135.36-.04.36.153V8h5V4Zm2 4h2v12H8v-2h8a2 2 0 0 0 2-2V8Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgRightToCopy);
export default ForwardRef;
