import * as React from "react";
import { forwardRef } from "react";
const SvgCuboid = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    fill: "currentColor",
    viewBox: "0 0 24 24",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      d: "M8.5 4.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm12-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-17-6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm11-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-11 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm11-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6z",
      clipRule: "evenodd"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      d: "M11.67 21.5a2.997 2.997 0 0 1-.17-1c0-.35.06-.687.17-1H6.33a2.997 2.997 0 0 1 0 2h5.34zm5.43-2.497 2.318-2.704a3.01 3.01 0 0 1-1.518-1.302l-2.318 2.704a3.01 3.01 0 0 1 1.518 1.302zm-1.6-1.332v-5.342a2.997 2.997 0 0 1-1 .17 2.997 2.997 0 0 1-1-.17v5.342a2.997 2.997 0 0 1 1-.171c.35 0 .687.06 1 .17zm-13 0v-5.342a2.996 2.996 0 0 0 1 .17c.35 0 .687-.06 1-.17v5.342a2.996 2.996 0 0 0-1-.171c-.35 0-.687.06-1 .17zm14.74-9.396 2.162-1.982a3.01 3.01 0 0 1-1.539-1.361l-2.02 1.885a3.012 3.012 0 0 1 1.396 1.458zm-5.57.225H6.33a2.995 2.995 0 0 1 .17 1c0 .35-.06.687-.17 1h5.34a2.997 2.997 0 0 1-.17-1c0-.35.06-.687.17-1zm-5.657-.639a3.01 3.01 0 0 0-1.467-1.173l1.368-1.666a3.01 3.01 0 0 0 1.497 1.274L6.013 7.861zm15.487 2.81a2.997 2.997 0 0 0-1-.171c-.35 0-.687.06-1 .17V6.33a2.995 2.995 0 0 0 1 .17c.35 0 .687-.06 1-.17v4.34zm-4-7.171c0 .35.06.687.17 1h-6.34a2.996 2.996 0 0 0 .17-1c0-.35-.06-.687-.17-1h6.34a2.996 2.996 0 0 0-.17 1z",
      clipRule: "evenodd"
    }
  )
);
const ForwardRef = forwardRef(SvgCuboid);
export default ForwardRef;
