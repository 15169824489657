/* eslint-disable no-param-reassign */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Sentry from '@sentry/nextjs';
import { SeverityLevel } from '@sentry/nextjs';
import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import jwtDecode from 'jwt-decode';
import { camelCase, map, mapKeys, mapValues } from 'lodash';
import qs from 'qs';

import { Region } from '../contexts/AppContext';
import { Params } from '../services/types';
import { getCurateEndpoint, getEndpoint, getModelEndpoint } from './getEndpoint';
import StringUtils from './StringUtils';

function toCamelCaseKeys<A extends Record<string, any>>(node: A): A;
function toCamelCaseKeys<A extends any[]>(node: A): A;
function toCamelCaseKeys(node: any): any {
  if (node instanceof Array) {
    return map(node, toCamelCaseKeys) as any[];
  }
  if (node instanceof Object) {
    return mapValues(
      mapKeys(node, (v, k) => camelCase(k)),
      toCamelCaseKeys,
    );
  }
  return node;
}

export function toSnakeCaseKeys<A extends Record<string, any>>(node: A): A;
export function toSnakeCaseKeys<A extends any[]>(node: A): A;
export function toSnakeCaseKeys(node: any): any {
  if (node instanceof Array) {
    return map(node, toSnakeCaseKeys);
  }
  if (node instanceof Object) {
    // Changed lodash snakeCase to string utils snakeCase function. May have side effects.
    return mapValues(
      mapKeys(node, (v, k) => StringUtils.snakeCase(k)),
      toSnakeCaseKeys,
    );
  }
  return node;
}

function getBaseURL({
  version,
  idToken,
  base = '',
}: {
  version?: number;
  idToken?: string | null;
  base?: string;
}) {
  const versionPath = version ? `/v${version}` : '';
  const endpoint = getEndpoint(idToken);

  return `https://${endpoint}${versionPath}${base}/`;
}

function getCurateBaseURL({
  version,
  idToken,
  base = '',
}: {
  version?: number;
  idToken?: string | null;
  base?: string;
}) {
  const versionPath = version ? `/v${version}` : '';
  const endpoint = getCurateEndpoint(idToken);

  return `https://${endpoint}${versionPath}${base}/`;
}

function getModelBaseURL({
  version,
  idToken,
  base = '',
}: {
  version?: number;
  idToken?: string | null;
  base?: string;
}) {
  const versionPath = version ? `/v${version}` : '';
  const endpoint = getModelEndpoint(idToken);

  return `https://${endpoint}${versionPath}${base}/`;
}

const getAuthAxiosConfig = ({
  accountName,
  version,
}: {
  accountName: string;
  version?: number;
}): Record<string, any> => {
  return {
    baseURL: getBaseURL({ version }),
    crossdomain: true,
    transformResponse: ([] as any[]).concat(axios.defaults.transformResponse, toCamelCaseKeys),
    transformRequest: ([] as any[]).concat(
      axios.defaults.transformRequest,
      <T>(data: T, headers: Record<string, any>): T => {
        headers['X-Tenant-Id'] = accountName;
        delete headers.Authorization;
        return data;
      },
    ),
    timeout: 100000,
  };
};

const getAxiosConfig = ({
  accountName,
  version,
  isCurateUrl = false,
  isModelUrl = false,
  transformResponseToCamelCase = true,
  region,
}: {
  accountName: string;
  version?: number;
  isCurateUrl?: boolean;
  isModelUrl?: boolean;
  transformResponseToCamelCase?: boolean;
  region?: Region | '';
}): Record<string, any> => {
  const idToken = localStorage.getItem('spb_user');

  return {
    baseURL: isCurateUrl
      ? getCurateBaseURL({ version, idToken })
      : isModelUrl
      ? getModelBaseURL({ version, idToken })
      : getBaseURL({ version, idToken }),
    crossdomain: true,
    ...(transformResponseToCamelCase && {
      transformResponse: ([] as any[]).concat(axios.defaults.transformResponse, toCamelCaseKeys),
    }),
    transformRequest: ([] as any[]).concat(
      axios.defaults.transformRequest,
      <T>(data: T, headers: Record<string, any>): T => {
        headers['X-Tenant-Id'] = accountName;
        headers.Authorization = `Bearer ${idToken}`;
        return data;
      },
    ),
    timeout: 60000,
  };
};

const getGuestAxiosConfig = ({
  accountName,
  version,
}: {
  accountName: string;
  version?: number;
}): Record<string, any> => {
  return {
    baseURL: getBaseURL({ version, base: `/public/tenants/${accountName}` }),
    crossdomain: true,
    transformResponse: ([] as any[]).concat(axios.defaults.transformResponse, toCamelCaseKeys),
    transformRequest: ([] as any[]).concat(
      axios.defaults.transformRequest,
      <T>(data: T, headers: Record<string, any>): T => {
        headers['X-Tenant-Id'] = accountName;
        delete headers.Authorization;
        return data;
      },
    ),
    timeout: 60000,
  };
};

const getEmailSenderAxiosConfig = (): Record<string, any> => {
  return {
    baseURL: `https://${process.env.NEXT_PUBLIC_IS_EMAIL_SENDER_HOST}/`,
    crossdomain: true,
    transformResponse: ([] as any[]).concat(axios.defaults.transformResponse, toCamelCaseKeys),
    timeout: 100000,
  };
};

// @ts-ignore: because axios-retry can't support recently axios verison
axiosRetry(axios);
const getAxiosRetry = (): any => {
  return {
    'axios-retry': {
      retries: 5,
      retryDelay: axiosRetry.exponentialDelay,
    },
  };
};

/**
 * @param params (object) ex. {statusIn: ["SUBMITTED", "WORKING"],
 *     tagsAll: ["d58a871e-a9ae-4065-9584-98458a8a8881"]}
 *
 * Returns ex. status_in[]=SUBMITTED&status_in[]=WORKING&tags_all[]=d58a871e-a9ae-4065-9584-98458a8a8881
 */
const getParamString = (params?: Params): string => {
  if (!params) return '';

  return qs.stringify(toSnakeCaseKeys(params), { arrayFormat: 'brackets' });
};

const isAxiosResponseError = (
  err: any,
): err is AxiosError<any> & Required<Pick<AxiosError, 'response'>> => !!err.response;

const logSentry = (
  err: AxiosError<any> | Error,
  origin = '',
  level: SeverityLevel = 'error',
): void => {
  const spbAuthBase64 = localStorage.getItem('spb_user');
  const auth = spbAuthBase64 ? jwtDecode<Record<string, string>>(spbAuthBase64) : null;
  if (auth) {
    Sentry.setUser({
      id: auth.email,
      email: auth.email,
      accountName: auth['custom:tenant_name'],
      role: auth['custom:tenant_role'],
    });
    Sentry.setTag('user_role', auth['custom:tenant_role']);
    Sentry.setTag('account_name', auth['custom:tenant_name']);
  }

  Sentry.setTag('app-group', 'suite');

  Sentry.withScope(scope => {
    if (isAxiosResponseError(err)) {
      scope.setTag('correlation_id', err.response.headers['x-correlation-id']);
      scope.setExtra('error_code', err.response.data?.code);
      scope.setExtra('error_message', err.response.data?.message);
      if (err.response.data?.code || err.response.data?.message) {
        err.message = `${err.message}: ${err.response.data.code} (${err.response.data.message})`;
      }
    }
    scope.setExtra('origin', origin);
    scope.setLevel(level as any);
    Sentry.captureException(err);
  });
};

const handleSentryLogging = (err: AxiosError, origin: string): void => {
  if (!err.response) {
    logSentry(err);
    throw err;
  } else {
    const status = `${err.response.status}`;
    switch (true) {
      case /^4/.test(status):
        logSentry(err, origin, 'warning');
        break;
      case /^5/.test(status):
        logSentry(err, origin, 'error');
        break;
      default:
        logSentry(err, origin, 'info');
    }
  }
};

export default {
  toCamelCaseKeys,
  toSnakeCaseKeys,
  getAuthAxiosConfig,
  getAxiosConfig,
  getGuestAxiosConfig,
  getEmailSenderAxiosConfig,
  getAxiosRetry,
  getParamString,
  handleSentryLogging,
  logSentry,
};
