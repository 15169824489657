import Script from 'next/script';

const SegmentLoader = () => {
  return (
    <Script
      src={`https://cdn.segment.com/analytics.js/v1/${process.env.NEXT_PUBLIC_SEGMENT_TOKEN}/analytics.min.js`}
      strategy="afterInteractive"
      onLoad={() => {
        console.log('Segment script loaded');
        // window.analytics.page(); // Optionally track a page view on load
      }}
    />
  );
};

export default SegmentLoader;
