import * as React from "react";
import { forwardRef } from "react";
const SvgPolylineUnion = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm-3-1a3 3 0 1 1 1.707 2.708l-10 9.999a3 3 0 1 1-1.414-1.414l10-10A2.987 2.987 0 0 1 16 5ZM4 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM6 2v2h2v2H6v2H4V6H2V4h2V2h2Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgPolylineUnion);
export default ForwardRef;
