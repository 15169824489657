import * as React from "react";
import { forwardRef } from "react";
const SvgChartDonut = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    height: "1em",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("path", { d: "M17.96 10.949H22C21.529 6.232 17.768 2.471 13.051 2v4.04a6.004 6.004 0 0 1 4.909 4.909Z" }),
  /* @__PURE__ */ React.createElement("path", { d: "M12.051 17.949c-3.309 0-6-2.691-6-6 0-2.967 2.167-5.432 5-5.909V2c-5.046.504-9 4.773-9 9.949 0 5.514 4.486 10 10 10 5.176 0 9.445-3.954 9.949-9h-4.04c-.478 2.833-2.942 5-5.909 5Z" })
);
const ForwardRef = forwardRef(SvgChartDonut);
export default ForwardRef;
