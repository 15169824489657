// @ts-ignore: no typing
import segmentPlugin from '@analytics/segment';
import { Analytics } from 'analytics';

const version = process.env.SENTRY_RELEASE;

const plugins = [];
if (process.env.NEXT_PUBLIC_SEGMENT_TOKEN) {
  plugins.unshift(
    segmentPlugin({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_TOKEN,
    }),
  );
}

export const analytics = Analytics({
  app: 'sahara',
  version,
  plugins,
});

// TODO: update sahara vs siesta
analytics.on('ready:segment', _ => {
  if (typeof window === 'undefined') return;
  try {
    // @ts-ignore: this works
    window.analytics.addSourceMiddleware(({ payload, next }) => {
      const context = payload.obj.context;
      payload.obj.context = { ...context, app: { name: 'sahara', version } };
      next(payload);
    });
  } catch (_) {}
});
