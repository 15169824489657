import * as React from "react";
import { forwardRef } from "react";
const SvgCuboidSize = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 16 16",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M3.286 8v4.714H8l-1.885-1.886 4.714-4.714L12.714 8V3.286H8l1.886 1.886-4.714 4.714L3.286 8Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgCuboidSize);
export default ForwardRef;
