import { AxiosError } from 'axios';

import { Region } from '../contexts/AppContext';
import { GetApiKeyResponse } from '../queries/useApiKeyQuery';
import { resetLoginTokens, setLoginTokens } from '../utils/LoginUtils';
import {
  AfterLoginCallback,
  apiCallAfterLogin,
  apiCallBeforeLogin,
  BeforeLoginCallback,
  replacePageToAuth,
  useFetcher,
} from '.';

export const loginRefresh: BeforeLoginCallback<any> = async params => {
  const errorHandling = () => {
    resetLoginTokens();
    replacePageToAuth({ router: params.router });
  };

  const { data } = await apiCallBeforeLogin<any>({
    method: 'post',
    url: '/auth/refresh',
    errorHandling,
    ...params,
    data: { idToken: localStorage.getItem('spb_user'), refreshToken: localStorage.getItem('spb_') },
  });

  const { accessToken, idToken, hmacToken } = data.data;

  localStorage.setItem('spb_access', accessToken);
  localStorage.setItem('spb_user', idToken);

  if (hmacToken) {
    localStorage.setItem('hmac_token', hmacToken);
  }

  return data;
};

const createAccount: BeforeLoginCallback<any, Record<string, any>> = async params => {
  const { data } = await apiCallBeforeLogin({
    method: 'post',
    url: '/auth/reg',
    ...params,
  });

  return data;
};

interface CreateAccountData {
  tenantUuid: string;
  email: string;
  session: string;
  tenantId: string;
  newPassword: string;
  givenName: string;
  familyName: string;
  affiliation: string;
  jobTitle?: string;
}

const createAccountV2: BeforeLoginCallback<any, CreateAccountData> = async params => {
  const { data } = await apiCallBeforeLogin<any>({
    method: 'post',
    url: '/auth/v2/reg-force-change-password',
    ...params,
    accountName: params.data.tenantId,
  });

  if (data) {
    const { accessToken, idToken, refreshToken, hmacToken } = data.data;
    setLoginTokens(accessToken, idToken, refreshToken, hmacToken);
  }

  return data;
};

const createAccountByEmail: BeforeLoginCallback<any, { email: string }> = async params => {
  const { data } = await apiCallBeforeLogin({
    method: 'post',
    url: '/auth/v2/reg',
    ...params,
    accountName: '',
  });

  return data;
};

const confirmAccount: BeforeLoginCallback<
  any,
  { email: string; code: string; region: string; tenantId: string }
> = async ({ data: { region, ...restData }, ...params }) => {
  const { data } = await apiCallBeforeLogin({
    method: 'post',
    url: `/auth/confirm?region=${region}`,
    ...params,
    data: {
      ...restData,
    },
  });

  return data;
};

const confirmAccountV2: BeforeLoginCallback<
  any,
  { tenantUuid: string; email: string; code: string; region: string }
> = async ({ data: { region, ...restData }, ...params }) => {
  const { data } = await apiCallBeforeLogin({
    method: 'post',
    url: `/auth/v2/confirm?region=${region}`,
    data: restData,
    ...params,
    accountName: '',
  });

  return data;
};

const resendCode: BeforeLoginCallback<
  any,
  { email: string; tenantUuid: string; v2?: boolean }
> = async params => {
  const errorHandling = (err: AxiosError<any>) => {
    if (err.response?.status === 400) {
      switch (err.response.data.code) {
        case '006400':
          throw new Error("You've made an attempt more than 5 times. Please try after an hour");
        default:
          throw new Error('Unknown Error');
      }
    } else if (err.response?.status === 412) {
      switch (err.response.data.code) {
        case '201412':
          throw new Error('Your account has been already activated');
        default:
          throw new Error('Unknown Error');
      }
    }
  };

  const { data } = await apiCallBeforeLogin({
    method: 'post',
    url: '/auth/v2/resend',
    errorHandling,
    ...params,
  });

  return data;
};

interface LoginArgs {
  email: string;
  password: string;
  tenantId: string;
}

const login: BeforeLoginCallback<any, LoginArgs> = async params => {
  const errorHandling = (err: AxiosError<any>) => {
    if (!err.response) {
      throw err;
    } else {
      switch (err.response.status) {
        case 400:
          if (err.response.data && err.response.data.code) {
            switch (err.response.data.code) {
              case '003400':
                throw new Error('Wrong password!');
              case '201400':
                throw new Error('That team name is not registered');
              case '202400':
                throw new Error('That email is not registered');
              case '211400':
                throw new Error('Your account has been locked');
              case '212400':
                throw new Error('Please activate your account first');
              default:
                throw new Error('Unknown error');
            }
          }
          throw new Error('Bad request');
        case 412:
          switch (err.response.data.code) {
            case '200412':
              throw new Error('That account is not activated. Please check your email.');
            default:
              throw new Error('Unknown error');
          }
        case 401:
          switch (err.response.data.code) {
            case '401999':
              throw new Error(
                'Your IP address is not allowed to access this service. Please contact your network administrator or the service provider for more information.',
              );
            default:
              throw new Error('Unknown error');
          }
        case 403:
          switch (err.response.data.detail.code) {
            case '403999':
              throw new Error(
                'Your IP address is not allowed to access this service. Please contact your network administrator or the service provider for more information.',
              );
            default:
              throw new Error('Unknown error');
          }
        default:
          throw new Error('Unknown error');
      }
    }
  };

  const { data } = await apiCallBeforeLogin<any>({
    method: 'post',
    url: '/auth/login',
    errorHandling,
    ...params,
  });

  const { message } = data;
  const { accessToken, idToken, refreshToken, hmacToken } = data.data;

  if (idToken && message !== 'Force change password') {
    setLoginTokens(accessToken, idToken, refreshToken, hmacToken);
  }

  return data;
};

interface LoginWithMfaArgs {
  email: string;
  session: string;
  userCode: string;
  rememberDevice: boolean;
  tenantId: string;
}

const loginWithMfa: BeforeLoginCallback<any, LoginWithMfaArgs> = async ({
  data: body,
  ...params
}) => {
  const errorHandling = (err: AxiosError<any>) => {
    if (!err.response) {
      throw err;
    } else {
      switch (err.response.status) {
        case 400:
          if (err.response.data && err.response.data.code) {
            switch (err.response.data.code) {
              case '003400':
                throw new Error('Wrong password!');
              case '201400':
                throw new Error('That team name is not registered');
              case '202400':
                throw new Error('That email is not registered');
              case '211400':
                throw new Error('Your account has been locked');
              case '212400':
                throw new Error('Please activate your account first');
              case '011400':
                throw new Error('Code is incorrect. Please try again.');
              default:
                throw new Error('Unknown error');
            }
          }
          throw new Error('Bad request');
        case 412:
          switch (err.response.data.code) {
            case '200412':
              throw new Error('That account is not activated. Please check your email.');
            default:
              throw new Error('Unknown error');
          }
        case 401:
          switch (err.response.data.code) {
            case '401999':
              throw new Error(
                'Your IP address is not allowed to access this service. Please contact your network administrator or the service provider for more information.',
              );
            default:
              throw new Error('Unknown error');
          }
        default:
          throw new Error('Unknown error');
      }
    }
  };

  const { data } = await apiCallBeforeLogin<any>({
    method: 'post',
    url: '/auth/login/mfa',
    errorHandling,
    ...params,
    data: {
      ...body,
      challengeName: 'SOFTWARE_TOKEN_MFA',
    },
  });

  const { message } = data;
  const { accessToken, idToken, refreshToken, hmacToken } = data.data;

  if (message !== 'Force change password') {
    setLoginTokens(accessToken, idToken, refreshToken, hmacToken);
  }

  return data;
};

interface ChangePasswordAfterInviteArgs {
  email: string;
  session: string;
  newPassword: string;
  givenName: string;
  familyName: string;
  tenantId: string;
}

const changePasswordAfterInvite: BeforeLoginCallback<
  any,
  ChangePasswordAfterInviteArgs
> = async params => {
  const errorHandling = (err: AxiosError<any>) => {
    if (err.response?.status === 400) {
      switch (err.response.data.code) {
        case '003400':
          throw new Error('Session expired');
        default:
          throw new Error('Bad request');
      }
    }
  };

  const { data } = await apiCallBeforeLogin({
    method: 'post',
    url: '/auth/force-change-password',
    errorHandling,
    ...params,
  });

  return data;
};

const forgotPassword: BeforeLoginCallback<
  any,
  { email: string; tenantId: string }
> = async params => {
  const errorHandling = (err: AxiosError<any>) => {
    switch (err.response?.status) {
      case 400:
        switch (err.response.data.code) {
          case '204400':
            throw new Error("That Team doesn't exist");
          case '203400':
            throw new Error("That Email doesn't exist");
          default:
            throw new Error('Unknown Error');
        }
      case 412:
        switch (err.response.data.code) {
          case '003400':
            throw new Error('Session expired');
          default:
            throw new Error('Bad request');
        }
    }
  };

  const { data } = await apiCallBeforeLogin({
    method: 'post',
    url: '/auth/reset-password',
    errorHandling,
    ...params,
  });

  return data;
};

interface ChanggePasswordAfterForgotArgs {
  email: string;
  code: string;
  password: string;
  tenantId: string;
}

const changePasswordAfterForgotPassword: BeforeLoginCallback<
  any,
  ChanggePasswordAfterForgotArgs
> = async params => {
  const errorHandling = (err: AxiosError<any>) => {
    if (err.response?.status === 500) {
      switch (err.response.data.code) {
        case '200500':
          throw new Error('Code mismatch!');
        default:
          throw new Error('Unknown Error');
      }
    }
  };

  const { data } = await apiCallBeforeLogin({
    method: 'post',
    url: '/auth/reset-password/confirm',
    errorHandling,
    ...params,
  });

  return data;
};

const changePasswordOnAccount: AfterLoginCallback<
  any,
  { oldPassword: string; newPassword: string }
> = async ({ data: { newPassword, oldPassword }, ...params }) => {
  const { data } = await apiCallAfterLogin({
    method: 'put',
    url: '/auth/users/change-password',
    data: {
      accessToken: localStorage.getItem('spb_access'),
      oldPassword,
      newPassword,
    },
    hasPublicApi: false,
    ...params,
  });

  return data;
};

const changeName: AfterLoginCallback<any, { givenName: string; familyName: string }> = async ({
  data: { givenName, familyName },
  ...params
}) => {
  const { data } = await apiCallAfterLogin({
    method: 'put',
    url: '/auth/users/profile',
    data: {
      accessToken: localStorage.getItem('spb_access'),
      givenName,
      familyName,
    },
    hasPublicApi: false,
    ...params,
  });

  return data;
};

const uploadAvatar: AfterLoginCallback<
  { data: { avatarUrl: string } },
  { avatar: string }
> = async ({ data: { avatar }, ...params }) => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/auth/users/profile/avatar',
    data: {
      accessToken: localStorage.getItem('spb_access'),
      avatar,
    },
    hasPublicApi: false,
    ...params,
  });

  return data;
};

const deleteAvatar: AfterLoginCallback<any> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'delete',
    url: '/auth/users/profile/avatar',
    hasPublicApi: false,
    ...params,
    data: {
      accessToken: localStorage.getItem('spb_access'),
    },
  });

  return data;
};

const uploadTenantAvatar: AfterLoginCallback<any, { avatar: string }> = async ({
  data: { avatar },
  ...params
}) => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/auth/tenants/profile/avatar',
    data: {
      accessToken: localStorage.getItem('spb_access'),
      avatar,
    },
    hasPublicApi: false,
    ...params,
  });

  return data;
};

type MfaRole = 'Admin' | 'Owner' | 'Collaborator';

interface TenantInfo {
  avatarUrl: string;
  mfaRequiredRoles: MfaRole[];
  region: Region | '';
  country: string;
}

const getTenantInfo: AfterLoginCallback<{ data: TenantInfo }> = async params => {
  const { data } = await apiCallAfterLogin<{ data: TenantInfo }>({
    method: 'get',
    url: `/auth/tenants/${encodeURIComponent(params.accountName)}/profile`,
    hasPublicApi: false,
    ...params,
  });

  return data;
};

const requestApiKey: AfterLoginCallback<GetApiKeyResponse> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/auth/tenants/apikey',
    ...params,
    data: {
      accessToken: localStorage.getItem('spb_access'),
    },
    hasPublicApi: false,
  });

  return data;
};

const getApiKey: AfterLoginCallback<GetApiKeyResponse> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: '/auth/tenants/apikey',
    hasPublicApi: false,
    ...params,
  });
  return data;
};

export function useAuthService() {
  const { afterLoginFetcher, beforeLoginFetcher, signInPageFetcher } = useFetcher();

  return {
    // After Login
    changePasswordOnAccount: afterLoginFetcher(changePasswordOnAccount),
    changeName: afterLoginFetcher(changeName),
    uploadAvatar: afterLoginFetcher(uploadAvatar),
    deleteAvatar: afterLoginFetcher(deleteAvatar),
    uploadTenantAvatar: afterLoginFetcher(uploadTenantAvatar),
    getTenantInfo: afterLoginFetcher(getTenantInfo),
    requestApiKey: afterLoginFetcher(requestApiKey),
    getApiKey: afterLoginFetcher(getApiKey),
    // Before Login - sign up
    // [Notice] beforeLoginFetcher is somewhat ill-defined name. It is actually used for createAccount and confirmAccount.
    createAccount: beforeLoginFetcher(createAccount),
    createAccountV2: beforeLoginFetcher(createAccountV2),
    createAccountByEmail: beforeLoginFetcher(createAccountByEmail),
    confirmAccount: beforeLoginFetcher(confirmAccount),
    confirmAccountV2: beforeLoginFetcher(confirmAccountV2),
    resendCode: beforeLoginFetcher(resendCode),
    // After Login - refresh
    loginRefresh: beforeLoginFetcher(loginRefresh),
    // login (account name is required in requrest headers)
    login: signInPageFetcher(login),
    loginWithMfa: signInPageFetcher(loginWithMfa),
    forgotPassword: signInPageFetcher(forgotPassword),
    changePasswordAfterInvite: signInPageFetcher(changePasswordAfterInvite),
    changePasswordAfterForgotPassword: signInPageFetcher(changePasswordAfterForgotPassword),
  };
}
