import * as React from "react";
import { forwardRef } from "react";
const SvgAppsLogo = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement("rect", { x: 3, y: 3, width: 7.5, height: 7.5, rx: 1, fill: "#D2AEFF" }),
  /* @__PURE__ */ React.createElement("rect", { x: 3, y: 13.5, width: 7.5, height: 7.5, rx: 1, fill: "#D2AEFF" }),
  /* @__PURE__ */ React.createElement("rect", { x: 13, y: 16.375, width: 9, height: 2.25, rx: 1, fill: "#A64DFF" }),
  /* @__PURE__ */ React.createElement(
    "rect",
    {
      x: 16.375,
      y: 22,
      width: 9,
      height: 2.25,
      rx: 1,
      transform: "rotate(-90 16.375 22)",
      fill: "#A64DFF"
    }
  ),
  /* @__PURE__ */ React.createElement("rect", { x: 13.5, y: 3, width: 7.5, height: 7.5, rx: 1, fill: "#D2AEFF" })
);
const ForwardRef = forwardRef(SvgAppsLogo);
export default ForwardRef;
