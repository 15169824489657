import * as React from "react";
import { forwardRef } from "react";
const SvgMerge = (props, ref) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    ref,
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M22 6.172V9l-2-2-.793-.793-5.328 5.329A5 5 0 0 1 10.343 13H2v-2h8.343a3 3 0 0 0 2.121-.879l5.329-5.328L17 4l-2-2h7V6.172ZM17 20l.793-.793-4.5-4.5 1.414-1.414 4.5 4.5L20 17l2-2v7h-7l2-2Z",
      fill: "currentColor"
    }
  )
);
const ForwardRef = forwardRef(SvgMerge);
export default ForwardRef;
